import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuBar: {
    marginTop: `0px !important`,
    top: `0px !important`,
    color: `none !important`,
    width: `none !important`,
    height: `none !important`,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  navBarMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuBarMobile: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  menuBarMobileIcon: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  active: {
    transition: ".6s ease !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
    "& Button": {
      color: theme.palette.common.white,
    },
    "& svg": {
      color: theme.palette.common.white,
    },
  },
  inactive: {
    backgroundColor: `transparent !important`,
  },
  logo: {
    width: `50px !important`,
    height: `50px !important`,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  icons: {
    color: theme.palette.common.white,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawerContent: {
    paddingTop: theme.spacing(5),
  },
}));
