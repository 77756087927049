import { connectRouter } from "connected-react-router/immutable";
import { combineReducers } from "redux";
import { History } from "history";
import authentication from "./authentication.reducer";
import snackbars from "./snackbar.reducer";
import houses from "./house.reducer";

const rootReducer = (history: History<any>) =>
  combineReducers({
    user: authentication,
    router: connectRouter(history),
    snackbar: snackbars,
    houses: houses,
  });

export default rootReducer;
