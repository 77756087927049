import React from "react";
import jewi from "../../../assets/images/jewi.jpg";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";

interface Props {
  location: any;
  history: any;
}

function AboutUs(props: Props) {
  return (
    <React.Fragment>
      <div className="container">
        <div className="keewist-sections">
          <section className={"about-section"}>
            <div className="keewist-titles">
              <h3>
                <span className={"title-span"}>About</span> us
              </h3>
            </div>
            <div className="keewist-introduction">
              <div className="left-side">
                <img src={jewi} alt="about-us" />
              </div>
              <div className="paragraph">
                <p>
                  Airbnb was introduced to us in 2015 by one of our close friend
                  who is also a host in Wichita. Like many people we were
                  skeptical opening our home to travelers initially; however, we
                  felt we have gained so much more since 2015. It allowed us to
                  become humble, compassionate, and appreciative what humanity
                  has to offer.
                </p>
                <p>
                  Don't worry! We are not nosy hosts we don't care to probe for
                  information for personal gains, but rather we enjoy great
                  conversations and crave for our guests' life experiences. Only
                  if you would like to share.
                </p>
                <p>
                  Jerry is a sales and operation manager for a family owned
                  furniture store. He enjoys traveling, gym, obstacle races,
                  gardening, landscaping, pond, and outdoor activities. Winnie
                  works for local bridal store in downtown on weekend, and
                  Pfizer chemist during the week. She loves real estate,
                  entrepreneurial pod cast, true crime, ghost stories, yoga, and
                  minimal exercise!
                </p>
                <p>
                  We are passionate hosts, we started from one listing out of
                  our basement to multiple listing operating from three
                  different homes with private rooms to entire house. Airbnb is
                  both our baby and we enjoy hosting guests. We can't wait to
                  host you and all your travel needs!
                </p>
              </div>
            </div>
          </section>
          <section className="listing">
            <div className="keewist-titles">
              <h3>
                <span className={"title-span"}>Our</span> listings
              </h3>
            </div>
            <div className="properties">
              <h2>Coming soon...</h2>
            </div>
          </section>
          <div className="more-properties">
            <Button>Explore more</Button>
          </div>
          <br />
        </div>
      </div>
    </React.Fragment>
  );
}
const mapDispatchToProps = (dispatch) => {};
export default withRouter(connect(mapDispatchToProps)(AboutUs));
