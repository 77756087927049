import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function SKELETONMedia(props: any) {
  const { loading = false } = props;
  return (
    <Card sx={{ maxWidth: 345, m: 2 }}>
      <CardHeader
        avatar={
          loading && (
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          )
        }
        action={
          loading && (
            <IconButton aria-label="settings" disabled>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={
          loading && (
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          )
        }
        subheader={
          loading && <Skeleton animation="wave" height={10} width="40%" />
        }
      />
      {loading && (
        <Skeleton sx={{ height: 290 }} animation="wave" variant="rectangular" />
      )}

      <CardContent>
        {loading && (
          <React.Fragment>
            <Skeleton
              animation="wave"
              height={10}
              style={{ marginBottom: 6 }}
            />
            <Skeleton
              animation="wave"
              height={10}
              style={{ marginBottom: 6 }}
            />
            <Skeleton
              animation="wave"
              height={10}
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="80%" />
          </React.Fragment>
        )}
      </CardContent>
    </Card>
  );
}
