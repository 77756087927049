import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  showcase: {
    position: "relative",
    right: 0,
    width: "100%",
    minHeight: "40rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundImage: `url(
      "https://media-exp1.licdn.com/dms/image/C4E1BAQFooY5jljXrTw/company-background_10000/0/1576176202230?e=2159024400&v=beta&t=J6jUV7r1FNuvjl1G65JT0YxocpnQGhFExE4SCEttTHw"
    )`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  root: {
    zIndex: 9,
  },
  text: {
    maxWidth: 900,
    padding: "0 30px 0 35px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      padding: "9em 1em",
    },
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2,
    backgroundImage:
      "linear-gradient(to left top, #2e77ae, #6392c0, #8eafd1, #b7cce3, #e0eaf5)",
    opacity: 0.8,
  },
  header: {
    display: "block",
    fontSize: "6.4em !important",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    fontFamily: "'Rock Salt', 'cursive' !important",
    paddingBottom: ".2em !important",
    marginTop: `${theme.spacing(1)} !important`,
    marginBottom: `${theme.spacing(2)} !important`,
    [theme.breakpoints.down("sm")]: {
      fontSize: "4em !important",
    },
  },
  smallHeader: {
    letterSpacing: 0.8,
    fontSize: "1.3em",
    textTransform: "lowercase",
    color: theme.palette.info.main,
    lineHeight: "1.3em",
    zIndex: 8,
    // fontFamily: "'Schoolbell', 'sans-serif'",
    // fontFamily: "'Rock Salt', 'cursive' !important",
  },
  paragraph: {
    fontSize: "17px",
    lineHeight: "29px",
    color: theme.palette.primary.main,
    margin: "20px 0",
    fontWeight: 500,
    textAlign: "justify",
  },
  submit: {
    margin: `${theme.spacing(3, 0, 2)} !important`,
    display: "block !important",
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: "#fff",
  },
  typograph: {
    color: theme.palette.info.main,
    letterSpacing: "0.8px !important",
    textTransform: "uppercase",
    fontWeight: "600 !important",
    marginTop: `${theme.spacing(12)} !important`,
    marginBottom: `${theme.spacing(1)} !important`,
    "& span": {
      color: theme.palette.secondary.main,
    },
  },
  showMore: {
    padding: ".5em 1.5em",
    marginTop: theme.spacing(3),
  },
}));
