import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  typograph: {
    color: theme.palette.info.main,
    letterSpacing: 0.8,
    textTransform: "uppercase",
    fontWeight: `600 !important`,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40
    },
    marginBottom: theme.spacing(1),
    "& span": {
      color: theme.palette.secondary.main,
    },
  },
  btnGrid: {
    paddingLeft: `0 !important`,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: `32px !important`
    },
  },
  cancelBy: {
    display: "flex",
    justifyContent: "space-between"
  },
  image: {
    height: 300,
  },
  viewImages: {
    position: "absolute",
    bottom: 10,
    right: 10,
    zIndex: 10,
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
    padding: "5px 10px",
  },
  imageHeader: {
    position: "relative",
    cursor: "pointer",
    "& img:nth-child(1)": {
      width: "50%",
      border: "1px solid #fff",
      borderTopLeftRadius: 4,
    },
    "& img:nth-child(2)": {
      border: "1px solid #fff",
      width: "50%",
      borderTopRightRadius: 4,
    },
    "& img:nth-child(3)": {
      border: "1px solid #fff",
      width: "35%",
      borderBottomLeftRadius: 4,
    },
    "& img:nth-child(5)": {
      border: "1px solid #fff",
      width: "35%",
      borderBottomRightRadius: 4,
    },
    "& img:nth-child(4)": {
      border: "1px solid #fff",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      "& img:nth-child(1)": {
        width: "100%",
        border: "1px solid #fff",
      },
      "& img:nth-child(2)": {
        display: "none",
      },
      "& img:nth-child(3)": {
        display: "none",
      },
      "& img:nth-child(5)": {
        display: "none",
      },
      "& img:nth-child(4)": {
        display: "none",
      },
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(3),
    },
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  map: {
    position: "sticky",
    top: 150,
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    zIndex: 10,
  },
  paper: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  caption: {
    color: theme.palette.grey.main,
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.info.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  datePicker: {
    border: "1px solid #EBEBEB !important",
    fontFamily: "'Roboto', 'sans-serif' !important",
    "& .rdrDay>span": {
      color: `${theme.palette.secondary.main} !important`,
    },
    "& .rdrDateDisplayItemActive": {
      borderColor: `${theme.palette.secondary.main} !important`,
    },
  },
  tripDetails: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  warnings: {
    display: "flex",
    justifyContent: "space-between",
  },
  requestPageLeft: {
    marginTop: `${theme.spacing(1)}px !important`,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      marginTop: `${theme.spacing(1)} !important`,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: `${theme.spacing(0)} !important`,
    },
  },
  requestPageRightPaper: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  requestPageRight: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));
