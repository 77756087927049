import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import styles from "./styles";
import { PropertyStateContext } from "../PropertyContext";
import { FormHelperText, Paper, Typography } from "@mui/material";
import theme from "../../../../theme/theme";
import KEEWISTEditor from "../../../../../ui-kit/components/KEEWIST-editor";
import { Button, Chip, TextField } from "@material-ui/core";
import { HygieneApiService } from "../../../../../api-kit/hygiene/hygiene-api.service";
import { addSnackBar } from "../../../../../_redux/_actions/snackBar.actions";
import { useDispatch } from "react-redux";

const Hygiene = () => {
  const classes = styles();
  const hygieneApiService = new HygieneApiService();
  const dispatch = useDispatch();

  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);

  useEffect(() => {
    hygieneApiService
      .getHygiene()
      .then((res) => {
        updateAddPropertyState({
          enhancedCleaningDescription:
            res.data !== null ? res.data?.enhanced_clean_description : "",
          enhancedCleanings: res.data !== null ? res.data?.enhanced_clean : [],
        });
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (
      addPropertyState.enhancedCleaningType !== "" &&
      addPropertyState.enhancedCleaningDesc !== ""
    ) {
      const delayDebounceFn = setTimeout(() => {
        let newObject = [
          {
            type: addPropertyState.enhancedCleaningType,
            description: addPropertyState.enhancedCleaningDesc,
          },
        ];
        var joined = addPropertyState.enhancedCleanings.concat(newObject);
        updateAddPropertyState({
          enhancedCleanings: joined,
          enhancedCleaningType: "",
          enhancedCleaningDesc: "",
        });
        return () => clearTimeout(delayDebounceFn);
      }, 3000);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addPropertyState.enhancedCleaningType,
    addPropertyState.enhancedCleaningDesc,
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const body = {
      enhanced_clean_description: addPropertyState.enhancedCleaningDescription,
      enhanced_clean: addPropertyState.enhancedCleanings,
    };
    hygieneApiService
      .createHygiene(body)
      .then((data) =>
        dispatch(
          addSnackBar({
            message: "New house successfully added.",
            type: "success",
          })
        )
      )
      .catch((error) =>
        dispatch(
          addSnackBar({
            message: "Error occured while saving.",
            type: "error",
          })
        )
      );
  };
  const onDeleteEnhancedCleaning = (value) => {
    const EnhancedCleanUpdated = addPropertyState.enhancedCleanings.filter(
      (clean) =>
        clean.type !== value.type && clean.decription !== clean.description
    );
    updateAddPropertyState({ enhancedCleanings: EnhancedCleanUpdated });
  };
  return (
    <Paper component="main" elevation={1}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        gutterBottom
        color="textSecondary"
        className={classes.title}
      >
        Enhanced cleaning
      </Typography>
      <form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.form}>
          <div className={classes.editor}>
            <KEEWISTEditor
              data={addPropertyState.enhancedCleaningDescription}
              onChange={(e: any) =>
                updateAddPropertyState({ enhancedCleaningDescription: e })
              }
              onBlur={(e: any) =>
                updateAddPropertyState({ enhancedCleaningDescription: e })
              }
            />
            <FormHelperText>
              Ex: You can add something like this in the description {">"}{" "}
              Here’s what Keewist LLC committed to doing before every stay:
            </FormHelperText>
          </div>
          <TextField
            variant="outlined"
            className={clsx(classes.enhacedCleaning, classes.cleaningGap)}
            name="keewist-enhanced-cleaning-type"
            label="Add cleaning type."
            type="text"
            id="keewist-enhanced-cleaning-type"
            onChange={(e: any) =>
              updateAddPropertyState({
                enhancedCleaningType: e.target?.value,
              })
            }
          />
          <TextField
            variant="outlined"
            className={classes.enhacedCleaning}
            name="keewist-enhanced-cleaning-description"
            label="Add cleaning description."
            type="text"
            id="keewist-enhanced-cleaning-description"
            onChange={(e: any) =>
              updateAddPropertyState({
                enhancedCleaningDesc: e.target?.value,
              })
            }
          />

          <FormHelperText>
            Tip: You can keep adding cleaning types and descriptions, when both
            fields are filled, you will see little popup below. If you finished
            adding them please hit submit.
          </FormHelperText>
          <div className={classes.chipsRow}>
            {addPropertyState.enhancedCleanings.length !== 0 &&
              addPropertyState.enhancedCleanings.map((item, index) => (
                <Chip
                  key={index}
                  size="small"
                  label={`Type: ${item.type} :::::
              Description: ${item.description}`}
                  onDelete={(e) => onDeleteEnhancedCleaning(item)}
                  className={classes.chips}
                />
              ))}
          </div>
          <Button
            variant="contained"
            style={{
              color: theme.palette.common.white,
              backgroundColor: theme.palette.secondary.main,
            }}
            size={"small"}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default Hygiene;
