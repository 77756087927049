import {
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import styles from "./styles";
import React, { useContext, useEffect } from "react";
import AddHouseRules from "./components/AddHouseRules";
import AddHouseAddress from "./components/AddHouseAddress";
import AddHouseDetails from "./components/AddHouseDetails";
import { PropertyStateContext } from "../PropertyContext";
import { PropertyApiService } from "../../../../../api-kit/property/property-api.service";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../_redux/_actions/snackBar.actions";
import { addPropertyStateDefault } from "./AddPropertyState";
import { isFormSubmitted } from "../../../../../service/validation.service";
import { updatePropertyStatesFunc } from "../helpers/HelperFunctions";
import { validationMessages } from "../helpers/constants";
import { zipCodeCheck } from "../../../../../ui-kit/common/validation/KeewistValidations";
import { useHistory } from "react-router-dom";
import { Paths } from "../../../../Router";
import theme from "../../../../theme/theme";
import KEEWISTFormDialog from "../components/KEEWIST-dailog";

const AddNewProperty = () => {
  const classes = styles();
  const history = useHistory();
  const propertyApiService = new PropertyApiService();
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    let isCurrent = true;
    if (activeStep === 1) {
      validateHouseDetails();
      const isValid = checkIfAllFieldsValid();
      if (!isValid) {
        addPropertyState.steps[0].error = true;
      } else {
        addPropertyState.steps[0].error = false;
      }
    }
    if (activeStep === 2) {
      validateHouseDetails();
      validateHouseAddress();
      const isValid = checkIfAllFieldsValid();
      if (!isValid) {
        addPropertyState.steps[1].error = true;
      } else {
        addPropertyState.steps[1].error = false;
      }
    }
    isFormSubmitted.subscribe((submit) => {
      if (submit && isCurrent) {
        isFormSubmitted.next(false);
      }
    });
    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.houseRules.concat(
        addPropertyState.houseRule.trim()
      );
      if (addPropertyState.houseRule !== "") {
        updateAddPropertyState({ houseRules: joined, houseRule: "" });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.houseRule]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.healthSafeties.concat(
        addPropertyState.healthSafety.trim()
      );
      if (addPropertyState.healthSafety !== "") {
        updateAddPropertyState({ healthSafeties: joined, healthSafety: "" });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.healthSafety]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.cancellationPolicies.concat(
        addPropertyState.cancellationPolicy.trim()
      );
      if (addPropertyState.cancellationPolicy !== "") {
        updateAddPropertyState({
          cancellationPolicies: joined,
          cancellationPolicy: "",
        });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.cancellationPolicy]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.amenities.concat(
        addPropertyState.amenity.trim()
      );
      if (addPropertyState.amenity !== "") {
        updateAddPropertyState({ amenities: joined, amenity: "" });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.amenity]);

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <AddHouseDetails />;
      case 1:
        return <AddHouseAddress />;
      case 2:
        return <AddHouseRules />;
      case 3:
        return "";
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      validateHouseDetails();
      const isValid = checkIfAllFieldsValid();
      if (!isValid) {
        addPropertyState.steps[0].error = true;
      } else {
        addPropertyState.steps[0].error = false;
      }
    }

    if (activeStep === 1) {
      validateHouseAddress();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    updateAddPropertyState(addPropertyStateDefault);
  };

  const validateHouseDetails = () => {
    // house name
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.houseName,
      validationMessages.houseName,
      "isHouseNameInvalid",
      "houseNameValidationMessage",
      "houseName"
    );

    // house type
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.houseType,
      validationMessages.houseType,
      "isHouseTypeInvalid",
      "houseTypeValidationMessage",
      "houseType"
    );

    // house description
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.houseDescription,
      validationMessages.houseDescription,
      "isHouseDescriptionInvalid",
      "houseDescriptionValidationMessage",
      "houseDescription"
    );

    // house Bed
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.houseBed,
      validationMessages.houseBed,
      "isHouseBedInvalid",
      "houseBedValidationMessage",
      "houseBed"
    );

    // house Bath
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.houseBath,
      validationMessages.houseBath,
      "isHouseBathInvalid",
      "houseBathValidationMessage",
      "houseBath"
    );

    // Guest limit
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.guestLimit,
      validationMessages.guestLimit,
      "isGuestLimitInvalid",
      "guestLimitValidationMessage",
      "guestLimit"
    );

    // Price
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.pricePerNight,
      validationMessages.price,
      "isPricePerNightInvalid",
      "pricePerNightValidationMessage",
      "pricePerNight"
    );
  };

  function validateHouseAddress() {
    // Address
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.locationAddress,
      validationMessages.address,
      "isLocationAddressInvalid",
      "locationAddressValidationMessage",
      "locationAddress"
    );

    // City
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.locationCity,
      validationMessages.city,
      "isLocationCityInvalid",
      "locationCityValidationMessage",
      "locationCity"
    );

    // State
    updatePropertyStatesFunc(
      updateAddPropertyState,
      addPropertyState.locationState,
      validationMessages.state,
      "isLocationStateInvalid",
      "locationStateValidationMessage",
      "locationState"
    );

    //Zip code
    const validation = zipCodeCheck(
      addPropertyState.locationZipCode,
      validationMessages.zipCode1,
      validationMessages.zipCode2,
      validationMessages.zipCode3
    );

    if (validation.isInvalid) {
      updateAddPropertyState({
        isLocationZipCodeInvalid: validation.isInvalid,
        locationZipCodeValidationMessage: validation.validationError,
      });
    } else {
      updateAddPropertyState({
        locationZipCode: addPropertyState.locationZipCode,
        isLocationZipCodeInvalid: false,
        locationZipCodeValidationMessage: "",
      });
    }
  }

  function checkIfAllFieldsValid() {
    return addPropertyState.guestLimit !== 0 &&
      addPropertyState.houseBath !== 0 &&
      addPropertyState.houseBed !== 0 &&
      addPropertyState.houseDescription !== "" &&
      addPropertyState.houseName !== "" &&
      addPropertyState.houseType !== "" &&
      addPropertyState.locationAddress !== "" &&
      addPropertyState.locationCity !== "" &&
      addPropertyState.locationState !== "" &&
      addPropertyState.locationZipCode !== "" &&
      addPropertyState.pricePerNight !== ""
      ? true
      : false;
  }

  // Form submission
  const handleSubmit = (e: any) => {
    isFormSubmitted.next(true);
    e.preventDefault();
    if (checkIfAllFieldsValid()) {
      const ids = addPropertyState.propertyImages.map(function (id) {
        return id.ids;
      });
      const body = {
        property_name: addPropertyState.houseName,
        property_description: addPropertyState.houseDescription,
        property_type: addPropertyState.houseType,
        property_beds: addPropertyState.houseBed,
        property_baths: addPropertyState.houseBath,
        property_guest_limit: addPropertyState.guestLimit,
        property_price_per_night: addPropertyState.pricePerNight,
        geo_location: addPropertyState.viewport,
        property_location: {
          property_address1: addPropertyState.locationAddress,
          property_city: addPropertyState.locationCity,
          property_state: addPropertyState.locationState,
          property_zipCode1: addPropertyState.locationZipCode,
        },
        property_partial_location: {
          property_city: addPropertyState.locationCity,
          property_state: addPropertyState.locationState,
        },
        property_images: ids,
        thumbnail:
          addPropertyState.propertyImages.length !== 0
            ? addPropertyState.propertyImages[0].filepath
            : "",
        image_title:
          addPropertyState.propertyImages.length !== 0
            ? addPropertyState.propertyImages[0].filename
            : "",
        things_to_know: {
          house_rules: addPropertyState.houseRules,
        },
        offers: {
          // Bathroom
          bathtub: addPropertyState.bathtub,
          hairDryer: addPropertyState.hairDryer,
          cleaningProducts: addPropertyState.cleaningProducts,
          shampoo: addPropertyState.shampoo,
          conditioner: addPropertyState.conditioner,
          bodySoap: addPropertyState.bodySoap,
          hotWater: addPropertyState.hotWater,
          showerGel: addPropertyState.showerGel,
          // Bedroom and laundry
          freeWasherInBuilding: addPropertyState.freeWasherInBuilding,
          freeDryerInBuilding: addPropertyState.freeDryerInBuilding,
          essentials: addPropertyState.essentials,
          hangers: addPropertyState.hangers,
          bedLinens: addPropertyState.bedLinens,
          extraPillowsAndBlankets: addPropertyState.extraPillowsAndBlankets,
          iron: addPropertyState.iron,
          clothingStorage: addPropertyState.clothingStorage,
          //Entertaiment
          enthernetConnection: addPropertyState.enthernetConnection,
          smartTv: addPropertyState.smartTv,
          // Family
          Crib: addPropertyState.Crib,
          PackNPlay: addPropertyState.PackNPlay,
          highChair: addPropertyState.highChair,
          babyBath: addPropertyState.babyBath,
          childrensDinnerware: addPropertyState.childrensDinnerware,
          boardGames: addPropertyState.boardGames,
          babySafetyGates: addPropertyState.babySafetyGates,
          // Heating and cooling
          centralAirConditioning: addPropertyState.centralAirConditioning,
          ceilingFan: addPropertyState.ceilingFan,
          portableFans: addPropertyState.portableFans,
          heating: addPropertyState.heating,
          //Home safety
          securityCamerasOnProperty: addPropertyState.securityCamerasOnProperty,
          smokeAlarm: addPropertyState.smokeAlarm,
          carbonMonoxideAlarm: addPropertyState.carbonMonoxideAlarm,
          fireExtinguisher: addPropertyState.fireExtinguisher,
          firstAidKit: addPropertyState.firstAidKit,
          // Internet and office
          wifi: addPropertyState.wifi,
          dedicatedWorkspace: addPropertyState.dedicatedWorkspace,
          // Kitchen and dining
          kitchen: addPropertyState.kitchen,
          refrigerator: addPropertyState.refrigerator,
          microwave: addPropertyState.microwave,
          cookingBasics: addPropertyState.cookingBasics,
          dishesAndSilverware: addPropertyState.dishesAndSilverware,
          freezer: addPropertyState.freezer,
          dishwasher: addPropertyState.dishwasher,
          stainlessSteelElectricStove:
            addPropertyState.stainlessSteelElectricStove,
          oven: addPropertyState.oven,
          hotWaterKettle: addPropertyState.hotWaterKettle,
          coffeeMaker: addPropertyState.coffeeMaker,
          wineGlasses: addPropertyState.wineGlasses,
          toaster: addPropertyState.toaster,
          bakingSheet: addPropertyState.bakingSheet,
          blender: addPropertyState.blender,
          barbecueUtensils: addPropertyState.barbecueUtensils,
          diningTable: addPropertyState.diningTable,
          coffee: addPropertyState.coffee,
          // Location features
          privateEntrance: addPropertyState.privateEntrance,
          // Outdoor
          sharedPatioOrBalcony: addPropertyState.sharedPatioOrBalcony,
          privateBackyardNotFullyFenced:
            addPropertyState.privateBackyardNotFullyFenced,
          outdoorFurniture: addPropertyState.outdoorFurniture,
          outdoorDiningArea: addPropertyState.outdoorDiningArea,
          bbqGrill: addPropertyState.bbqGrill,
          // Parking and facilities
          freeParkingOnPremises: addPropertyState.freeParkingOnPremises,
          freeStreetParking: addPropertyState.freeStreetParking,
          // Services
          luggageDropoffAllowed: addPropertyState.luggageDropoffAllowed,
          longTermStaysAllowed: addPropertyState.longTermStaysAllowed,
          smartLock: addPropertyState.smartLock,
          cleaningAvailableDuringStay:
            addPropertyState.cleaningAvailableDuringStay,
        },
      };
      propertyApiService
        .createProperty(body)
        .then((data) => {
          handleNext();
          dispatch(
            addSnackBar({
              message: "New house successfully added.",
              type: "success",
            })
          );
        })
        .catch((error) => {
          setActiveStep(2);
          dispatch(
            addSnackBar({
              message: `Error occured while saving`,
              type: "error",
            })
          );
        });
    } else {
      setActiveStep(2);
      dispatch(
        addSnackBar({
          message: `One or more invalid field(s) occured`,
          type: "error",
        })
      );
    }
  };
  const handleOnClickBackBtn = () => {
    if (activeStep === 0) {
      updateAddPropertyState({ toListing: true });
    } else {
      handleBack();
    }
  };

  const handleCloseDialog = () => {
    updateAddPropertyState({ toListing: false });
  };

  const handleConfirmRequest = () => {
    history.push(Paths.propertyList);
    handleBack();
    updateAddPropertyState({ toListing: false });
  };

  return (
    <>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {addPropertyState.steps.map((label) => (
            <Step key={label.label}>
              <StepLabel error={label.error}>{label.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Paper component="main" elevation={0}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            gutterBottom
            color="textSecondary"
            style={{ textTransform: "uppercase", fontSize: "1.5em" }}
          >
            Add house
          </Typography>
          <form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
            <div className={classes.form}>
              {activeStep !== addPropertyState.steps.length ? (
                <>
                  {getStepContent(activeStep)}
                  <Button
                    onClick={handleOnClickBackBtn}
                    className={classes.backButton}
                    variant="contained"
                    size={"small"}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: theme.palette.common.white,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                    onClick={handleNext}
                    disabled={addPropertyState.disableBtn}
                    size={"small"}
                    type={activeStep === 3 ? "submit" : "button"}
                  >
                    {activeStep === addPropertyState.steps.length - 2
                      ? "Submit"
                      : "Next"}
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleReset}
                  className={classes.backButton}
                  variant="contained"
                >
                  Reset
                </Button>
              )}
            </div>
          </form>
          <KEEWISTFormDialog
            warningQuestion={
              "You are about to exit without saving. Are you sure?"
            }
            content={""}
            isOpen={addPropertyState.toListing}
            handleClose={handleCloseDialog}
            handleConfirm={handleConfirmRequest}
          />
        </Paper>
      </div>
    </>
  );
};

export default AddNewProperty;
