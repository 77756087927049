import React, { useEffect } from "react";
import { Container } from "@mui/material";

import TodoTabs from "./components/TodoTabs";
import { TodosApiService } from "../../../api-kit/todos/todos-api.services";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../_redux/_actions/snackBar.actions";

export default function ThingsToDo() {
  const todosApiService = new TodosApiService();
  const dispatch = useDispatch();
  const [locations, setLocations] = React.useState([]);
  const [currentTabValue, setCurrentTabValue] = React.useState("1");
  const [tabs, setTab] = React.useState([]);

  useEffect(() => {
    window.scroll(0, 0);
    // call todo api if successfull set locations to state
    todosApiService
      .getTodos()
      .then((res) => {
        let allLocations = [];
        let places = [];
        res.data.map((tab, index) => {
          let tabValue = ++index + 1;
          tab.value = tabValue.toString();
          places.push(...tab.content);
          tab.content.map((location) => {
            allLocations.push(location.geo_location);
          });
        });
        if (res.data.length !== 0) {
          const all = {
            content: places,
            disabled: false,
            label: "All",
            value: "1",
          };
          res.data.unshift(all);
        }
        setLocations(allLocations);
        setTab(res.data);
        console.log("Todos ", res.data)
      })
      .catch((err) => {
        dispatch(
          addSnackBar({
            message: "Unable to get todos.",
            type: "error",
          })
        );
      });
  }, []);

  useEffect(() => {
    let allPlaces = [];
    let allLocations = [];
    if (tabs.length !== 0) {
      tabs.map(
        (place) =>
          currentTabValue === place.value && allPlaces.push(place.content)
      );

      if (allPlaces.length !== 0) {
        allPlaces.map((location) => {
          location.map((geo) => allLocations.push(geo.geo_location));
        });
      }
    }
    setLocations(allLocations);
  }, [currentTabValue]);

  const handleTabChange = (value: any) => {
    setCurrentTabValue(value);
  };

  return (
    <React.Fragment>
      <Container component="main">
        <TodoTabs
          locations={locations}
          tabs={tabs}
          handleChange={(value) => handleTabChange(value)}
        />
      </Container>
    </React.Fragment>
  );
}
