import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Typography } from "@material-ui/core";

interface KEEWISTEditorProps {
  onReady?: any;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  data?: string;
  isInValid?: boolean;
  errorMessage?: string;
}

const KEEWISTEditor = (props: KEEWISTEditorProps) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={props.data !== undefined ? props.data : ""}
        // onReady={(editor) => {
        //   // You can store the "editor" and use when it is needed.
        //   props.onReady(editor);
        // }}
        onChange={(event, editor) => {
          props.onChange(editor.getData());
        }}
        onBlur={(event, editor) => {
          props.onBlur(editor.getData());
        }}
        // onFocus={(event, editor) => {
        //   props.onFocus(editor);
        // }}
      />
      {props.isInValid && (
        <Typography variant={"caption"} color="error">
          {props.errorMessage !== null && props.errorMessage}
        </Typography>
      )}
    </>
  );
};

export default KEEWISTEditor;
