import React, { useContext } from "react";
import { Chip, TextField } from "@material-ui/core";
import theme from "../../../../../theme/theme";
import { PropertyStateContext } from "../../PropertyContext";
import styles from "../styles";

const EditHouseRules = () => {
  const classes = styles();
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);

  const onDeleteRule = (value) => {
    console.log("Rule delete clicked.");
    const RuleUpdated = addPropertyState.houseRules.filter(
      (rule) => rule !== value
    );
    updateAddPropertyState({ houseRules: RuleUpdated });
  };
  const onDeleteHealthSafety = (value) => {
    const HealthSafety = addPropertyState.healthSafeties.filter(
      (healthSafety) => healthSafety !== value
    );
    updateAddPropertyState({ healthSafeties: HealthSafety });
  };

  const onDeleteCancellationPolicy = (value: any) => {
    const PolicyUpdated = addPropertyState.cancellationPolicies.filter(
      (policy) => policy !== value
    );
    updateAddPropertyState({ cancellationPolicies: PolicyUpdated });
  };

  const onDeleteAmenities = (value: any) => {
    const AmenitiesUpdated = addPropertyState.amenities.filter(
      (policy) => policy !== value
    );
    updateAddPropertyState({ amenities: AmenitiesUpdated });
  };


  return (
    <>
      <TextField
        variant="outlined"
        fullWidth
        margin="normal"
        name="house-rules"
        label="House rules"
        type="text"
        id="house-rules"
        onChange={(e) => updateAddPropertyState({ houseRule: e.target?.value })}
      />

      <br />
      <div className={classes.chipsRow}>
        {addPropertyState.houseRules != null &&
          addPropertyState.houseRules !== undefined &&
          addPropertyState.houseRules.map((item, index) => (
            <Chip
              key={index}
              size="small"
              label={item}
              onDelete={() => onDeleteRule(item)}
              className={classes.chips}
              color="primary"
            />
          ))}
      </div>
      <TextField
        variant="outlined"
        fullWidth
        name="health-safety"
        label="Health Safety"
        margin="normal"
        type="text"
        id="health-safety"
        onChange={(e: any) =>
          updateAddPropertyState({ healthSafety: e.target?.value })
        }
      />
      <div className={classes.chipsRow}>
        {addPropertyState.healthSafeties != null &&
          addPropertyState.healthSafeties !== undefined &&
          addPropertyState.healthSafeties.map((item, index) => (
            <Chip
              key={index}
              size="small"
              label={item}
              onDelete={() => onDeleteHealthSafety(item)}
              className={classes.chips}
              color="primary"
            />
          ))}
      </div>
      <TextField
        variant="outlined"
        fullWidth
        name="cancellation-policy"
        label="Cancellation policy"
        margin="normal"
        type="text"
        id="cancellation-policy"
        onChange={(e: any) =>
          updateAddPropertyState({ cancellationPolicy: e.target?.value })
        }
      />
      <div className={classes.chipsRow}>
        {addPropertyState.cancellationPolicies != null &&
          addPropertyState.cancellationPolicies !== undefined &&
          addPropertyState.cancellationPolicies.map((item, index) => (
            <Chip
              key={index}
              size="small"
              label={item}
              onDelete={() => onDeleteCancellationPolicy(item)}
              className={classes.chips}
              color="primary"
            />
          ))}
      </div>
      <TextField
        variant="outlined"
        fullWidth
        name="house-amenities"
        label="Amenities"
        margin="normal"
        type="text"
        id="house-amenities"
        onChange={(e: any) =>
          updateAddPropertyState({ amenity: e.target?.value })
        }
      />
      <div className={classes.chipsRow}>
        {addPropertyState.amenities != null &&
          addPropertyState.amenities !== undefined &&
          addPropertyState.amenities.map((item, index) => (
            <Chip
              key={index}
              size="small"
              label={item}
              onDelete={() => onDeleteAmenities(item)}
              className={classes.chips}
              color="primary"
            />
          ))}
      </div>
      <div style={{ marginBottom: theme.spacing(1) }}></div>
    </>
  );
};

export default EditHouseRules;
