import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import House from "./components/House";
import SKELETONMedia from "../../../ui-kit/components/SKELETON-media";
import styles from "./styles";

export default function Houses() {
  const classes = styles();
  const houses = useSelector((state: any) => state.houses);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Box mt={15}>
        <Container component="main">
          <Typography
            className={classes.typograph}
            component="h4"
            variant="subtitle1"
          >
            Rental <span>houses</span>
          </Typography>
          <Box mt={2}></Box>
          <Grid container spacing={2}>
            {houses.length !== 0
              ? houses.map((house, idx) => (
                  <Grid key={idx} item xs={12} sm={6} md={3} lg={3}>
                    <House
                      type={house.property_type}
                      title={house.property_name}
                      subheader={house.property_partial_location}
                      thumbnail={house.thumbnail}
                      images={house.images}
                      imageTitle={house.image_title}
                      beds={house.property_beds}
                      baths={house.property_baths}
                      price={house.property_price_per_night}
                      available={house.property_status}
                      _id={house._id}
                    />
                    <Box mt={4}></Box>
                  </Grid>
                ))
              : [...Array(4)].map((e, i) => (
                  <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
                    <SKELETONMedia loading />
                  </Grid>
                ))}
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
}
