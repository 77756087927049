import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    minHeight: 210
  },
  avatar: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  description: {
    textAlign: "justify",
  },
  content: {
    minHeight: 112,
  },
  type: {
    textTransform:"uppercase",
    fontSize:"0.8rem"
  },
  actions: {
    "& button": {
      marginRight: 8,
    },
    "& span": {
      fontFamily: "'Roboto', 'sans-serif'",
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop:0,
      "& button": {
        marginRight: 9,
      },
    },
  },
}));
