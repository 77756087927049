import { Grid, Typography } from "@mui/material";
import styles from "../styles";
import ReactHtmlParser from "react-html-parser";
import SKELETONText from "../../../../ui-kit/components/SKELETON-text";

interface WhyChooseUsProps {
  whyChooseUs: string;
}

const WhyChooseUs = (props: WhyChooseUsProps) => {
  const classes = styles();
  return (
    <Grid item xs={12}>
      <Typography
        className={classes.typograph}
        component="h4"
        variant="subtitle1"
      >
        Why <span>Choose us</span>
      </Typography>
      {props.whyChooseUs !== "" ? (
        <div className={classes.paragraph} style={{ margin: 0 }}>
          {ReactHtmlParser(props.whyChooseUs)}
        </div>
      ) : (
        <>
          <SKELETONText />
          <SKELETONText />
        </>
      )}
    </Grid>
  );
};

export default WhyChooseUs;
