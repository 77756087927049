import React, { useEffect, useState } from "react";
import { logout } from "../../../_redux/_actions/user.actions";
import styles from "./styles";
import whiteLogo from "../../../assets/images/white_logo.png";
import blackLogo from "../../../assets/images/black_logo.png";
import {
  AppBar,
  Button,
  Toolbar,
  ButtonGroup,
  IconButton,
  MenuItem,
  Container,
  SwipeableDrawer,
} from "@mui/material";
import { connect } from "react-redux";
import { Paths } from "../../Router";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { ExitToApp, MenuRounded } from "@mui/icons-material";
import theme from "../../theme/theme";
import PositionedMenu from "../../../ui-kit/components/KEEWIST-menu";

interface KEEWISTAppBarProps {
  logout: any;
  user: any;
  children: any;
}

const KEEWISTAppBar = (props: KEEWISTAppBarProps): React.ReactElement => {
  const classes = styles();
  const history = useHistory();
  const [navBar, setNavbar] = useState(false);
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScrollY);
    return () => {
      window.removeEventListener("scroll", handleOnScrollY);
    };
  }, []);

  const handleOnScrollY = () => {
    if (window.scrollY > 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const logout = () => {
    props.logout();
  };

  const mobileNavBar = (
    <>
      <MenuItem onClick={() => history.push(Paths.home)}>
        <p>Home</p>
      </MenuItem>
      {props.user?.loggedIn && (
        <MenuItem onClick={() => history.push(Paths.dashboard)}>
          <p>Dashboard</p>
        </MenuItem>
      )}
      <MenuItem onClick={() => history.push(Paths.news)}>
        <p>News</p>
      </MenuItem>
      <MenuItem onClick={() => history.push(Paths.rentals)}>
        <p>Listing</p>
      </MenuItem>
      <MenuItem onClick={() => history.push(Paths.thingstodo)}>
        <p>Activities</p>
      </MenuItem>
    </>
  );

  const mobileNavBarLogin = (
    <MenuItem>
      <ButtonGroup
        variant="contained"
        aria-label="contained primary button group"
      >
        <Button
          onClick={() => history.push(Paths.login)}
          variant="contained"
          style={{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.info.main,
          }}
          size={"small"}
        >
          login
        </Button>
        <Button
          onClick={() => history.push(Paths.register)}
          variant="contained"
          style={{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          }}
          size={"small"}
        >
          Register
        </Button>
      </ButtonGroup>
    </MenuItem>
  );
  const mobileNavBarLogout = (
    <MenuItem>
      <Button
        onClick={logout}
        variant="contained"
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        size={"small"}
      >
        <ExitToApp />
      </Button>
    </MenuItem>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.menuBarMobile}>
        {props.user.loggedIn ? (
          <>
            {mobileNavBar} {mobileNavBarLogout}
          </>
        ) : (
          <>
            {mobileNavBar} {mobileNavBarLogin}
          </>
        )}
      </div>
    </div>
  );

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
          color: theme.palette.primary.light,
        }}
        className={clsx(navBar ? classes.active : classes.inactive)}
      >
        <Container disableGutters>
          <Toolbar variant="dense">
            <IconButton
              onClick={() => history.push(Paths.home)}
              edge="start"
              size="small"
            >
              <img
                src={navBar ? whiteLogo : blackLogo}
                alt="keewist logo"
                className={classes.logo}
              />
            </IconButton>
            <div className={classes.menuBar}>
              <Button color="inherit" onClick={() => history.push(Paths.home)}>
                home
              </Button>
              <Button color="inherit" onClick={() => history.push(Paths.news)}>
                news
              </Button>
              <Button
                color="inherit"
                onClick={() => history.push(Paths.rentals)}
              >
                listing
              </Button>
              <Button
                color="inherit"
                onClick={() => history.push(Paths.thingstodo)}
              >
                activities
              </Button>
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {!props.user?.loggedIn ? (
                <ButtonGroup
                  variant="contained"
                  aria-label="contained primary button group"
                >
                  <Button
                    onClick={() => history.push(Paths.login)}
                    variant="contained"
                    style={{
                      color: theme.palette.common.white,
                      backgroundColor: theme.palette.info.main,
                    }}
                    size={"small"}
                  >
                    login
                  </Button>
                  <Button
                    onClick={() => history.push(Paths.register)}
                    variant="contained"
                    style={{
                      color: theme.palette.common.white,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                    size={"small"}
                  >
                    Register
                  </Button>
                </ButtonGroup>
              ) : (
                <PositionedMenu logout={logout} />
              )}
            </div>
            <div className={classes.menuBarMobileIcon}>
              <IconButton
                aria-label="hamburger"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuRounded color="inherit" />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <SwipeableDrawer
        anchor={"right"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className={classes.drawerContent}>{renderMobileMenu("right")}</div>
      </SwipeableDrawer>
      {props.children}
    </>
  );
};
const mapDispatchToProps = (props) => {
  return {
    user: props.user,
  };
};
export default connect(mapDispatchToProps, { logout })(KEEWISTAppBar);
