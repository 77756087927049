import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { NumberFormatCustom } from "../../../../../../ui-kit/common/NumberFormat";
import theme from "../../../../../theme/theme";
import { PropertyStateContext } from "../../PropertyContext";
import { validationMessages } from "../../helpers/constants";
import { updatePropertyStatesFunc } from "../../helpers/HelperFunctions";
import styles from "../styles";
import {
  Box,
  FormControl,
  FormHelperText,
  ImageList,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import KEEWISTFileUpload from "../../../../../../ui-kit/components/KEEWIST-fileUpload";
import { UploadApiService } from "../../../../../../api-kit/upload/upload-api.services";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import { useDispatch } from "react-redux";
import Card from "../../../../../../ui-kit/components/KEEWIST-card";

const EditHouseDetails = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const uploadApiService = new UploadApiService();
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);

  const handleHouseNameChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseName,
      "isHouseNameInvalid",
      "houseNameValidationMessage",
      "houseName"
    );
  };
  const handleHouseTypeChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseType,
      "isHouseTypeInvalid",
      "houseTypeValidationMessage",
      "houseType"
    );
  };
  const handleHouseDescriptionChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseDescription,
      "isHouseDescriptionInvalid",
      "houseDescriptionValidationMessage",
      "houseDescription"
    );
  };
  const handleHouseBedChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseBed,
      "isHouseBedInvalid",
      "houseBedValidationMessage",
      "houseBed"
    );
  };
  const handleHouseBathChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseBath,
      "isHouseBathInvalid",
      "houseBathValidationMessage",
      "houseBath"
    );
  };
  const handleGuestLimitChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.guestLimit,
      "isGuestLimitInvalid",
      "guestLimitValidationMessage",
      "guestLimit"
    );
  };
  const handlePriceChange = (e: any) => {
    let value = e.target.value;
    if (value !== "") {
      value.replace(/-/g, "");
      updateAddPropertyState({ focused: true });
    } else {
      updateAddPropertyState({ focused: false });
    }
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value.replace(/-/g, ""),
      validationMessages.price,
      "isPricePerNightInvalid",
      "pricePerNightValidationMessage",
      "pricePerNight"
    );
  };

  const moveImage = (dragIndex, hoverIndex) => {
    let clonedCards = addPropertyState.propertyImages;
    if (clonedCards !== undefined && clonedCards.length !== 0) {
      const removedItem = clonedCards.splice(dragIndex, 1)[0];
      clonedCards.splice(hoverIndex, 0, removedItem);
      updateAddPropertyState({ propertyImages: clonedCards });
      // Get all ids
      const photos = clonedCards.map(function (img, index) {
        img.position = index;
        return img;
      });
      uploadApiService
        .updateOrder(photos)
        .then((res) => {
          dispatch(
            addSnackBar({
              message: "Image uploaded.",
              type: "success",
            })
          );
        })
        .catch((err) =>
          dispatch(
            addSnackBar({
              message: "Unable to update image order.",
              type: "error",
            })
          )
        );
    }
  };

  const handleImageUpload = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i]);
    }
    formData.append("length", files.length);
    updateAddPropertyState({ disableBtn: true });
    uploadApiService
      .upload(formData)
      .then((res) => {
        const joined = addPropertyState.propertyImages.concat(res.data);
        updateAddPropertyState({ propertyImages: joined, disableBtn: false });
        dispatch(
          addSnackBar({
            message: "Image order updated.",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to upload image.",
            type: "error",
          })
        )
      );

    const joined = addPropertyState.propertyImages.concat(formData);
    updateAddPropertyState({ propertyImages: joined });
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-name"
        label="Name"
        type="text"
        id="house-name"
        value={
          addPropertyState.houseName !== undefined
            ? addPropertyState.houseName
            : ""
        }
        onBlur={(e: any) => handleHouseNameChange(e)}
        onChange={(e: any) => handleHouseNameChange(e)}
        error={addPropertyState.isHouseNameInvalid}
        helperText={addPropertyState.houseNameValidationMessage}
      />
      <FormControl
        variant="outlined"
        size="medium"
        margin="normal"
        className={classes.formControl}
      >
        <InputLabel id="keewist-select-type-label">House type</InputLabel>
        <Select
          labelId="keewist-select-type-label"
          id="keewist-select-type"
          value={
            addPropertyState.houseType
              ? addPropertyState.houseType
              : "entirehouse"
          }
          onBlur={(e: any) => handleHouseTypeChange(e)}
          onChange={(e: any) => handleHouseTypeChange(e)}
          error={addPropertyState.isHouseTypeInvalid}
          label="House type"
        >
          <MenuItem value={"entirehouse"}>entirehouse</MenuItem>
          <MenuItem value={"privateroom"}>privateroom</MenuItem>
        </Select>
        <FormHelperText style={{ color: " #E73935" }}>
          {addPropertyState.houseTypeValidationMessage}
        </FormHelperText>
      </FormControl>
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-description"
        label="Description"
        type="text"
        id="house-description"
        value={
          addPropertyState.houseDescription
            ? addPropertyState.houseDescription
            : ""
        }
        onBlur={(e: any) => handleHouseDescriptionChange(e)}
        onChange={(e: any) => handleHouseDescriptionChange(e)}
        error={addPropertyState.isHouseDescriptionInvalid}
        helperText={addPropertyState.houseDescriptionValidationMessage}
      />
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-beds"
        label="Beds"
        type="number"
        InputProps={{ inputProps: { min: 0, max: 5 } }}
        id="house-beds"
        onBlur={(e: any) => handleHouseBedChange(e)}
        onChange={(e: any) => handleHouseBedChange(e)}
        value={addPropertyState.houseBed ? addPropertyState.houseBed : ""}
        error={addPropertyState.isHouseBedInvalid}
        helperText={addPropertyState.houseBedValidationMessage}
      />
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-baths"
        label="Baths"
        type="number"
        id="house-baths"
        InputProps={{ inputProps: { min: 0, max: 5 } }}
        onBlur={(e: any) => handleHouseBathChange(e)}
        onChange={(e: any) => handleHouseBathChange(e)}
        value={addPropertyState.houseBath ? addPropertyState.houseBath : ""}
        error={addPropertyState.isHouseBathInvalid}
        helperText={addPropertyState.houseBathValidationMessage}
      />

      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-guest-limit"
        label="Guest limit"
        type="number"
        InputProps={{ inputProps: { min: 0, max: 10 } }}
        id="house-guest-limit"
        onBlur={(e: any) => handleGuestLimitChange(e)}
        onChange={(e: any) => handleGuestLimitChange(e)}
        value={addPropertyState.guestLimit ? addPropertyState.guestLimit : ""}
        error={addPropertyState.isGuestLimitInvalid}
        helperText={addPropertyState.guestLimitValidationMessage}
      />
      <TextField
        label="Price per night"
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        value={
          addPropertyState.pricePerNight ? addPropertyState.pricePerNight : ""
        }
        onBlur={(e: any) => handlePriceChange(e)}
        onChange={(e: any) => handlePriceChange(e)}
        focused={addPropertyState.focused}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        error={addPropertyState.isPricePerNightInvalid}
        helperText={addPropertyState.pricePerNightValidationMessage}
      />
      <Box sx={{ mt: theme.spacing(0.1) }}>
        <KEEWISTFileUpload handleImageUpload={handleImageUpload} />
      </Box>
      <Box>
        <ImageList
          sx={{ width: "100%", height: "100%", mt: 1 }}
          cols={3}
          rowHeight={164}
        >
          {React.Children.toArray(
            addPropertyState.propertyImages !== undefined &&
              addPropertyState.propertyImages.length !== 0 &&
              addPropertyState.propertyImages.map((img, key) => (
                <Card
                  src={img?.filepath}
                  title={img?.filename}
                  id={img?.id}
                  index={key}
                  moveImage={moveImage}
                />
              ))
          )}
        </ImageList>
      </Box>
      <div style={{ marginBottom: theme.spacing(1) }}></div>
    </>
  );
};

export default EditHouseDetails;
