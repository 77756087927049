import axios from "axios";

export interface ApiParameters {
  name: string;
  method: any;
  headers: { [key: string]: string };
  params: { [key: string]: any };
  suffixURL: string;
  baseURL: string;
  body: { [key: string]: any };
  timeout: number;
  withCredentials: boolean;
  responseType: any;
  responseEncoding: string;
}

const onSuccess = (response: any) => {
  return Promise.resolve(response);
};

const onError = (error: any) => {
  console.log(error);
  if (error.response) console.log("Server Side Error: " + error.response);
  else console.error("Request Error:", error.message);
  return Promise.reject(error.response || error.message);
};

export class WrapperService {
  public APIs: any = {
    user: "/user",
    property: "/property",
    home: "/home",
    about: "/about",
    todo: "/todo",
    news: "/news",
    hygiene: "/hygiene",
    booking: "/booking",
    upload: "/upload",
    auth: "/auth",
    policy: "/policy",
  };
  public httpCall(apiParam: any, authenticatedCall: boolean = false) {
    const middleURL = apiParam.name ? this.APIs[apiParam.name] : "";
    const suffixURL = apiParam.suffixURL || "";
    return axios
      .request({
        baseURL: apiParam.baseURL,
        url: `${middleURL}${suffixURL}`,
        method: apiParam.method,
        params: apiParam.params,
        data: apiParam.body,
        headers: apiParam.headers,
        timeout: apiParam.timeout,
        responseType: apiParam.responseType,
      })
      .then(onSuccess)
      .catch(onError);
  }
}
