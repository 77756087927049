import React, { useEffect, useState } from "react";
import KEEWISTTable from "../policies/components/PoliciesTable";
import { useDispatch } from "react-redux";
import { PolicyApiService } from "../../../../api-kit/policy/policy-api.services";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";

const Policies = () => {
  const dispatch = useDispatch();
  const [policies, setPolicyList] = useState([]);
  const policyApiService = new PolicyApiService();

  useEffect(() => {
    policyApiService
      .getPolicies()
      .then((res) => {
        setPolicyList(res.data);
      })
      .catch((error) => console.log(error));
    return () => {
      setPolicyList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: "Type",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "createdAt",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const handleOnDelete = (id: string) => {
    policyApiService
      .deletePolicy(id)
      .then((res) => {
        const updatedPolicy = policies.filter(
          (policy) => policy._id !== res.data.id
        );
        setPolicyList(updatedPolicy);
        dispatch(
          addSnackBar({
            message: "Policy successfully deleted!",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to delete this policy!",
            type: "error",
          })
        )
      );
  };

  return (
    <>
      <KEEWISTTable
        rows={policies}
        headCells={headCells}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};

export default Policies;
