import { Container, Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import styles from "../styles";
import ImageSlider from "./ImageSlider";
import LeftSideContent from "../components/LeftSideContent";
import RightSideContent from "./form/RightSideContent";
import theme from "../../../theme/theme";
import AddLocationsToMap from "../../../common/mapBox/AddLocationsToMap";
import { PropertyApiService } from "../../../../api-kit/property/property-api.service";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import { EntireHouseStateContext } from "../EntireHouseContext";
import { PageHelper, Paths } from "../../../Router";
import store from "../../../../_redux/_helpers/store";
import { PolicyApiService } from "../../../../api-kit/policy/policy-api.services";

const ViewHouse = () => {
  const classes = styles();
  const propertyApiService = new PropertyApiService();
  const policyApiService = new PolicyApiService();
  const { entireHouseState, updateEntireHouseState } = useContext(
    EntireHouseStateContext
  );
  const dispatch = useDispatch();
  const location: any = useLocation();

  useEffect(() => {
    policyApiService
      .getPolicies()
      .then((res) => {
        updateEntireHouseState({
          cleaningFee: res.data[0].cleaning_fee,
          commonDiscount: res.data[0].discounted_price,
          weeklyDiscount: res.data[1].discounted_price,
          monthlyDiscount: res.data[2].discounted_price,
          serviceFee: res.data[0].service_fee,
          cancellationPolicy: res.data,
        });
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to get a policies!",
            type: "error",
          })
        )
      );
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    const userInfo = store.getState().user;
    if (userInfo.loggedIn) {
      updateEntireHouseState({ isUserLoggedIn: true });
    }
    const booking = [
      {
        startDate: new Date("12/26/2021"),
        endDate: new Date("12/28/2021"),
        key: "selection",
      },
    ];

    if (location.state !== undefined && location.state.houseId !== null) {
      propertyApiService
        .getProperty(location.state.houseId)
        .then((res) => {
          updateEntireHouseState(res.data);
          updateEntireHouseState({ booked: booking });
          let locations = [];
          locations.push(res.data?.geo_location);
          updateEntireHouseState({
            geo_location: locations,
            booked: booking,
            loading: true,
            id: res.data?._id,
          });
        })
        .catch((err) =>
          dispatch(
            addSnackBar({
              message: "Unable to get a house!",
              type: "error",
            })
          )
        );
    } else {
      PageHelper.openPage(Paths.rentals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ImageSlider images={entireHouseState.property_images} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} className={classes.content}>
          <LeftSideContent />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.map}>
          <RightSideContent />
        </Grid>
        <Grid item xs={12}>
          <Divider
            light
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
          />
          <Typography
            className={classes.typograph}
            component="h4"
            variant="subtitle1"
            style={{ marginBottom: theme.spacing(1) }}
          >
            Where <span>you’ll be</span>
          </Typography>
          <Typography
            component="h4"
            variant="caption"
            style={{ marginBottom: theme.spacing(1) }}
          >
            exact location is not shown.
          </Typography>
          {entireHouseState.loading && (
            <AddLocationsToMap
              latitude={entireHouseState.geo_location[0]?.latitude}
              longitude={entireHouseState.geo_location[0]?.longitude}
              zoom={12}
              width={"100%"}
              height={"100%"}
              data={entireHouseState.geo_location}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewHouse;
