import { Grid, Typography } from "@material-ui/core";
import React from "react";
import styles from "../styles";
import AddLocationsToMap from "../../../common/mapBox/AddLocationsToMap";
interface HouseLocationProps {
  locations: any;
}

const HouseLocation = (props: HouseLocationProps) => {
  const classes = styles();

  return (
    <Grid item xs={12}>
      <Typography
        className={classes.typograph}
        component="h4"
        variant="subtitle1"
      >
        house <span>location</span>
      </Typography>
      <AddLocationsToMap
        latitude={37.6922361}
        longitude={-97.3375448}
        zoom={10}
        width={"100%"}
        height={"100%"}
        data={props.locations}
      />
    </Grid>
  );
};

export default HouseLocation;
