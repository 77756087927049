import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TodosApiService } from "../../../../api-kit/todos/todos-api.services";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import KEEWISTTable from "./components/ThingsToDoTable";

const ThingsToDoList = () => {
  const [todos, setTodos] = useState([]);
  const dispatch = useDispatch();
  const todosApiService = new TodosApiService();

  useEffect(() => {
    todosApiService
      .getTodos()
      .then((res) => {
        setTodos(res.data);
      })
      .catch((error) => {
        dispatch(
          addSnackBar({
            message: "Unable to get todos.",
            type: "error",
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "todo-label",
      numeric: false,
      disablePadding: true,
      label: "Label",
    },
    {
      id: "todo-disabled",
      numeric: true,
      disablePadding: false,
      label: "isDisabled",
    },
    {
      id: "todo-createdAt",
      numeric: true,
      disablePadding: false,
      label: "Created at",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const handleOnDelete = (id: string) => {
    todosApiService
      .deleteTodo(id)
      .then((res) => {
        const newTodos = todos.filter((todo) => todo._id !== res.data.id);
        setTodos(newTodos);
        dispatch(
          addSnackBar({
            message: "Todo successfully deleted!",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to delete this todo!",
            type: "error",
          })
        )
      );
  };

  return (
    <>
      <KEEWISTTable
        rows={todos}
        headCells={headCells}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};

export default ThingsToDoList;
