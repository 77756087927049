import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Email, Send } from "@material-ui/icons";
import { checkEmail } from "../../../ui-kit/common/validation/KeewistValidations";
import theme from "../../theme/theme";
import { UserApiService } from "../../../api-kit/user/user-api.service";
import { addSnackBar } from "../../../_redux/_actions/snackBar.actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
  },
  paper: {
    paddingTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: "none",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Forgot() {
  const myClasses = useStyles();
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const dispatch = useDispatch();
  const userApiService = new UserApiService();

  const handleEmailChange = (e: any) => {
    let value = e.target?.value;
    const validation = checkEmail(value);
    if (validation.isInvalid) {
      setIsEmailInvalid(validation.isInvalid);
      setEmailErrorMessage(validation.validationError);
      setEmail(value);
    } else {
      setIsEmailInvalid(false);
      setEmailErrorMessage("");
      setEmail(value);
    }
  };
  const submit = async (e: any) => {
    e.preventDefault();
    const body = {
      email: email,
    };
    userApiService
      .resetPassword(body)
      .then((res) => {
        dispatch(
          addSnackBar({
            message: "Password reset request sent!",
            type: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          addSnackBar({
            message: error?.data?.message,
            type: "error",
          })
        );
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={myClasses.paper}>
        <Avatar className={myClasses.avatar}>
          <Email />
        </Avatar>
        <form
          className={myClasses.form}
          autoComplete="off"
          noValidate
          onSubmit={submit}
        >
          <TextField
            variant="outlined"
            required
            fullWidth
            type="text"
            id="email-address"
            label="Email Address"
            autoFocus
            onBlur={(e: any) => handleEmailChange(e)}
            onChange={(e: any) => handleEmailChange(e)}
            error={isEmailInvalid}
            helperText={emailErrorMessage}
          />
          <Button
            endIcon={<Send />}
            type="submit"
            className={myClasses.submit}
            fullWidth
            variant="contained"
            style={{
              marginTop: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
            }}
            size={"large"}
          >
            Send
          </Button>
        </form>
      </div>
    </Container>
  );
}
export default Forgot;
