import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(5)}px 0`,
  },
  content: {
    maxWidth: 600,
    display: "block",
    margin: "0 auto 10px auto",
  },
  list: { textAlign: "justify" },
  todoHeader: { fontWeight: 600, marginTop: theme.spacing(2) },
}));
