import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import NewsHeader from "./components/NewsHeader";
import NewsContent from "./components/NewsContent";
import { useDispatch } from "react-redux";
import { NewsApiService } from "../../../api-kit/news/news-api.service";
import { addSnackBar } from "../../../_redux/_actions/snackBar.actions";

export default function News() {
  const dispatch = useDispatch();
  const [news, setNews] = useState([]);
  const [header, setHeader] = useState({});
  useEffect(() => {
    window.scroll(0, 0);
    const newsApiService = new NewsApiService();
    try {
      newsApiService
        .getNewsList()
        .then((res) => {
          newsApiService
            .getNewsHeader()
            .then((res) => setHeader((header) => ({ ...header, ...res.data })))
            .catch((err) => console.log(err));
          setNews(res.data);
        })
        .catch((error) => {
          dispatch(
            addSnackBar({
              message: error,
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(
        addSnackBar({
          message: e,
          type: "error",
        })
      );
    }
  }, []);

  return (
    <Container>
      <Grid container spacing={3} style={{ marginLeft: 0, width: "100%" }}>
        <NewsHeader header={header} />
        <NewsContent news={news} />
      </Grid>
    </Container>
  );
}
