import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  content: {
    maxWidth: 300,
    display: "block",
    margin: "0 auto 10px auto",
  },
  table: {
    minWidth: 650,
  },
  form: {
    maxWidth: 300,
    display: "block",
    margin: "0 auto 10px auto",
    paddingBottom: theme.spacing(5),
  },
  btn: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
    marginRight: theme.spacing(1.8),
  },
}));
