import {
  ADD_SNACKBAR,
  REMOVE_SNACKBAR,
} from "../_constants/snackBar.constants";

const snackbars = (state: any = {}, action) => {
  switch (action.type) {
    case ADD_SNACKBAR:
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload,
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };

    default:
      return state;
  }
};
export default snackbars;
