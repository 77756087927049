import { Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { Paths } from "../../../Router";
import styles from "../styles";
import ReactHtmlParser from "react-html-parser";

interface HeaderProps {
  data: any;
}

const Header = (props: HeaderProps) => {
  const classes = styles();
  const history = useHistory();
  return (
    <Container disableGutters className={classes.root}>
      <Grid item xs={12} md={10} lg={10} className={classes.text}>
        <span className={classes.smallHeader}>
          {props.data?.header !== undefined &&
          props.data?.header !== null &&
          Object.keys(props.data?.header).length !== 0
            ? props.data?.header?.small_header
            : "welcome to"}
        </span>
        <Typography
          component="h2"
          variant="h2"
          color="inherit"
          className={classes.header}
        >
          {props.data?.header !== undefined &&
          props.data?.header !== null &&
          Object.keys(props.data?.header).length !== 0
            ? props.data?.header?.heading
            : "Wichita"}
        </Typography>

        {props.data?.header !== undefined &&
        props.data?.header !== null &&
        Object.keys(props.data?.header).length !== 0 ? (
          ReactHtmlParser(props.data?.header?.paragraph)
        ) : (
          <Typography
            component="p"
            color="inherit"
            className={classes.paragraph}
            paragraph
          >
            "Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of
            type."
          </Typography>
        )}
        <Button
          className={classes.submit}
          onClick={() => history.push(Paths.rentals)}
          variant="contained"
          size="medium"
        >
          Explore Houses
        </Button>
      </Grid>
    </Container>
  );
};

export default Header;
