import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    form: {
      maxWidth: 600,
      display: "block",
      margin: "0 auto 10px auto",
      paddingBottom: theme.spacing(5),
    },
    btn: {
      marginTop: theme.spacing(1),
    },
    root: {
      paddingTop: theme.spacing(5),
    },
    chipsRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    chips: {
      marginTop: 10,
    },
    input: {
      display: "none",
      marginTop: theme.spacing(2),
    },
    editor: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  })
);
