import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { FormHelperText } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { TodosApiService } from "../../../../../../api-kit/todos/todos-api.services";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ThingsToDoStateContext } from "../../ThingsToDoContext";
import styles from "./styles";
import AddLabelContent from "./AddLabelContent";
import { nullCheck } from "../../../../../../ui-kit/common/validation/KeewistValidations";
import { validationMessages } from "../../helper/constants";
import { Paths } from "../../../../../Router";
import theme from "../../../../../theme/theme";

const AddNewThingsToDo = () => {
  const todosApiService = new TodosApiService();
  const { thingsToDoState, updateThingsToDoState } = useContext(
    ThingsToDoStateContext
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (thingsToDoState.label !== "") {
        checkIfLabelExist(
          thingsToDoState.label.charAt(0).toUpperCase() +
            thingsToDoState.label.slice(1)
        );
        updateThingsToDoState({
          newLabel: thingsToDoState.label,
        });
      } else {
        updateThingsToDoState({
          newLabel: "",
          title: "",
          description: "",
          isGeoLocationAdded: false,
        });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thingsToDoState.label]);

  function checkIfLabelExist(label: string) {
    todosApiService
      .getTodoByLabel(label)
      .then((res) => {
        if (res.data?.label !== "") {
          updateThingsToDoState({
            isLabelExist: true,
            label: label,
            isLabelInvalid: true,
            labelErrorMessage: validationMessages.labelExist,
          });
        } else {
          updateThingsToDoState({
            isLabelExist: false,
            label: label,
            isLabelInvalid: false,
            labelErrorMessage: "",
          });
        }
      })
      .catch((err) => {
        dispatch(
          addSnackBar({
            message: "Unable to validate label.",
            type: "error",
          })
        );
      });
  }

  const handleLabel = (label) => {
    const validation = nullCheck(label, validationMessages.addLabel);
    if (validation.isInvalid) {
      updateThingsToDoState({
        label: label,
        isLabelInvalid: validation.isInvalid,
        labelErrorMessage: validation.validationError,
      });
    } else {
      updateThingsToDoState({
        label: label,
        isLabelInvalid: false,
        labelErrorMessage: "",
      });
    }
  };

  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.thingsToDoList)}
        size="small"
      >
        Back
      </Button>
      <Paper component="main" elevation={1} className={classes.root}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Activities
        </Typography>
        <form noValidate autoComplete="off" className={classes.form}>
          <TextField
            variant="outlined"
            fullWidth
            required
            name="todo-label"
            label="Add label"
            type="text"
            id="todo-label"
            onBlur={(e) => handleLabel(e.target?.value)}
            onChange={(e) => handleLabel(e.target?.value)}
            disabled={thingsToDoState.addMoreLabelPlace}
            error={thingsToDoState.isLabelInvalid}
            helperText={thingsToDoState.labelErrorMessage}
          />
          <FormHelperText className={classes.helperText}>
            Label can be anything(ex: School, Restaurant, Cinema, Hospital)
          </FormHelperText>
          {thingsToDoState.newLabel !== "" && !thingsToDoState.isLabelExist && (
            <AddLabelContent />
          )}
        </form>
      </Paper>
    </>
  );
};

export default AddNewThingsToDo;
