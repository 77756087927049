import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../../../../Router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import styles from "./styles";
import { Announcement, Title } from "@material-ui/icons";
import { NewsApiService } from "../../../../../../api-kit/news/news-api.service";
import ReactHtmlParser from "react-html-parser";
import theme from "../../../../../theme/theme";
import KEEWISTImageSwipe from "../../../../../../ui-kit/components/KEEWIST-crousel";

const ViewNews = () => {
  const classes = styles();
  const history = useHistory();
  const location: any = useLocation();
  const newsApiService = new NewsApiService();
  const [news, setNews] = useState({
    title: "",
    content: "",
    news_images: []
  });

  useEffect(() => {
    if (location.state !== null && location.state.newsId !== null) {
      newsApiService
        .getNews(location.state.newsId)
        .then((res) => setNews(res.data))
        .catch((err) => console.error(err));
    }
    return () => {
      setNews({
        title: "",
        content: "",
        news_images: [
          {
            filename: "",
            filepath: "",
          },
        ]
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.newsList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <div className={classes.content}>
          <List>
            <ListItem>
              <ListItemIcon>
                <Title />
              </ListItemIcon>
              <ListItemText primary={news.title} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Announcement />
              </ListItemIcon>
              <ListItemText primary={ReactHtmlParser(news.content)} />
            </ListItem>
            <ListItem>
            <ListItemIcon>
              </ListItemIcon>
              <KEEWISTImageSwipe images={news.news_images}/>
            </ListItem>
          </List>
        </div>
      </Paper>
    </>
  );
};

export default ViewNews;
