import { nullCheck } from "../../../../../ui-kit/common/validation/KeewistValidations";

export function updatePropertyStatesFunc(
  context,
  value,
  message,
  invalid,
  validationMessage,
  state
) {
  const validation = nullCheck(value, message);
  updateState(validation, context, value, invalid, validationMessage, state);
}
function updateState(
  validation,
  context,
  value,
  invalid,
  validationMessage,
  state
) {
  if (validation.isInvalid) {
    context({
      [state]: value,
      [invalid]: validation.isInvalid,
      [validationMessage]: validation.validationError,
    });
  } else {
    context({
      [state]: value,
      [invalid]: false,
      [validationMessage]: "",
    });
  }
}
