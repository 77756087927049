import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../../../../Router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import styles from "./styles";
import LabelIcon from "@mui/icons-material/Label";
import { TodosApiService } from "../../../../../../api-kit/todos/todos-api.services";
import theme from "../../../../../theme/theme";
import { Box, Divider, Typography } from "@mui/material";
import KEEWISTImageSwipe from "../../../../../../ui-kit/components/KEEWIST-crousel";
import ReactHtmlParser from "react-html-parser";

const ViewTodo = () => {
  const classes = styles();
  const history = useHistory();
  const location: any = useLocation();
  const todosApiService = new TodosApiService();
  const [todo, setTodo] = useState({
    label: "",
    content: [
      {
        title: "",
        labelType: "",
        description: "",
        geo_location: {
          latitude: 0,
          longitude: 0,
          zoom: 0,
        },
        places: [
          {
            filename: "",
            filepath: "",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    if (location.state !== null && location.state.todoId !== null) {
      todosApiService
        .getTodo(location.state.todoId)
        .then((res) => setTodo(res.data))
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.thingsToDoList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <div className={classes.content}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            gutterBottom
            color="textSecondary"
            style={{ textTransform: "uppercase", fontSize: "1.5em" }}
          >
            <LabelIcon style={{ paddingTop: theme.spacing(0.6) }} />{" "}
            {todo.label}
          </Typography>
          <List>
            {todo.content.length !== 0 &&
              todo.content.map((d) => (
                <>
                  <ListItem>
                    <ListItemText primary={`Place: ${d.title}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={`Label type: ${d.labelType}`} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary={ReactHtmlParser(d.description)} />
                  </ListItem>
                  <ListItem>
                    <Typography variant={"body1"}>Latitude: </Typography>
                    <Typography variant="body2" color={"textSecondary"}>
                      {d.geo_location.latitude}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant={"body1"}>Longitude: </Typography>
                    <Typography variant="body2" color={"textSecondary"}>
                      {d.geo_location.longitude}
                    </Typography>
                  </ListItem>
                  <Box ml={2}>
                    <KEEWISTImageSwipe images={d.places}/>
                  </Box>

                  <Divider
                    style={{
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(2),
                      borderColor: "#EBEBEB",
                    }}
                  />
                </>
              ))}
          </List>
        </div>
      </Paper>
    </>
  );
};

export default ViewTodo;
