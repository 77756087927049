import {
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  Box,
  ButtonGroup,
} from "@mui/material";
import { useContext, useEffect } from "react";
import styles from "./styles";
import KEEWISTDialog from "../../../../../ui-kit/components/KEEWIST-dailog";
import theme from "../../../../theme/theme";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import KEEWISTDatepicker from "../../../../../ui-kit/components/KEEWIST-datepicker";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Paths } from "../../../../Router";
import { EntireHouseStateContext } from "../../EntireHouseContext";
import { green } from "@mui/material/colors";
import store from "../../../../../_redux/_helpers/store";
import { useHistory } from "react-router-dom";
import UserLogin from "../../../auth/login/login";

const RightSideContent = () => {
  const classes = styles();
  const { entireHouseState, updateEntireHouseState } = useContext(
    EntireHouseStateContext
  );
  const history = useHistory();

  const toggleExpand = () => {
    updateEntireHouseState({ expand: !entireHouseState.expand });
  };

  const showCalendar = () => {
    updateEntireHouseState({ openCalendar: !entireHouseState.openCalendar });
  };
  const closeCalendar = () => {
    updateEntireHouseState({ openCalendar: false });
  };

  const openModalWindow = () => {
    updateEntireHouseState({ open: true });
  };

  const closeModalWindow = () => {
    updateEntireHouseState({ open: false });
  };
  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    let discountedPrice = 0;

    if (
      entireHouseState.isBookingSelected &&
      entireHouseState.differenceInDays >= 7 &&
      entireHouseState.differenceInDays < 28
    ) {
      discountedPrice =
        entireHouseState.totalNightPrice - entireHouseState.weeklyDiscount;
    } else if (
      entireHouseState.isBookingSelected &&
      entireHouseState.differenceInDays >= 28
    ) {
      discountedPrice =
        entireHouseState.totalNightPrice - entireHouseState.monthlyDiscount;
    } else {
      discountedPrice = entireHouseState.totalNightPrice;
    }

    updateEntireHouseState({
      totalPrice:
        entireHouseState.totalNightPrice !== 0
          ? formatter.format(
              discountedPrice +
                entireHouseState.cleaningFee +
                entireHouseState.serviceFee
            )
          : formatter.format(0),
    });
  }, [entireHouseState.totalNightPrice]);

  useEffect(() => {
    if (entireHouseState.differenceInDays === 0) {
      updateEntireHouseState({
        isBookingSelected: false,
      });
    } else {
      updateEntireHouseState({
        isBookingSelected: true,
      });
    }
  }, [entireHouseState.differenceInDays]);

  const handleOnChangeDate = (value: any) => {
    let dateRange = value[0];

    // To calculate the time difference of two dates
    var Difference_In_Time =
      dateRange.endDate.getTime() - dateRange.startDate.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.floor(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    if (Difference_In_Days > 7 && Difference_In_Days < 30) {
      updateEntireHouseState({
        // weekly:
      });
    }
    updateEntireHouseState({
      totalNightPrice:
        entireHouseState.property_price_per_night * Difference_In_Days,
      differenceInDays: Difference_In_Days,
      booking: [dateRange],
    });
  };

  const handleReserve = () => {
    const userInfo = store.getState().user;
    if (userInfo.loggedIn || entireHouseState.continue_as_guest) {
      history.push({
        pathname: Paths.requestHouse,
        state: {
          houseDetails: entireHouseState,
          cancellationPolicy: entireHouseState.cancellationPolicy,
        },
      });
    } else {
      updateEntireHouseState({ isOpenLoginForm: true });
    }
  };

  const handleLoginForm = () => {
    updateEntireHouseState({ isOpenLoginForm: false });
  };

  const handleContinueAsGuest = () => {
    updateEntireHouseState({
      isOpenLoginForm: false,
      continue_as_guest: true,
    });
  };

  const handleLoginSubmit = (e: any) => {
    if (e) {
      updateEntireHouseState({ isOpenLoginForm: false, isUserLoggedIn: true });
    }
  };

  const userLogin = (
    <Box
      sx={{
        "& .makeStyles-paper-64": {
          paddingTop: theme.spacing(1),
        },
      }}
    >
      <UserLogin onSubmitClicked={(e) => handleLoginSubmit(e)} />
      <Button
        variant="contained"
        fullWidth
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.info.main,
        }}
        onClick={handleContinueAsGuest}
      >
        Continue as guest
      </Button>
    </Box>
  );

  const handleAddAdult = () => {
    if (
      entireHouseState.adults + entireHouseState.children <
      entireHouseState.property_guest_limit
    ) {
      updateEntireHouseState({ adults: entireHouseState.adults + 1 });
    }
  };

  const handleRemoveAdult = () => {
    if (entireHouseState.adults > 0) {
      updateEntireHouseState({ adults: entireHouseState.adults - 1 });
    }
  };

  const handleAddChildren = () => {
    if (
      entireHouseState.adults + entireHouseState.children <
      entireHouseState.property_guest_limit
    ) {
      updateEntireHouseState({ children: entireHouseState.children + 1 });
    }
  };

  const handleRemoveChildren = () => {
    if (entireHouseState.children > 0) {
      updateEntireHouseState({ children: entireHouseState.children - 1 });
    }
  };

  const handleAddInfant = () => {
    if (entireHouseState.infants < 5) {
      updateEntireHouseState({ infants: entireHouseState.infants + 1 });
    }
  };

  const handleRemoveInfant = () => {
    if (entireHouseState.infants > 0) {
      updateEntireHouseState({ infants: entireHouseState.infants - 1 });
    }
  };

  const expendedMobile = (
    <div className={classes.stickyFooter}>
      <Button
        variant="contained"
        fullWidth
        style={{
          marginTop: theme.spacing(1),
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        onClick={openModalWindow}
      >
        Reserve
      </Button>
    </div>
  );

  const expended = (
    <>
      <div className={classes.guestLimit}>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Adults
          </Typography>
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleAddAdult}>
              <AddIcon fontSize="small" color="primary" />
            </Button>
            <Button>{entireHouseState.adults}</Button>
            <Button onClick={handleRemoveAdult}>
              <RemoveIcon fontSize="small" color="primary" />
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Children
          </Typography>
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleAddChildren}>
              <AddIcon fontSize="small" color="primary" />
            </Button>
            <Button>{entireHouseState.children}</Button>
            <Button onClick={handleRemoveChildren}>
              <RemoveIcon fontSize="small" color="primary" />
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Infants
          </Typography>
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleAddInfant}>
              <AddIcon fontSize="small" color="primary" />
            </Button>
            <Button>{entireHouseState.infants}</Button>
            <Button onClick={handleRemoveInfant}>
              <RemoveIcon fontSize="small" color="primary" />
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Pets
          </Typography>
          <Typography
            component="p"
            variant="body2"
            style={{
              color: theme.palette.info.light,
              fontSize: 12,
              paddingTop: theme.spacing(0.8),
            }}
          >
            Pets aren't allowed in this house.
          </Typography>
        </div>
      </div>
    </>
  );

  const bookingFormContent = (
    <form className={classes.form} noValidate autoComplete="off">
      <div className={classes.homePrice}>
        <AttachMoneyIcon fontSize="small" />
        {entireHouseState.property_price_per_night}{" "}
        <span className={classes.night}> / night</span>
      </div>
      <div className={classes.datePicker} onClick={showCalendar}>
        <div className="checkIn">
          <Typography
            component="h5"
            variant="h5"
            style={{ fontSize: 11, color: theme.palette.primary.main }}
          >
            Check-In
          </Typography>
          <Typography
            component="p"
            variant="body2"
            style={{
              color: theme.palette.info.light,
              textTransform: "capitalize",
            }}
          >
            Add dates
          </Typography>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className="checkout">
          <Typography
            component="h5"
            variant="h5"
            style={{ fontSize: 11, color: theme.palette.primary.main }}
          >
            Checkout
          </Typography>
          <Typography
            component="p"
            variant="body2"
            style={{
              color: theme.palette.info.light,
              textTransform: "capitalize",
            }}
          >
            Add dates
          </Typography>
        </div>
      </div>
      <div className={classes.datePicker} onClick={toggleExpand}>
        <div className="guests">
          <Typography
            component="h5"
            variant="h5"
            style={{ fontSize: 11, color: theme.palette.primary.main }}
          >
            Guests
          </Typography>
          <Typography
            component="h5"
            variant="subtitle1"
            style={{ fontSize: 10, color: theme.palette.grey[700] }}
          >
            {entireHouseState.adults + entireHouseState.children === 1 ? (
              <span>
                {entireHouseState.adults + entireHouseState.children} guest
              </span>
            ) : (
              <span>
                {entireHouseState.adults + entireHouseState.children} guests
              </span>
            )}
            {entireHouseState.infants > 0 && (
              <span>
                ,{" "}
                {entireHouseState.infants === 1 ? (
                  <>{entireHouseState.infants} infant</>
                ) : (
                  <>{entireHouseState.infants} infants</>
                )}
              </span>
            )}
          </Typography>
        </div>
        <div className="expand">
          <IconButton>
            {entireHouseState.expand ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </div>
      {entireHouseState.expand && expended}

      {!entireHouseState.isBookingSelected ? (
        <Button
          variant="contained"
          fullWidth
          style={{
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          }}
          onClick={showCalendar}
        >
          Check availability
        </Button>
      ) : (
        <Button
          variant="contained"
          fullWidth
          style={{
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          }}
          onClick={handleReserve}
        >
          Reserve
        </Button>
      )}
    </form>
  );

  const datePickerContent = (
    <KEEWISTDatepicker
      onChange={handleOnChangeDate}
      booking={entireHouseState.booking}
      booked={entireHouseState.booked}
      position={"center"}
    />
  );

  return (
    <>
      <Paper className={classes.paper} variant="outlined">
        {bookingFormContent}
        {entireHouseState.differenceInDays > 0 && (
          <Box
            sx={{
              paddingTop: theme.spacing(0.5),
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                ${entireHouseState.property_price_per_night} x{" "}
                {entireHouseState.differenceInDays} nights
              </Typography>
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                ${entireHouseState.totalNightPrice}
              </Typography>
            </Box>
            {entireHouseState.differenceInDays >= 7 &&
              entireHouseState.differenceInDays < 28 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: theme.spacing(0.1),
                  }}
                >
                  <Typography
                    component="h5"
                    variant="body1"
                    style={{ color: green[800] }}
                  >
                    Weekly discount
                  </Typography>
                  <Typography
                    component="h5"
                    variant="body1"
                    style={{ color: green[800] }}
                  >
                    {entireHouseState.cancellationPolicy.map((data: any) => {
                      return data.type === "weekly" && -data.discounted_price;
                    })}
                  </Typography>
                </Box>
              )}
            {entireHouseState.differenceInDays >= 28 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: theme.spacing(0.1),
                }}
              >
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ color: green[800] }}
                >
                  Monthly discount
                </Typography>
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ color: green[800] }}
                >
                  {entireHouseState.cancellationPolicy.map((data: any) => {
                    return data.type === "monthly" && -data.discounted_price;
                  })}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: theme.spacing(0.1),
              }}
            >
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                Cleaning fee
              </Typography>
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                ${entireHouseState.cleaningFee}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: theme.spacing(0.1),
              }}
            >
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                Service fee
              </Typography>
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                ${entireHouseState.serviceFee}
              </Typography>
            </Box>
            <Divider
              light
              style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                component="h6"
                variant="h6"
                style={{ color: theme.palette.primary.main }}
              >
                Total before taxes
              </Typography>
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.primary.main }}
              >
                {entireHouseState.totalPrice}
              </Typography>
            </Box>
          </Box>
        )}
      </Paper>
      {expendedMobile}
      <KEEWISTDialog
        isOpen={entireHouseState.open}
        handleClose={closeModalWindow}
        title={"Form"}
        content={bookingFormContent}
        actionButton={false}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={false}
      />
      <KEEWISTDialog
        isOpen={entireHouseState.openCalendar}
        handleClose={closeCalendar}
        title={"Add dates"}
        content={datePickerContent}
        actionButton={true}
        maxWidth="xs"
        fullWidth={true}
        fullScreen={false}
        btnSize={"small"}
        btnLabelCancel="Close"
        btnLabelHide={true}
      />
      <KEEWISTDialog
        isOpen={entireHouseState.isOpenLoginForm}
        handleClose={handleLoginForm}
        content={userLogin}
        actionButton={false}
        maxWidth="xs"
        fullWidth={true}
        fullScreen={false}
        btnSize={"small"}
      />
    </>
  );
};

export default RightSideContent;
