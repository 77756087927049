export const validationMessages = {
  firstname: "Firstname is required.",
  lastname: "Lastname is required.",
  email: "Email address is required.",
  phonenumber: "Phone number is required.",
  bookingSuccess: "Your booking request is sent.",
  bookingFailed:
    "Unable to add booking. All required fields should be filled including a guests.",
  adultsField: "At least 1 adult should be selected.",
};
