import React, { createContext, useState } from "react";
import {
  EntireHouseState,
  entireHouseStateDefault,
} from "./components/form/EntireHouseState";

interface EntireHouseContext {
  entireHouseState: EntireHouseState;
  updateEntireHouseState: React.Dispatch<React.SetStateAction<any>>;
}

export const EntireHouseStateContext = createContext({} as EntireHouseContext);

export const EntireHouseProvider = ({ children }) => {
  const [entireHouseState, setEntireHouseState] = useState<EntireHouseState>(
    entireHouseStateDefault
  );

  function updateEntireHouseState(newState: any) {
    setEntireHouseState((prevState: any) => {
      return { ...prevState, ...newState };
    });
  }

  const value = {
    entireHouseState,
    updateEntireHouseState,
  };

  return (
    <EntireHouseStateContext.Provider value={value}>
      {children}
    </EntireHouseStateContext.Provider>
  );
};
