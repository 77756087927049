import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import pinPoint from "../../assets/images/1.png";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

export interface KEEWISTMapProps {
  viewport?: any;
  onViewportChange?: any;
  data?: any[];
  fullScreen?: boolean;
  wrapperHeight?: string;
  maxZoom?: number;
}

const styles = makeStyles((theme: Theme) =>
  createStyles({
    marker: {
      border: "none",
    },
  })
);

export interface KEEWISTMapState {}
export const KEEWISTMap = (props: KEEWISTMapProps): React.ReactElement => {
  const classes = styles();

  const mapRef = React.useRef();
  const handleViewportChange = React.useCallback(
    (newViewport) => props.onViewportChange(newViewport),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className={"mapBoxInner"}
      style={{
        height:
          props.fullScreen !== undefined && props.fullScreen
            ? props.wrapperHeight !== undefined
              ? props.wrapperHeight
              : "100vh"
            : "50vh",
        width: "100%",
      }}
    >
      <ReactMapGL
        ref={mapRef}
        {...props.viewport}
        width={props.viewport?.width?.toString()}
        height={props.viewport?.height?.toString()}
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        maxZoom={props.maxZoom !== undefined ? props.maxZoom : 13}
      >
        {Array.isArray(props?.data) &&
          props?.data?.map((location: any, index) => (
            <Marker
              key={index}
              latitude={location?.latitude}
              longitude={location?.longitude}
            >
              <button className={classes.marker} style={{ borderRadius: 25 }}>
                <img
                  src={pinPoint}
                  style={{ width: 40, border: 0 }}
                  alt="KS rental properties in Wichita, KS"
                />
              </button>
            </Marker>
          ))}
      </ReactMapGL>
    </div>
  );
};
export default KEEWISTMap;
