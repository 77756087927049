import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Paths } from "../../../../../Router";
import styles from "./styles";
import { connect, useSelector } from "react-redux";
import KEEWISTTableToolbar from "../../../../../../ui-kit/components/table/KEEWIST-TableToolbar";
import { Edit } from "@material-ui/icons";
import theme from "../../../../../theme/theme";

interface AccountProps {
  user?: any;
}

const Account = (props: AccountProps) => {
  const classes = styles();
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createdAtUser = new Date(props.user.user.createdAt);
  const state = useSelector((state: any) => state.user.user);

  return (
    <>
      <TableContainer component={Paper}>
        <KEEWISTTableToolbar
          tableName="Account"
          btnValue={false}
          onClick={(e) => history.push(Paths.accountEdit)}
        />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell align="right">Email address</TableCell>
              <TableCell align="right">User role</TableCell>
              <TableCell align="right">Created at</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={props.user.user.username}>
              <TableCell component="th" scope="row">
                {props.user.user.username}
              </TableCell>
              <TableCell align="right">{props.user.user.email}</TableCell>
              <TableCell align="right">{props.user.user.role}</TableCell>
              <TableCell align="right">
                {createdAtUser.toDateString()}
              </TableCell>
              <TableCell align="right">
                <Button
                  component={Link}
                  to={{
                    pathname: Paths.accountEdit,
                    state: {
                      userId: state.id,
                    },
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.common.white,
                    justifyContent: "initial",
                    minWidth: 0,
                  }}
                >
                  <Edit />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapDispatchToProps = (props) => {
  return {
    user: props.user,
  };
};
export default connect(mapDispatchToProps, {})(Account);
