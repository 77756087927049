import React, { useEffect } from "react";
import styles from "./styles";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../../../theme/theme";
import { useHistory } from "react-router-dom";
import { Paths } from "../../../../Router";
import { useDispatch } from "react-redux";
import { PolicyApiService } from "../../../../../api-kit/policy/policy-api.services";
import { isFormSubmitted } from "../../../../../service/validation.service";
import { addSnackBar } from "../../../../../_redux/_actions/snackBar.actions";
import { PolicyStateContext } from "../PolicyContext";
import KEEWISTEditor from "../../../../../ui-kit/components/KEEWIST-editor";
import { NumberFormatCustom } from "../../../../../ui-kit/common/NumberFormat";

const AddPolicy = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();
  const policyApiService = new PolicyApiService();
  const { policyState, updatePolicyState } =
    React.useContext(PolicyStateContext);

  useEffect(() => {
    let isCurrent = true;
    isFormSubmitted.subscribe((submit) => {
      if (submit && isCurrent) {
        isFormSubmitted.next(false);
      }
    });
    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyState.type, policyState.title, policyState.content]);

  const handleSubmit = (e: any) => {
    isFormSubmitted.next(true);
    e.preventDefault();
    e.target.reset();

    const body = {
      type: policyState.type,
      title: policyState.title,
      content: policyState.content,
      discounted_price: policyState.discountedPrice,
      cleaning_fee: policyState.cleaningFee,
      service_fee: policyState.serviceFee,
    };

    if (
      policyState.type !== "" &&
      policyState.title !== "" &&
      policyState.content !== "" &&
      policyState.discountedPrice !== null
    ) {
      policyApiService
        .createPolicy(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "New policy added.",
              type: "success",
            })
          );
          updatePolicyState({ type: "" });
          updatePolicyState({ title: "" });
          updatePolicyState({ content: "" });
          updatePolicyState({ discountedPrice: 0 });
        })
        .catch((error) =>
          dispatch(
            addSnackBar({
              message: "Unable to add a new policy",
              type: "error",
            })
          )
        );
    } else {
      dispatch(
        addSnackBar({
          message: "All fields are required!",
          type: "error",
        })
      );
    }
  };
  return (
    <>
      <Paper component="main" elevation={0} className={classes.root}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          add policy
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(e) => handleSubmit(e)}
        >
          <FormControl variant="outlined" size="medium" margin="normal">
            <InputLabel id="keewist-select-type-label">
              Discount type
            </InputLabel>
            <Select
              labelId="keewist-select-type-label-discounted-type"
              id="keewist-select-type-discounted-type"
              value={
                policyState.type
                  ? policyState.type
                  : updatePolicyState({ type: "common" })
              }
              onChange={(e: any) =>
                updatePolicyState({ type: e.target?.value })
              }
              label="Discounted type"
            >
              <MenuItem value={"common"}>common</MenuItem>
              <MenuItem value={"weekly"}>weekly</MenuItem>
              <MenuItem value={"monthly"}>monthly</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            required
            name="policy-title"
            label="Title"
            type="text"
            id="policy-title-id"
            value={policyState.title !== undefined ? policyState.title : ""}
            onChange={(e) => updatePolicyState({ title: e.target?.value })}
          />
          <div className={classes.editor}>
            <FormHelperText>Add policy description here.</FormHelperText>
            <KEEWISTEditor
              data={policyState.content}
              onChange={(e: any) => updatePolicyState({ content: e })}
              onBlur={(e: any) => updatePolicyState({ content: e })}
            />
          </div>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            name="discounted-price"
            label="Discounted price"
            type="text"
            id="discounted-price-id"
            value={
              policyState.discountedPrice !== null
                ? policyState.discountedPrice
                : 0
            }
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            onChange={(e) =>
              updatePolicyState({ discountedPrice: Number(e.target?.value) })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="cleaning-fee"
            label="Cleaning fee"
            type="text"
            id="cleaning-fee-id"
            value={
              policyState.cleaningFee !== null ? policyState.cleaningFee : 0
            }
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            onChange={(e) =>
              updatePolicyState({ cleaningFee: Number(e.target?.value) })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="service-fee"
            label="Service fee"
            type="text"
            id="service-fee-id"
            value={policyState.serviceFee !== null ? policyState.serviceFee : 0}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            onChange={(e) =>
              updatePolicyState({ serviceFee: Number(e.target?.value) })
            }
          />

          <Button
            onClick={() => history.push(Paths.policies)}
            className={classes.btn}
            style={{
              marginRight: theme.spacing(1),
            }}
            variant="contained"
            size={"small"}
          >
            Back
          </Button>
          <Button
            type="submit"
            className={classes.btn}
            variant="contained"
            size={"small"}
            style={{
              color: theme.palette.common.white,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </>
  );
};

export default AddPolicy;
