import { Box, Divider, Grid, Skeleton } from "@mui/material";
import React from "react";
import KEEWISTTabs from "../../../../ui-kit/components/KEEWIST-tabs";
import TodoMap from "./TodoMap";
import styles from "../styles";
import theme from "../../../theme/theme";

interface TodoTabsProps {
  handleChange?: any;
  tabs?: any[];
  locations?: any;
}

const TodoTabs = (props: TodoTabsProps) => {
  const classes = styles();
  return (
    <Grid container spacing={2} mt={15}>
      <Grid item xs={12} sm={6}>
        {props.tabs.length !== 0 && props.tabs[0].value !== "" ? (
          <KEEWISTTabs handleChange={props.handleChange} tabs={props.tabs} />
        ) : (
          <Box mt={10.5}>
            <Grid item xs={12} sm={8}>
              <Skeleton width="60%" />
              <Skeleton variant="rectangular" width={500} height={310} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
              <Divider
                light
                style={{
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Skeleton width="60%" />
              <Skeleton variant="rectangular" width={500} height={310} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
              <Divider
                light
                style={{
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
                }}
              />
            </Grid>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} sm={6} className={classes.map}>
        <TodoMap locations={props.locations} />
      </Grid>
    </Grid>
  );
};

export default TodoTabs;
