import { PropertyApiService } from "../../api-kit/property/property-api.service";
import { FETCH_HOUSES_SUCCESS } from "../_constants/house.constants";
import { addSnackBar } from "./snackBar.actions";

const fetchHousesSuccess = (houses: any = []) => ({
  type: FETCH_HOUSES_SUCCESS,
  payload: { houses },
});

export const fetchHouses = () => {
  const propertyApiService = new PropertyApiService();
  return async (dispatch) => {
    try {
      await propertyApiService
        .getProperties()
        .then((res) => {
          dispatch(fetchHousesSuccess(res.data));
        })
        .catch((error) => {
          console.log("Test ",error.data)
          dispatch(
            addSnackBar({
              message: error?.data?.statusText,
              type: "error",
            })
          );
        });
    } catch (e) {
      dispatch(
        addSnackBar({
          message: e,
          type: "error",
        })
      );
    }
  };
};
