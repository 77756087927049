import { FETCH_HOUSES_SUCCESS } from "../_constants/house.constants";

const houses = (state: any = [], action) => {
  switch (action.type) {
    case FETCH_HOUSES_SUCCESS:
      return action.payload.houses;
    default:
      return state;
  }
};

export default houses;
