import { Router } from "./Router";
import "@fontsource/roboto";
import { ThemeProvider } from "@mui/styles";
import theme from "./theme/theme";
import SuccessSnackBar from "../_redux/_components/SuccessSnackBar";
import { CssBaseline } from "@mui/material";
import React from "react";
import { StylesProvider,createGenerateClassName } from "@material-ui/core/styles";

export default function App() {

  const generateClassName = createGenerateClassName({
    seed: 'keewist-rental',
  });
  return (
    <React.StrictMode>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SuccessSnackBar />
          <Router />
        </ThemeProvider>
      </StylesProvider>
    </React.StrictMode>
  );
}
