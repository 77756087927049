import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PropertyApiService } from "../../../../api-kit/property/property-api.service";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import KEEWISTTable from "./components/PropertyTable";

const PropertyList = () => {
  const [properties, setProperties] = useState([
    {
      _id: "",
    },
  ]);
  const propertyApiService = new PropertyApiService();
  const dispatch = useDispatch();

  useEffect(() => {
    propertyApiService
      .getProperties()
      .then((properties) => {
        setProperties(properties.data);
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "property-name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "property-type",
      numeric: true,
      disablePadding: false,
      label: "Type",
    },
    {
      id: "property-status",
      numeric: true,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "property-location",
      numeric: true,
      disablePadding: false,
      label: "Location",
    },
    {
      id: "property-price",
      numeric: true,
      disablePadding: false,
      label: "Price per night",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const handleOnDelete = (id: string) => {
    propertyApiService
      .deleteProperty(id)
      .then((res) => {
        const houses = properties.filter((house) => house._id !== res.data.id);

        setProperties(houses);
        dispatch(
          addSnackBar({
            message: "House deleted successfully.",
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          addSnackBar({
            message: "Unable to delete this house!",
            type: "error",
          })
        );
      });
  };

  return (
    <KEEWISTTable
      rows={properties}
      headCells={headCells}
      handleOnDelete={handleOnDelete}
    />
  );
};

export default PropertyList;
