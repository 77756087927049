import { Button, Paper, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NewsApiService } from "../../../../../../api-kit/news/news-api.service";
import { isFormSubmitted } from "../../../../../../service/validation.service";
import KEEWISTEditor from "../../../../../../ui-kit/components/KEEWIST-editor";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import { Paths } from "../../../../../Router";
import theme from "../../../../../theme/theme";
import styles from "./styles";
import Box from "@mui/material/Box";
import KEEWISTFileUpload from "../../../../../../ui-kit/components/KEEWIST-fileUpload";
import { NewsStateContext } from "../../NewsContext";
import { UploadApiService } from "../../../../../../api-kit/upload/upload-api.services";

const AddNewNews = () => {
  const uploadApiService = new UploadApiService();
  const newsApiService = new NewsApiService();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();
  const { addNewsState, updateAddNewsState } = useContext(NewsStateContext);

  useEffect(() => {
    newsApiService
      .getNewsHeader()
      .then((res) => {
        let header = res.data;
        if (header !== undefined) {
          updateAddNewsState({
            header: header.header,
            inspiringQuote: header.inspiring_quote,
            quotee: header.quotee,
            isHeaderValid: true,
          });
        }
      })
      .catch((err) => console.log("No header found!", err));
  }, []);

  useEffect(() => {
    let isCurrent = true;
    isFormSubmitted.subscribe((submit) => {
      if (submit && isCurrent) {
        isFormSubmitted.next(false);
      }
    });
    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewsState.title, addNewsState.content]);

  useEffect(() => {
    if (
      addNewsState.header !== "" &&
      addNewsState.inspiringQuote !== "" &&
      addNewsState.quotee !== ""
    ) {
      updateAddNewsState({ isHeaderValid: true });
    }
  }, [addNewsState.header, addNewsState.inspiringQuote, addNewsState.quotee]);

  const handleSubmit = (e: any) => {
    isFormSubmitted.next(true);
    e.preventDefault();
    e.target.reset();
    const ids = addNewsState.newsImages.map(function (id) {
      return id.ids;
    });
    const body = {
      header: {
        header: addNewsState.header,
        inspiring_quote: addNewsState.inspiringQuote,
        quotee: addNewsState.quotee,
      },
      content: {
        title: addNewsState.title,
        content: addNewsState.content,
        news_images: ids,
      },
    };
    if (
      addNewsState.title !== "" &&
      addNewsState.content !== "" &&
      addNewsState.isHeaderValid
    ) {
      newsApiService
        .createNews(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "News page successfully updated",
              type: "success",
            })
          );
          updateAddNewsState({ title: "" });
          updateAddNewsState({ content: "" });
        })
        .catch((error) =>
          dispatch(
            addSnackBar({
              message: "Unable to add News page",
              type: "error",
            })
          )
        );
    } else {
      dispatch(
        addSnackBar({
          message: "All fields are required!",
          type: "error",
        })
      );
    }
  };
  const handleImageUpload = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i]);
    }
    formData.append("length", files.length);
    updateAddNewsState({ disableBtn: true });
    uploadApiService
      .upload(formData)
      .then((res) => {
        const joined = addNewsState.newsImages.concat(res.data);
        updateAddNewsState({ newsImages: joined, disableBtn: false });
        dispatch(
          addSnackBar({
            message: "Image uploaded.",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to upload image.",
            type: "error",
          })
        )
      );

    const joined = addNewsState.newsImages.concat(formData);
    updateAddNewsState({ newsImages: joined });
  };

  return (
    <>
      <Paper component="main" elevation={0} className={classes.root}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          add News
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(e) => handleSubmit(e)}
        >
          <Typography variant="h6" color="inherit">
            Lets start with adding your favorite qoute.
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            required
            margin="normal"
            name="news-title"
            label="Header"
            type="text"
            id="news-title-id"
            value={addNewsState.header !== undefined ? addNewsState.header : ""}
            onChange={(e) => updateAddNewsState({ header: e.target?.value })}
          />
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            required
            name="inspiring-quote"
            label="Inspiring Quote"
            type="text"
            id="inspiring-quote-id"
            value={
              addNewsState.inspiringQuote !== undefined
                ? addNewsState.inspiringQuote
                : ""
            }
            onChange={(e) =>
              updateAddNewsState({ inspiringQuote: e.target?.value })
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            name="quotee"
            label="Quotee"
            type="text"
            id="quotee-id"
            value={addNewsState.quotee !== undefined ? addNewsState.quotee : ""}
            onChange={(e) => updateAddNewsState({ quotee: e.target?.value })}
          />
          {addNewsState.isHeaderValid && (
            <>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                required
                name="news-title"
                label="Title"
                type="text"
                id="header-title-id"
                onChange={(e) => updateAddNewsState({ title: e.target?.value })}
              />
              <div className={classes.editor}>
                <KEEWISTEditor
                  data={addNewsState.content}
                  onChange={(e: any) => updateAddNewsState({ content: e })}
                  onBlur={(e: any) => updateAddNewsState({ content: e })}
                />
              </div>
              <Box sx={{ mt: theme.spacing(0.1) }}>
                <KEEWISTFileUpload handleImageUpload={handleImageUpload} />
              </Box>
            </>
          )}
          <Button
            onClick={() => history.push(Paths.newsList)}
            className={classes.btn}
            style={{
              marginRight: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
            variant="contained"
            size={"small"}
          >
            Back
          </Button>
          <Button
            type="submit"
            className={classes.btn}
            variant="contained"
            size={"small"}
            style={{
              color: theme.palette.common.white,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            Submit
          </Button>
        </form>
      </Paper>
    </>
  );
};

export default AddNewNews;
