import { WrapperService } from "../wrapper.service";
import { authHeader } from "../../_redux/_helpers/auth-header";

export class TodosApiService {
  public apiService: WrapperService = new WrapperService();
  private requestTimeout = 0; //no timeout;
  private responseEncoding = "utf8";
  private baseURL = process.env.REACT_APP_API_PORT;

  public getTodos() {
    const apiParam = {
      name: "todo",
      method: "GET",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: "",
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public getTodo(todoId: string) {
    const apiParam = {
      name: "todo",
      method: "GET",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: `/${todoId}`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public getTodoByLabel(label: string) {
    const apiParam = {
      name: "todo",
      method: "GET",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: `/getTodoByLabel/${label}`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public createTodo(data: any) {
    const apiParam = {
      name: "todo",
      method: "POST",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
      body: data,
      suffixURL: "/create",
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public async deleteTodo(id) {
    const apiParam = {
      name: "todo",
      method: "DELETE",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: `/${id}`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public editTodo(body) {
    const apiParam = {
      name: "todo",
      method: "PATCH",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: body,
      suffixURL: "/update",
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }
}
