import React from "react";
import KEEWISTMap from "../../../ui-kit/components/KEEWIST-map";

export interface AddLocationsToMapProps {
  latitude: number;
  longitude: number;
  zoom: number;
  width: string;
  height: string;
  data?: any[];
  fullScreen?: boolean;
  wrapperHeight?: string;
  maxZoom?: number;
}
export interface AddLocationsToMapState {}
export const AddLocationsToMap = (
  props: AddLocationsToMapProps
): React.ReactElement => {
  const [viewport, setViewport] = React.useState({
    latitude: props.latitude,
    longitude: props.longitude,
    zoom: props.zoom,
    width: props.width,
    height: props.height,
  });
  const handleViewportChange = (port: any) => {
    setViewport(port);
  };
  return (
    <KEEWISTMap
      viewport={viewport}
      onViewportChange={(port: any) => handleViewportChange(port)}
      data={props.data}
      fullScreen={props.fullScreen}
      wrapperHeight={props.wrapperHeight}
      maxZoom={props.maxZoom}
    />
  );
};
export default AddLocationsToMap;
