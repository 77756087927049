import {
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../../../../Router";
import styles from "./styles";
import { nullCheck } from "../../../../../../ui-kit/common/validation/KeewistValidations";
import { connect, useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import theme from "../../../../../theme/theme";
import { validationMessages } from "../../helper/constants";
import { NewsApiService } from "../../../../../../api-kit/news/news-api.service";
import KEEWISTEditor from "../../../../../../ui-kit/components/KEEWIST-editor";

interface EditNewsProps {
  user?: any;
}

const EditNews = (props: EditNewsProps) => {
  const newsApiService = new NewsApiService();
  const history = useHistory();
  const location: any = useLocation();
  const classes = styles();
  const dispatch = useDispatch();
  const [newsId, setNewsId] = React.useState("");
  const [news, setNews] = React.useState({
    title: "",
    content: "",
  });
  const [isTitleInvalid, setIsTitleInvalid] = React.useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = React.useState("");

  useEffect(() => {
    if (location.state !== null && location.state.newsId !== null) {
      setNewsId(location.state.newsId);
      newsApiService
        .getNews(newsId ? newsId : location.state.newsId)
        .then((res) => {
          setNews(res.data);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!isTitleInvalid) {
      let body = {
        id: newsId,
        title: news.title,
        content: news.content,
      };
      newsApiService
        .editNews(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "Successfully updated.",
              type: "success",
            })
          );
          history.push(Paths.newsList);
        })
        .catch((err) =>
          dispatch(
            addSnackBar({
              message: "Unable to update news.",
              type: "error",
            })
          )
        );
    }
  };

  const handleTitleChange = (event: any) => {
    let value = event.target?.value;
    const validated = nullCheck(value, validationMessages.title);
    if (validated.isInvalid) {
      setIsTitleInvalid(validated.isInvalid);
      setTitleErrorMessage(validated.validationError);
    } else {
      setIsTitleInvalid(false);
      setTitleErrorMessage("");
    }
    // checkValueChanged(value, "email");
    setNews((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.newsList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Edit news
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextField
            id="standard-basic"
            label="Title"
            value={news.title ? news.title : ""}
            required
            size="medium"
            variant="outlined"
            name="title"
            // focused={editUserState.focused}
            onChange={handleTitleChange}
            fullWidth
            error={isTitleInvalid}
            helperText={titleErrorMessage}
          />
          <div className={classes.editor}>
            <KEEWISTEditor
              data={news.content}
              onChange={(e: any) =>
                setNews((prevState) => ({
                  ...prevState,
                  content: e,
                }))
              }
              onBlur={(e: any) =>
                setNews((prevState) => ({
                  ...prevState,
                  content: e,
                }))
              }
            />
          </div>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              component="span"
              style={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.info.light,
                width: "100%",
                marginTop: theme.spacing(1),
              }}
            >
              Upload image
            </Button>
          </label>
          <ButtonGroup
            disableElevation
            variant="contained"
            className={classes.btn}
          >
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={() => history.push(Paths.newsList)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,
              }}
              // disabled={editUserState.disabled}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </form>
      </Paper>
    </>
  );
};

const mapDispatchToProps = (props) => {
  return {
    user: props.user,
  };
};
export default connect(mapDispatchToProps, {})(EditNews);
