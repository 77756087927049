import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KEEWISTTableToolbar from "../../../../../ui-kit/components/table/KEEWIST-TableToolbar";
import KEEWISTTableHead from "../../../../../ui-kit/components/table/KEEWIST-tableHead";
import { Button, ButtonGroup } from "@material-ui/core";
import { Delete, Edit, ViewList } from "@material-ui/icons";
import KEEWISTFormDialog from "./KEEWIST-dailog";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Paths } from "../../../../Router";
import { addSnackBar } from "../../../../../_redux/_actions/snackBar.actions";
import { bindActionCreators } from "redux";
import theme from "../../../../theme/theme";

interface NewsTableProps {
  rows: any;
  headCells: any;
  actions: any;
  handleOnDelete: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    red: {
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
      color: theme.palette.common.white,
    },
  })
);

const NewsTable = (props: NewsTableProps): React.ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof any>("title");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [news, setNews] = useState({
    _id: null,
    title: null,
    content: null,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDelete = (val: any) => {
    setNews(val);
    setOpen(true);
  };

  const handleConfirmRequest = () => {
    const { _id: id } = news;
    props.handleOnDelete(id);
    setOpen(false);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string,
    id: string
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <KEEWISTTableToolbar
          tableName="News list"
          btnValue={true}
          onClick={() => history.push(Paths.newsAdd)}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <KEEWISTTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
              headCells={props.headCells}
            />
            <TableBody>
              {stableSort(props.rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const date = new Date(row.createdAt);
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row.title, row._id)
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {date.toDateString()}
                      </TableCell>
                      <TableCell align="right">
                        {
                          <ButtonGroup
                            size="small"
                            variant="contained"
                            aria-label="contained primary button group"
                          >
                            <Button
                              style={{
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.main,
                              }}
                              component={Link}
                              to={{
                                pathname: Paths.newsEdit,
                                state: {
                                  newsId: row._id,
                                },
                              }}
                            >
                              <Edit />
                            </Button>
                            <Button
                              style={{
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.main,
                              }}
                              component={Link}
                              to={{
                                pathname: Paths.newsView,
                                state: {
                                  newsId: row._id,
                                },
                              }}
                            >
                              <ViewList />
                            </Button>
                            <Button
                              style={{
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.secondary.main,
                              }}
                              onClick={() => handleDelete(row)}
                            >
                              <Delete />
                            </Button>
                          </ButtonGroup>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <KEEWISTFormDialog
          warningQuestion={"Delete this news?"}
          content={news}
          isOpen={open}
          handleClose={handleCloseDialog}
          handleConfirm={handleConfirmRequest}
        />
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    actions: bindActionCreators({ addSnackBar }, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(NewsTable);
