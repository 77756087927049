import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

interface Props {}

function NotFound(props: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <h1>404 - Page not found</h1>
            <p>Sorry, the page you were looking for is not available </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default NotFound;
