import React, { createContext, useState } from "react";
import { PolicyState, PolicyStateDefault } from "./PolicyState";

interface PolicyContext {
  policyState: PolicyState;
  updatePolicyState: React.Dispatch<React.SetStateAction<any>>;
}

export const PolicyStateContext = createContext({} as PolicyContext);

export const PolicyProvider = ({ children }) => {
  const [policyState, setPolicyState] =
    useState<PolicyState>(PolicyStateDefault);

  function updatePolicyState(newState: any) {
    setPolicyState((prevState: any) => {
      return { ...prevState, ...newState };
    });
  }

  const value = {
    policyState,
    updatePolicyState,
  };

  return (
    <PolicyStateContext.Provider value={value}>
      {children}
    </PolicyStateContext.Provider>
  );
};
