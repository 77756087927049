import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NewsApiService } from "../../../../api-kit/news/news-api.service";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import KEEWISTTable from "./components/NewsTable";

const NewsList = () => {
  const dispatch = useDispatch();
  const [newsList, setNewsList] = useState([]);
  const newsApiService = new NewsApiService();

  useEffect(() => {
    newsApiService
      .getNewsList()
      .then((res) => {
        setNewsList(res.data);
      })
      .catch((error) => console.log(error));
    return () => {
      setNewsList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Title",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: true,
      label: "createdAt",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const handleOnDelete = (id: string) => {
    newsApiService
      .deleteNews(id)
      .then((res) => {
        const newNews = newsList.filter((news) => news._id !== res.data.id);
        setNewsList(newNews);
        dispatch(
          addSnackBar({
            message: "News successfully deleted!",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to delete this news!",
            type: "error",
          })
        )
      );
  };

  return (
    <>
      <KEEWISTTable
        rows={newsList}
        headCells={headCells}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};

export default NewsList;
