import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { NumberFormatCustom } from "../../../../../../ui-kit/common/NumberFormat";
import theme from "../../../../../theme/theme";
import { PropertyStateContext } from "../../PropertyContext";
import { validationMessages } from "../../helpers/constants";
import { updatePropertyStatesFunc } from "../../helpers/HelperFunctions";
import styles from "../styles";
import KEEWISTEditor from "../../../../../../ui-kit/components/KEEWIST-editor";
import {
  Box,
  FormControl,
  FormHelperText,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import KEEWISTFileUpload from "../../../../../../ui-kit/components/KEEWIST-fileUpload";
import { UploadApiService } from "../../../../../../api-kit/upload/upload-api.services";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import { useDispatch } from "react-redux";

const AddHouseDetails = () => {
  const uploadApiService = new UploadApiService();
  const dispatch = useDispatch();
  const classes = styles();
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);

  const [cal, setCal] = React.useState({});

  const handleHouseNameChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseName,
      "isHouseNameInvalid",
      "houseNameValidationMessage",
      "houseName"
    );
  };
  const handleHouseTypeChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseType,
      "isHouseTypeInvalid",
      "houseTypeValidationMessage",
      "houseType"
    );
  };
  const handleHouseBedChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseBed,
      "isHouseBedInvalid",
      "houseBedValidationMessage",
      "houseBed"
    );
  };
  const handleHouseBathChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.houseBath,
      "isHouseBathInvalid",
      "houseBathValidationMessage",
      "houseBath"
    );
  };
  const handleGuestLimitChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.guestLimit,
      "isGuestLimitInvalid",
      "guestLimitValidationMessage",
      "guestLimit"
    );
  };
  const handlePriceChange = (e: any) => {
    let value = e.target.value;
    if (value !== "") {
      value.replace(/-/g, "");
      updateAddPropertyState({ focused: true });
    } else {
      updateAddPropertyState({ focused: false });
    }
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value.replace(/-/g, ""),
      validationMessages.price,
      "isPricePerNightInvalid",
      "pricePerNightValidationMessage",
      "pricePerNight"
    );
  };

  const handleImageUpload = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i]);
    }
    formData.append("length", files.length);
    updateAddPropertyState({ disableBtn: true });
    uploadApiService
      .upload(formData)
      .then((res) => {
        const joined = addPropertyState.propertyImages.concat(res.data);
        updateAddPropertyState({ propertyImages: joined, disableBtn: false });
        dispatch(
          addSnackBar({
            message: "Image uploaded.",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to upload image.",
            type: "error",
          })
        )
      );

    const joined = addPropertyState.propertyImages.concat(formData);
    updateAddPropertyState({ propertyImages: joined });
  };

  const handleCalFile = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      setCal(text);
    };
    reader.readAsText(e.target.files[0]);
  };
  return (
    <>
      <TextField
        variant="outlined"
        size="medium"
        margin="normal"
        required
        fullWidth
        name="house-name"
        label="Name"
        type="text"
        id="house-name"
        value={addPropertyState.houseName ? addPropertyState.houseName : ""}
        onBlur={(e: any) => handleHouseNameChange(e)}
        onChange={(e: any) => handleHouseNameChange(e)}
        error={addPropertyState.isHouseNameInvalid}
        helperText={addPropertyState.houseNameValidationMessage}
      />
      <FormControl variant="outlined" size="medium" margin="normal">
        <InputLabel id="keewist-select-type-label">House type</InputLabel>
        <Select
          labelId="keewist-select-type-label"
          id="keewist-select-type"
          value={
            addPropertyState.houseType
              ? addPropertyState.houseType
              : "entirehouse"
          }
          onBlur={(e: any) => handleHouseTypeChange(e)}
          onChange={(e: any) => handleHouseTypeChange(e)}
          error={addPropertyState.isHouseTypeInvalid}
          label="House type"
        >
          <MenuItem value={"entirehouse"}>entirehouse</MenuItem>
          <MenuItem value={"privateroom"}>privateroom</MenuItem>
        </Select>
        <FormHelperText style={{ color: " #E73935" }}>
          {addPropertyState.houseTypeValidationMessage}
        </FormHelperText>
      </FormControl>
      <br />
      <div className={classes.editor}>
        <FormHelperText>Add house description here.</FormHelperText>
        <KEEWISTEditor
          data={addPropertyState.houseDescription}
          onChange={(e: any) => updateAddPropertyState({ houseDescription: e })}
          onBlur={(e: any) => updateAddPropertyState({ houseDescription: e })}
        />
      </div>
      <TextField
        variant="outlined"
        size="medium"
        margin="normal"
        required
        fullWidth
        name="house-beds"
        label="Beds"
        type="number"
        InputProps={{ inputProps: { min: 0, max: 5 } }}
        id="house-beds"
        onBlur={(e: any) => handleHouseBedChange(e)}
        onChange={(e: any) => handleHouseBedChange(e)}
        value={addPropertyState.houseBed ? addPropertyState.houseBed : ""}
        error={addPropertyState.isHouseBedInvalid}
        helperText={addPropertyState.houseBedValidationMessage}
      />
      <TextField
        variant="outlined"
        size="medium"
        required
        fullWidth
        name="house-baths"
        margin="normal"
        label="Baths"
        type="number"
        id="house-baths"
        InputProps={{ inputProps: { min: 0, max: 5 } }}
        onBlur={(e: any) => handleHouseBathChange(e)}
        onChange={(e: any) => handleHouseBathChange(e)}
        value={addPropertyState.houseBath ? addPropertyState.houseBath : ""}
        error={addPropertyState.isHouseBathInvalid}
        helperText={addPropertyState.houseBathValidationMessage}
      />

      <TextField
        variant="outlined"
        size="medium"
        margin="normal"
        required
        fullWidth
        name="house-guest-limit"
        label="Guest limit"
        type="number"
        InputProps={{ inputProps: { min: 0, max: 10 } }}
        id="house-guest-limit"
        onBlur={(e: any) => handleGuestLimitChange(e)}
        onChange={(e: any) => handleGuestLimitChange(e)}
        value={addPropertyState.guestLimit ? addPropertyState.guestLimit : ""}
        error={addPropertyState.isGuestLimitInvalid}
        helperText={addPropertyState.guestLimitValidationMessage}
      />
      <TextField
        label="Price per night"
        variant="outlined"
        size="medium"
        margin="normal"
        required
        fullWidth
        value={
          addPropertyState.pricePerNight ? addPropertyState.pricePerNight : ""
        }
        onBlur={(e: any) => handlePriceChange(e)}
        onChange={(e: any) => handlePriceChange(e)}
        focused={addPropertyState.focused}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          inputComponent: NumberFormatCustom as any,
        }}
        error={addPropertyState.isPricePerNightInvalid}
        helperText={addPropertyState.pricePerNightValidationMessage}
      />
      {/* <KEEWISTProgress /> */}
      <Box sx={{ mt: theme.spacing(0.1) }}>
        <KEEWISTFileUpload handleImageUpload={handleImageUpload} />
      </Box>
      <Box>
        <ImageList
          sx={{ width: "100%", height: "100%", mt: 1 }}
          cols={3}
          rowHeight={164}
        >
          {addPropertyState.propertyImages.length !== 0 &&
            addPropertyState.propertyImages.map((item, key) => (
              <ImageListItem key={key}>
                <img
                  crossOrigin="anonymous"
                  src={`${item.filepath}`}
                  alt={item.filename}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Box>
      <div style={{ marginBottom: theme.spacing(1) }}></div>
    </>
  );
};

export default AddHouseDetails;
