export interface AddPropertyState {
  houseName: string;
  houseType: string;
  houseDescription: string;
  houseBed: number;
  houseBath: number;
  houseRule: string;
  houseRules: string[];
  healthSafety: string;
  healthSafeties: string[];
  cancellationPolicy: string;
  cancellationPolicies: string[];
  selfCheckIn: boolean;
  toListing: boolean;
  amenity: string;
  amenities: string[];
  offerings: boolean;
  enhancedCleaningType: string;
  enhancedCleaningDesc: string;
  enhancedCleaningDescription: string;
  enhancedCleanings: any[];
  guestLimit: number;
  setUploadPercentage: number;
  propertyImages: any[];
  pricePerNight: string;
  locationState: string;
  locationAddress: string;
  locationCity: string;
  locationZipCode: string;
  viewport: {
    latitude: number;
    longitude: number;
    zoom: number;
  };

  // validation
  isHouseNameInvalid: boolean;
  houseNameValidationMessage: string;

  isHouseTypeInvalid: boolean;
  houseTypeValidationMessage: string;

  isHouseDescriptionInvalid: boolean;
  houseDescriptionValidationMessage: string;

  isHouseBedInvalid: boolean;
  houseBedValidationMessage: string;

  isHouseBathInvalid: boolean;
  houseBathValidationMessage: string;

  isGuestLimitInvalid: boolean;
  guestLimitValidationMessage: string;

  isPricePerNightInvalid: boolean;
  pricePerNightValidationMessage: string;

  isLocationStateInvalid: boolean;
  locationStateValidationMessage: string;

  isLocationAddressInvalid: boolean;
  locationAddressValidationMessage: string;

  isLocationCityInvalid: boolean;
  locationCityValidationMessage: string;

  isLocationZipCodeInvalid: boolean;
  locationZipCodeValidationMessage: string;
  focused: boolean;
  disableBtn: boolean;
  steps: [
    { label: string; error: boolean },
    { label: string; error: boolean },
    { label: string; error: boolean },
    { label: string; error: boolean }
  ];
  // Offerings
  // Bathroom
  bathtub: boolean;
  hairDryer: boolean;
  cleaningProducts: boolean;
  shampoo: boolean;
  conditioner: boolean;
  bodySoap: boolean;
  hotWater: boolean;
  showerGel: boolean;
  // Bedroom and laundry
  freeWasherInBuilding: boolean;
  freeDryerInBuilding: boolean;
  essentials: boolean;
  hangers: boolean;
  bedLinens: boolean;
  extraPillowsAndBlankets: boolean;
  iron: boolean;
  clothingStorage: boolean;
  //Entertaiment
  enthernetConnection: boolean;
  smartTv: boolean;
  // Family
  Crib: boolean;
  PackNPlay: boolean;
  highChair: boolean;
  babyBath: boolean;
  childrensDinnerware: boolean;
  boardGames: boolean;
  babySafetyGates: boolean;
  // Heating and cooling
  centralAirConditioning: boolean;
  ceilingFan: boolean;
  portableFans: boolean;
  heating: boolean;
  //Home safety
  securityCamerasOnProperty: boolean;
  smokeAlarm: boolean;
  carbonMonoxideAlarm: boolean;
  fireExtinguisher: boolean;
  firstAidKit: boolean;
  // Internet and office
  wifi: boolean;
  dedicatedWorkspace: boolean;
  // Kitchen and dining
  kitchen: boolean;
  refrigerator: boolean;
  microwave: boolean;
  cookingBasics: boolean;
  dishesAndSilverware: boolean;
  freezer: boolean;
  dishwasher: boolean;
  stainlessSteelElectricStove: boolean;
  oven: boolean;
  hotWaterKettle: boolean;
  coffeeMaker: boolean;
  wineGlasses: boolean;
  toaster: boolean;
  bakingSheet: boolean;
  blender: boolean;
  barbecueUtensils: boolean;
  diningTable: boolean;
  coffee: boolean;
  // Location features
  privateEntrance: boolean;
  // Outdoor
  sharedPatioOrBalcony: boolean;
  privateBackyardNotFullyFenced: boolean;
  outdoorFurniture: boolean;
  outdoorDiningArea: boolean;
  bbqGrill: boolean;
  // Parking and facilities
  freeParkingOnPremises: boolean;
  freeStreetParking: boolean;
  // Services
  luggageDropoffAllowed: boolean;
  longTermStaysAllowed: boolean;
  smartLock: boolean;
  cleaningAvailableDuringStay: boolean;
}

export const addPropertyStateDefault: AddPropertyState = {
  locationState: "",
  locationAddress: "",
  locationCity: "",
  locationZipCode: "",
  houseName: "",
  houseType: "",
  houseDescription: "",
  houseBed: 0,
  houseBath: 0,
  houseRule: "",
  houseRules: [],
  healthSafety: "",
  healthSafeties: [],
  cancellationPolicy: "",
  cancellationPolicies: [],
  amenity: "",
  amenities: [],
  offerings: false,
  enhancedCleaningType: "",
  enhancedCleaningDesc: "",
  enhancedCleaningDescription: "",
  enhancedCleanings: [],
  guestLimit: 0,
  selfCheckIn: false,
  toListing: false,
  pricePerNight: "",
  propertyImages: [],
  setUploadPercentage: 0,
  isHouseNameInvalid: false,
  houseNameValidationMessage: "",
  viewport: {
    latitude: 37.6922361,
    longitude: -97.3375448,
    zoom: 10,
  },

  isHouseTypeInvalid: false,
  houseTypeValidationMessage: "",

  isHouseDescriptionInvalid: false,
  houseDescriptionValidationMessage: "",

  isHouseBedInvalid: false,
  houseBedValidationMessage: "",

  isHouseBathInvalid: false,
  houseBathValidationMessage: "",

  isGuestLimitInvalid: false,
  guestLimitValidationMessage: "",

  isPricePerNightInvalid: false,
  pricePerNightValidationMessage: "",

  isLocationStateInvalid: false,
  locationStateValidationMessage: "",

  isLocationAddressInvalid: false,
  locationAddressValidationMessage: "",

  isLocationCityInvalid: false,
  locationCityValidationMessage: "",

  isLocationZipCodeInvalid: false,
  locationZipCodeValidationMessage: "",
  focused: false,
  disableBtn: false,
  steps: [
    { label: "House details", error: false },
    { label: "House address", error: false },
    { label: "House rules", error: false },
    { label: "Done", error: false },
  ],
  // Offerings
  // Bathroom
  bathtub: false,
  hairDryer: false,
  cleaningProducts: false,
  shampoo: false,
  conditioner: false,
  bodySoap: false,
  hotWater: false,
  showerGel: false,
  // Bedroom and laundry
  freeWasherInBuilding: false,
  freeDryerInBuilding: false,
  essentials: false,
  hangers: false,
  bedLinens: false,
  extraPillowsAndBlankets: false,
  iron: false,
  clothingStorage: false,
  //Entertaiment
  enthernetConnection: false,
  smartTv: false,
  // Family
  Crib: false,
  PackNPlay: false,
  highChair: false,
  babyBath: false,
  childrensDinnerware: false,
  boardGames: false,
  babySafetyGates: false,
  // Heating and cooling
  centralAirConditioning: false,
  ceilingFan: false,
  portableFans: false,
  heating: false,
  //Home safety
  securityCamerasOnProperty: false,
  smokeAlarm: false,
  carbonMonoxideAlarm: false,
  fireExtinguisher: false,
  firstAidKit: false,
  // Internet and office
  wifi: false,
  dedicatedWorkspace: false,
  // Kitchen and dining
  kitchen: false,
  refrigerator: false,
  microwave: false,
  cookingBasics: false,
  dishesAndSilverware: false,
  freezer: false,
  dishwasher: false,
  stainlessSteelElectricStove: false,
  oven: false,
  hotWaterKettle: false,
  coffeeMaker: false,
  wineGlasses: false,
  toaster: false,
  bakingSheet: false,
  blender: false,
  barbecueUtensils: false,
  diningTable: false,
  coffee: false,
  // Location features
  privateEntrance: false,
  // Outdoor
  sharedPatioOrBalcony: false,
  privateBackyardNotFullyFenced: false,
  outdoorFurniture: false,
  outdoorDiningArea: false,
  bbqGrill: false,
  // Parking and facilities
  freeParkingOnPremises: false,
  freeStreetParking: false,
  // Services
  luggageDropoffAllowed: false,
  longTermStaysAllowed: false,
  smartLock: false,
  cleaningAvailableDuringStay: false,
};
