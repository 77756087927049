import React, { createContext, useState } from "react";
import {
  AddPropertyState,
  addPropertyStateDefault,
} from "./addProperty/AddPropertyState";

interface PropertyContext {
  addPropertyState: AddPropertyState;
  updateAddPropertyState: React.Dispatch<React.SetStateAction<any>>;
}

export const PropertyStateContext = createContext({} as PropertyContext);

export const PropertyProvider = ({ children }) => {
  const [addPropertyState, setAddPropertyState] = useState<AddPropertyState>(
    addPropertyStateDefault
  );

  function updateAddPropertyState(newState: any) {
    setAddPropertyState((prevState: any) => {
      return { ...prevState, ...newState };
    });
  }

  const value = {
    addPropertyState,
    updateAddPropertyState,
  };

  return (
    <PropertyStateContext.Provider value={value}>
      {children}
    </PropertyStateContext.Provider>
  );
};
