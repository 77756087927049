import { PhotoCamera } from "@material-ui/icons";
import { Button, IconButton, Stack, styled } from "@mui/material";
import React from "react";

interface KKEEWISTFileUploadProps {
  handleImageUpload: any;
}

export default function KEEWISTFileUpload(props: KKEEWISTFileUploadProps) {
  const Input = styled("input")({
    display: "none",
  });

  const handleImageUpload = (e) => {
    props.handleImageUpload(e, e.target.files);
  };
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleImageUpload}
          />
          <Button variant="contained" component="span">
            Choose Image
          </Button>
        </label>
        <label htmlFor="icon-button-file">
          <Input accept="image/*" id="icon-button-file" type="file" />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </Stack>
    </>
  );
}
