import { Link, Typography } from "@material-ui/core";

interface Props {
  site_url?: string;
  site_name?: string;
  color?: boolean;
}

export default function KWCopyright(props: Props) {
  const companyName = props.site_name?.toUpperCase();
  return (
    <Typography
      variant="body2"
      style={{
        color: "#dddddd",
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0.5,
      }}
      align="center"
    >
      <Link color="inherit" href={props.site_url}>
        {companyName}
      </Link>
      {"© "}
      {new Date().getFullYear()}
      {".  ALL RIGHTS RESERVED."}
    </Typography>
  );
}
