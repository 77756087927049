import {
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../../../Router";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import styles from "./styles";
import { PropertyApiService } from "../../../../../api-kit/property/property-api.service";
import ReactHtmlParser from "react-html-parser";
import { Grid } from "@mui/material";
import theme from "../../../../theme/theme";

const ViewProperty = () => {
  const classes = styles();
  const history = useHistory();
  const location: any = useLocation();
  const propertyApiService = new PropertyApiService();
  const [house, setHouse] = useState({
    property_name: "",
    property_type: "",
    property_description: "",
    property_location: {
      property_address1: "",
      property_city: "",
      property_state: "",
      property_zipCode1: "",
    },
    property_beds: 0,
    property_baths: 0,
    property_guest_limit: 0,
    property_comments: "",
    property_price_per_night: "",
    property_house_rules: "",
    property_things_to_keep_in_minds: "",
    amenities: [],
    geo_location: {
      latitude: 0,
      longitude: 0,
    },
    self_checkin: false,
    things_to_know: {
      house_rules: [],
      health_safety: [],
      cancellation_policy: [],
    },
  });

  useEffect(() => {
    if (location.state !== null && location.state.houseId !== null) {
      propertyApiService
        .getProperty(location.state.houseId)
        .then((res) => setHouse(res.data))
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.propertyList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <div className={classes.content}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            gutterBottom
            color="textSecondary"
            style={{ textTransform: "uppercase", fontSize: "1.5em" }}
          >
            {house.property_name}
          </Typography>
          <Typography
            variant="caption"
            align="center"
            gutterBottom
            color="textSecondary"
            style={{ textTransform: "uppercase", display: "block" }}
          >
            bedroom {house.property_beds}
            {" · "} bathroom {house.property_baths} {" · "}
            {house.property_type}
          </Typography>
          <List>
            <ListItem className={classes.list}>
              <ListItemText
                primary={ReactHtmlParser(house.property_description)}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${
                  house.property_location?.property_address1 !== undefined
                    ? house.property_location?.property_address1
                    : ""
                } ${
                  house.property_location?.property_city !== undefined
                    ? house.property_location?.property_city + " ,"
                    : ""
                } ${
                  house.property_location?.property_state !== undefined
                    ? house.property_location?.property_state
                    : ""
                } ${
                  house.property_location?.property_zipCode1 !== undefined
                    ? house.property_location?.property_zipCode1
                    : ""
                }`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Guest limit: ${house.property_guest_limit}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Price per night: $${house.property_price_per_night}`}
              />
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item sm={12}>
                  <Typography>Amenities:</Typography>
                </Grid>
                {house.amenities.map((offers, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <Typography variant="body2" color={"textSecondary"}>
                      {offers}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant={"body1"}>Coordinates:</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color={"textSecondary"}>
                    {house.geo_location.latitude} {" | "}
                    {house.geo_location.longitude}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Self checkin: ${house.self_checkin ? "Yes" : "No"}`}
              />
            </ListItem>
            <ListItem>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className={classes.todoHeader}>
                    House rules
                  </Typography>
                  {house.things_to_know?.house_rules.map((rule) => (
                    <Typography variant="body1">{rule}</Typography>
                  ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" className={classes.todoHeader}>
                    Health safety
                  </Typography>
                  {house.things_to_know?.health_safety.map((rule) => (
                    <Typography variant="body1">{rule}</Typography>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.todoHeader}>
                    Cancellation policy
                  </Typography>
                  {house.things_to_know?.cancellation_policy.map((rule) => (
                    <Typography variant="body1">{rule}</Typography>
                  ))}
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </div>
      </Paper>
    </>
  );
};

export default ViewProperty;
