import React, { useEffect } from "react";
import clsx from "clsx";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import ListSubheader from "@material-ui/core/ListSubheader";
import {
  CleanHands,
  LocalActivity,
  Dashboard,
  People,
  Menu,
  ChevronLeft,
  House,
  Person,
} from "@mui/icons-material";
import PolicyIcon from "@mui/icons-material/Policy";
import ArticleIcon from "@mui/icons-material/Article";
import HandshakeIcon from "@mui/icons-material/Handshake";
import styles from "./styles";
import { HomeIcon, Logout } from "../../../svg";
import { useHistory } from "react-router-dom";
import { Paths } from "../../../Router";
import { connect } from "react-redux";
import { logout } from "../../../../_redux/_actions/user.actions";
import theme from "../../../theme/theme";
import { userRole } from "../users/helper/constants";

interface DashboardProps {
  logout: any;
  user: any;
  children: any;
}

const DashboardTemp = (props: DashboardProps): React.ReactElement => {
  const classes = styles();
  const [open, setOpen] = React.useState(true);

  const history = useHistory();

  useEffect(() => {
    if (!props.user.loggedIn) {
      history.push(Paths.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleOnClickLogout = () => {
    props.logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <Menu />
          </IconButton>
          <Typography
            component="h4"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            KCICTRENTAL
          </Typography>
          <Button onClick={() => history.push(Paths.home)} color="inherit">
            Home
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft style={{ color: theme.palette.common.white }} />
          </IconButton>
        </div>
        <List>
          <ListSubheader
            color="inherit"
            style={{ backgroundColor: "none !important" }}
            inset
          >
            Components
          </ListSubheader>
          <ListItem
            className={classes.item}
            onClick={() => history.push(Paths.dashboard)}
          >
            <ListItemIcon>
              <Dashboard className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          {(props.user.user?.role === userRole.KWSM ||
            props.user.user?.role === userRole.KWA) && (
            <>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.authHome)}
              >
                <ListItemIcon>
                  <HomeIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.authAbout)}
              >
                <ListItemIcon>
                  <HandshakeIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Why Choose Us" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.userList)}
              >
                <ListItemIcon>
                  <People className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.propertyList)}
              >
                <ListItemIcon>
                  <House className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Listing" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.hygiene)}
              >
                <ListItemIcon>
                  <CleanHands className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Enhanced Cleaning" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.newsList)}
              >
                <ListItemIcon>
                  <ArticleIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="News" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.thingsToDoList)}
              >
                <ListItemIcon>
                  <LocalActivity className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Activities" />
              </ListItem>
              <ListItem
                className={classes.item}
                onClick={() => history.push(Paths.policies)}
              >
                <ListItemIcon>
                  <PolicyIcon className={classes.icons} />
                </ListItemIcon>
                <ListItemText primary="Policies" />
              </ListItem>
              <Divider
                style={{ backgroundColor: theme.palette.common.white }}
              />
            </>
          )}

          <ListItem
            className={classes.item}
            onClick={() => history.push(Paths.account)}
          >
            <ListItemIcon>
              <Person className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem className={classes.item} onClick={handleOnClickLogout}>
            <ListItemIcon>
              <Logout className={classes.icons} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        className={open ? classes.content : classes.colapsedMenu}
      >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg">{props.children}</Container>
      </Box>
    </div>
  );
};

const mapDispatchToProps = (props) => {
  return {
    user: props.user,
  };
};
export default connect(mapDispatchToProps, { logout })(DashboardTemp);
