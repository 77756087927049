import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  content: {
    maxWidth: 600,
    display: "block",
    margin: "0 auto 10px auto",
  },
}));
