export interface AddNewsState {
    newsImages: any[];
    disableBtn: boolean;
    title: string;
    content: string;
    isHeaderValid: boolean;
    header: string;
    inspiringQuote: string;
    quotee: string;
}

export const AddNewsStateDefault: AddNewsState = {
    newsImages: [],
    disableBtn: false,
    title: "",
    content: "",
    isHeaderValid: false,
    header: "",
    inspiringQuote: "",
    quotee: "",
};
