import {
  Button,
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Add } from "@material-ui/icons";
import theme from "../../../app/theme/theme";

interface KEEWISTTableToolbarProps {
  tableName: string;
  onClick?: any;
  btnValue?: boolean;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

const KEEWISTTableToolbar = (props: KEEWISTTableToolbarProps) => {
  const classes = useToolbarStyles();

  const handleOnClick = () => {
    props.onClick();
  };

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h4"
        component="h2"
        id="tableTitle"
        gutterBottom
        color="textSecondary"
        style={{ textTransform: "uppercase", fontSize: "1.5em" }}
      >
        {props.tableName}
      </Typography>
      {props.btnValue && (
        <Button
          onClick={handleOnClick}
          variant="contained"
          size="small"
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            justifyContent: "initial",
            minWidth: 0,
            marginRight: theme.spacing(1),
          }}
        >
          <Add />
        </Button>
      )}
    </Toolbar>
  );
};
export default KEEWISTTableToolbar;
