import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  form: {
    width: "100%",
    position: "relative",
    marginBottom: theme.spacing(2),
  },
  datePicker: {
    border: "1px solid #EBEBEB",
    height: 50,
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textTransform: "uppercase",
    cursor: "pointer",
    marginBottom: theme.spacing(1),
    padding: "0 30px",
  },
  guestLimit: {
    height: "20%",
    minHeight: 200,
    width: "100%",
    border: "1px solid #EBEBEB",
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 11,
    backgroundColor: theme.palette.common.white,
    padding: "10px 30px",
    paddingTop: theme.spacing(4),
  },
  stickyFooter: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      display: "flex",
      bottom: "6%",
      right: "4%",
      zIndex: 2,
    },
  },
  guestLimitContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  homePrice: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 20,
    textTransform: "uppercase",
  },
  night: {
    fontSize: 12,
    paddingTop: 4,
    paddingLeft: 2,
    color: "#6c6c6c",
  },
  image: {
    width: "100%"
  }
}));
