import { connect } from "react-redux";
import { Redirect, Route } from "react-router";
import { getUserInfo } from "../_helpers/auth-header";
import Dashboard from "../../app/components/auth/template/Dashboard";

const PrivateRouteComponent = ({
  component: Component,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        {isUserLoggedIn() ? (
          <Dashboard>
            <Component {...props} />
          </Dashboard>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )}
      </>
    )}
  />
);
const isUserLoggedIn = () => {
  return getUserInfo();
};
const mapStateToProps = (state, ownProps) => ({
  authentication: state.authentication,
});
const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);

export default PrivateRoute;
