import { userConstants } from "../_constants/user.constants";
import history from "../_helpers/history";
import { UserApiService } from "../../api-kit/user/user-api.service";
import { Paths } from "../../app/Router";
import { addSnackBar } from "./snackBar.actions";

function failure(error) {
  return { type: userConstants.LOGIN_FAILURE, error };
}

export function Login(data: any) {
  const userApiService = new UserApiService();
  return (dispatch) => {
    if (data.email !== "" && data.password !== "") {
      userApiService
        .login(data)
        .then((res) => {
          dispatch({
            type: userConstants.LOGIN_SUCCESS,
            payload: res.data.user,
          });
          localStorage.setItem("usertoken", res.data.token);
          dispatch(
            addSnackBar({
              message: "Confirmed!",
              type: "success",
            })
          );
          if (history.location.pathname !== Paths.rental) {
            history.push(Paths.dashboard);
          }
        })
        .catch((error) => {
          dispatch(failure(error.data?.error_message));
          localStorage.setItem("usertoken", "");
          dispatch(
            addSnackBar({
              message: "Email address or password is wrong!",
              type: "error",
            })
          );
        });
    } else {
      dispatch(
        addSnackBar({
          message: "Please provide your login credentials!",
          type: "error",
        })
      );
    }
  };
}
export function logout() {
  localStorage.setItem("usertoken", "");
  if (history.location && history.location.state) {
    history.replace({ ...history.location });
  }
  history.push(Paths.home);
  return { type: userConstants.LOGOUT };
}
