import { WrapperService } from "../wrapper.service";
import { authHeader } from "../../_redux/_helpers/auth-header";

export class UploadApiService {
  public apiService: WrapperService = new WrapperService();
  private requestTimeout = 0; //no timeout;
  private responseEncoding = "utf8";
  private baseURL = process.env.REACT_APP_API_PORT;

  public upload(file: any) {
    const apiParam = {
      name: "upload",
      method: "POST",
      params: {},
      headers: {
        "Content-Type": "multipart/form-data",
        ...authHeader,
      },
      body: file,
      suffixURL: "/upload",
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public updateOrder(body: any) {
    const apiParam = {
      name: "upload",
      method: "POST",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
      body: body,
      suffixURL: `/update-photo-order`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }
}
