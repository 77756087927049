import React, { createContext, useState } from "react";
import {
  EditUserState,
  editUserStateDefault,
} from "./components/editUser/EditUserState";

interface UserContext {
  editUserState: EditUserState;
  updateEditUserState: React.Dispatch<React.SetStateAction<any>>;
}

export const UserStateContext = createContext({} as UserContext);

export const UserProvider = ({ children }) => {
  const [editUserState, setEditUserState] =
    useState<EditUserState>(editUserStateDefault);

  function updateEditUserState(newState: any) {
    setEditUserState((prevState: any) => {
      return { ...prevState, ...newState };
    });
  }

  const value = {
    editUserState,
    updateEditUserState,
  };

  return (
    <UserStateContext.Provider value={value}>
      {children}
    </UserStateContext.Provider>
  );
};
