import { Box, createStyles, Theme } from "@material-ui/core";
import React from "react";
import { DateRange } from "react-date-range";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../app/theme/theme";

const styles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      border: "1px solid #EBEBEB !important",
      fontFamily: "'Roboto', 'sans-serif' !important",
    },
  })
);

interface KEEWISTDatepickerProp {
  onChange: any;
  booked: any;
  booking: any;
  position?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-around"
    | "space-between";
  maxDate?: any;
}

const KEEWISTDatepicker = (props: KEEWISTDatepickerProp) => {
  const classes = styles();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent:
          props.position !== undefined ? props.position : "flex-start",
        width: "100%",
        padding: "20px 0",
      }}
    >
      <DateRange
        className={classes.datePicker}
        editableDateInputs={true}
        onChange={(item) => props.onChange([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={props.booking}
        minDate={new Date()}
        disabledDates={props.booked}
        rangeColors={[theme.palette.info.light]}
      />
    </Box>
  );
};

export default KEEWISTDatepicker;
