import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(5),
  },
  btn: {
    marginTop: theme.spacing(2),
  },
  form: {
    maxWidth: 600,
    display: "block",
    margin: "0 auto 10px auto",
    paddingBottom: theme.spacing(5),
  },
  input: {
    display: "none",
    marginTop: theme.spacing(2),
  },
  dividerFullWidth: {
    margin: `${theme.spacing(4)}px 0 0 0`,
  },
  chipsRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  chips: {
    marginTop: 10,
  },
  formControl: {
    marginTop: "16px !important",
    minWidth: "120px !important",
    marginRight: "14.4px !important",
  },
}));
