import { makeStyles, createStyles } from "@mui/styles";

export default makeStyles((theme) =>
  createStyles({
    form: {
      maxWidth: 600,
      display: "block",
      margin: "0 auto 10px auto",
      paddingBottom: theme.spacing(5),
    },
    root: {
      paddingTop: theme.spacing(5),
    },
    btn: {
      marginTop: theme.spacing(2),
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    editor: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  })
);
