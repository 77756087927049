import React, { createContext, useState } from "react";
import { ThingsToDoState, thingsToDoStateDefault } from "./ThingsToDoState";

interface ThingsToDoContext {
  thingsToDoState: ThingsToDoState;
  updateThingsToDoState: React.Dispatch<React.SetStateAction<any>>;
}

export const ThingsToDoStateContext = createContext({} as ThingsToDoContext);

export const ThingsToDoProvider = ({ children }) => {
  const [thingsToDoState, setThingsToDoState] = useState<ThingsToDoState>(
    thingsToDoStateDefault
  );

  function updateThingsToDoState(newState: any) {
    setThingsToDoState((prevState: any) => {
      return { ...prevState, ...newState };
    });
  }

  const value = {
    thingsToDoState,
    updateThingsToDoState,
  };

  return (
    <ThingsToDoStateContext.Provider value={value}>
      {children}
    </ThingsToDoStateContext.Provider>
  );
};
