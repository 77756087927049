import {
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../../../../../Router";
import styles from "../styles";
import {
  checkEmail,
  checkUserName,
} from "../../../../../../../ui-kit/common/validation/KeewistValidations";
import { UserApiService } from "../../../../../../../api-kit/user/user-api.service";
import { UserStateContext } from "../../../UserContext";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../../../_redux/_actions/snackBar.actions";
import { logout } from "../../../../../../../_redux/_actions/user.actions";
import theme from "../../../../../../theme/theme";

const EditAccount = () => {
  const userApiService = new UserApiService();
  const { editUserState, updateEditUserState } = useContext(UserStateContext);
  const classes = styles();
  const history = useHistory();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const [isEmailInvalid, setEmailInvalid] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");

  const [isUsernameInvalid, setUsernameInvalid] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState("");

  useEffect(() => {
    if (location.state !== null && location.state.userId !== null) {
      updateEditUserState({ userId: location.state.userId });
      userApiService
        .getUser(location.state.userId)
        .then((user) => {
          updateEditUserState({
            email: user.data?.email,
            username: user.data?.username,
            role: user.data?.role,
            clonedUser: user.data,
          });
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!editUserState.invalidEmail && !editUserState.invalidUsername) {
      let body = {
        id: editUserState.userId,
        email: editUserState.email,
        username: editUserState.username,
        role: editUserState.role,
      };
      userApiService
        .editUser(body)
        .then((data) => {
          dispatch(logout());
          history.push(Paths.login);
        })
        .catch((err) =>
          dispatch(
            addSnackBar({
              message: "Unable to update user.",
              type: "error",
            })
          )
        );
    }
  };

  const handleEmailChange = (event: any) => {
    let value = event.target?.value;
    const validated = checkEmail(value);
    if (validated.isInvalid) {
      updateEditUserState({ email: value });
      updateEditUserState({ invalidEmail: validated.isInvalid });
      updateEditUserState({ invalidEmailMessage: validated.validationError });
    } else {
      updateEditUserState({ email: value });
      updateEditUserState({ invalidEmail: false });
      updateEditUserState({ invalidEmailMessage: "" });
    }
  };

  const handleUsernameChange = (event: any) => {
    let value = event.target?.value;
    const validated = checkUserName(value);
    if (validated.isInvalid) {
      updateEditUserState({ username: value });
      updateEditUserState({ invalidUsername: validated.isInvalid });
      updateEditUserState({
        invalidUsernameMessage: validated.validationError,
      });
    } else {
      updateEditUserState({ username: value });
      updateEditUserState({ invalidUsername: false });
      updateEditUserState({ invalidUsernameMessage: "" });
    }
  };

  return (
    <>
      <Paper variant="outlined" square className={classes.paper}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Edit account
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextField
            id="account-username"
            margin="normal"
            label="Username"
            value={editUserState.username}
            required
            size="medium"
            variant="outlined"
            onBlur={handleUsernameChange}
            onChange={handleUsernameChange}
            fullWidth
            error={isUsernameInvalid}
            helperText={usernameErrorMessage}
          />
          <TextField
            id="account-email"
            label="Email"
            value={editUserState.email}
            required
            size="medium"
            variant="outlined"
            onBlur={handleEmailChange}
            onChange={handleEmailChange}
            fullWidth
            error={isEmailInvalid}
            helperText={emailErrorMessage}
          />
          <ButtonGroup
            disableElevation
            variant="contained"
            className={classes.btn}
          >
            <Button
              variant="contained"
              size="small"
              color="inherit"
              onClick={() => history.push(Paths.account)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </form>
      </Paper>
    </>
  );
};

export default EditAccount;
