import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    form: {
      maxWidth: 600,
      display: "block",
      margin: "0 auto 10px auto",
      paddingBottom: theme.spacing(5),
    },
    chipsRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    chips: {
      marginTop: 10,
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      "& svg": {
        color: "#fff",
      },
    },
    editor: {
      width: "100%",
      margin: `${theme.spacing(2)}px 0`,
    },
    enhacedCleaning: {
      width: "49%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    cleaningGap: {
      marginRight: 12,
    },
    title: {
      textTransform: "uppercase",
      fontSize: "1.5em !important",
      marginTop: `${theme.spacing(4)}px !important`,
    },
  })
);
