import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import history from "./history";
import rootReducer from "../_reducers";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

const store = createStore(
  rootReducer(history),
  initialState,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export default store;
