import { makeStyles, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme) =>
  createStyles({
    form: {
      maxWidth: 600,
      display: "block",
      margin: "0 auto 10px auto",
      paddingBottom: theme.spacing(5),
    },
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 120,
      marginRight: theme.spacing(1.8),
    },
    item: {
      paddingTop: theme.spacing(2),
    },
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    input: {
      display: "none",
      marginTop: theme.spacing(2),
    },
    chipsRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    chips: {
      marginTop: 10,
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      "& svg": {
        color: "#fff",
      },
    },
    zipCode: {
      marginTop: theme.spacing(1),
      maxWidth: "20%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    editor: {
      width: "100%",
      margin: `${theme.spacing(2)}px 0`,
    },
    enhacedCleaning: {
      width: "49%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    cleaningGap: {
      marginRight: 12,
    },
    checkboxOffers: {
      width: "100%"
    },
    helperText: {
      marginBottom: "1rem !important"
    }
  })
);
