import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Avatar,
  Button,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { connect } from "react-redux";
import { Login } from "../../../../_redux/_actions/user.actions";
import { LockOpen, Person } from "@material-ui/icons";
import {
  checkEmail,
  nullCheck,
} from "../../../../ui-kit/common/validation/KeewistValidations";
import { validationMessages } from "./helper/constants";
import { Paths } from "../../../Router";
import styles from "./styles";
import theme from "../../../theme/theme";

interface UserLoginProps {
  Login: any;
  onSubmitClicked?: any;
}

function UserLogin(props: UserLoginProps) {
  const myClasses = styles();
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const history = useHistory();

  const register = () => {
    history.push("/register");
  };

  const handleEmailChange = (e: any) => {
    let value = e.target?.value;
    const validation = checkEmail(value);
    if (validation.isInvalid) {
      setIsEmailInvalid(validation.isInvalid);
      setEmailErrorMessage(validation.validationError);
      setEmail(value);
    } else {
      setIsEmailInvalid(false);
      setEmailErrorMessage("");
      setEmail(value);
    }
  };

  const handlePasswordChange = (e: any) => {
    let value = e.target?.value;
    const validation = nullCheck(value, validationMessages.password);
    if (validation.isInvalid) {
      setIsPasswordInvalid(validation.isInvalid);
      setPasswordErrorMessage(validation.validationError);
      setPassword(value);
    } else {
      setIsPasswordInvalid(false);
      setPasswordErrorMessage("");
      setPassword(value);
    }
  };
  const submit = async (e: any) => {
    e.preventDefault();
    const loginUser = { email, password };
    props.Login(loginUser);
    if (history.location.pathname === Paths.rental) {
      props.onSubmitClicked(true);
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={myClasses.paper}>
        <Avatar className={myClasses.avatar}>
          <Person />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form
          className={myClasses.form}
          autoComplete="off"
          noValidate
          onSubmit={submit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="text"
            id="email-address"
            label="Email Address"
            autoFocus
            onBlur={(e: any) => handleEmailChange(e)}
            onChange={(e: any) => handleEmailChange(e)}
            error={isEmailInvalid}
            helperText={emailErrorMessage}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onBlur={(e: any) => handlePasswordChange(e)}
            onChange={(e: any) => handlePasswordChange(e)}
            error={isPasswordInvalid}
            helperText={passwordErrorMessage}
          />
          <Button
            startIcon={<LockOpen />}
            type="submit"
            className={myClasses.submit}
            fullWidth
            variant="contained"
            style={{
              marginTop: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
            }}
            size={"large"}
          >
            Login
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                onClick={() => history.push(Paths.forgot)}
                style={{ cursor: "pointer" }}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                style={{ cursor: "pointer" }}
                onClick={register}
                variant="body2"
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
}
const mapStateToProps = (props) => ({
  user: props.user.user,
});

export default connect(mapStateToProps, { Login })(UserLogin);
