import { Box, Button, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router";
import { Paths } from "../../../Router";
import House from "../../entirehouse/components/House";
import styles from "../styles";
import SKELETONMedia from "../../../../ui-kit/components/SKELETON-media";
import theme from "../../../theme/theme";

interface AvailableHousesProps {
  houses: any;
}

const AvailableHouses = (props: AvailableHousesProps) => {
  const classes = styles();
  const history = useHistory();
  return (
    <>
      <Typography
        className={classes.typograph}
        component="h5"
        variant="subtitle1"
      >
        available <span>houses</span>
      </Typography>
      <Grid container spacing={2}>
        {props.houses.length !== 0
          ? props.houses.map((house, idx) => (
              <Grid key={idx} item xs={12} sm={6} md={3} lg={3}>
                <House
                  type={house.property_type}
                  title={house.property_name}
                  subheader={house.property_partial_location}
                  thumbnail={house.thumbnail}
                  images={house.property_images}
                  imageTitle={house.image_title}
                  beds={house.property_beds}
                  baths={house.property_baths}
                  price={house.property_price_per_night}
                  available={house.property_status}
                  _id={house._id}
                />
              </Grid>
            ))
          : [...Array(4)].map((e, i) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={i}>
                <SKELETONMedia loading />
              </Grid>
            ))}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              className={classes.showMore}
              onClick={() => history.push(Paths.rentals)}
              variant="contained"
              style={{ backgroundColor: theme.palette.secondary.main }}
            >
              Show more
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AvailableHouses;
