import { Icon, IconButton, Snackbar, SnackbarOrigin } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackBar } from "../_actions/snackBar.actions";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

export interface State extends SnackbarOrigin {
  open: boolean;
}

export default function SuccessSnackBar() {
  const dispatch = useDispatch();

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { successSnackbarMessage, successSnackbarOpen } = useSelector(
    (state: any) => state.snackbar
  );

  const handleClose = () => {
    dispatch(removeSnackBar());
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        aria-describedby="client-snackbar"
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <Icon>close</Icon>
          </IconButton>,
        ]}
      >
        <Alert onClose={handleClose} severity={successSnackbarMessage?.type}>
          {successSnackbarMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
