import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(12),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 3,
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.2)",
    borderRadius: 3,
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    color: `${theme.palette.common.white} !important`,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    marginBottom: theme.spacing(2),
    borderRadius: 3,
  },
  typograph: {
    color: `${theme.palette.info.main} !important`,
    letterSpacing: 0.8,
    textTransform: "uppercase",
    fontWeight: `600 !important`,
    marginBottom: theme.spacing(1),
    "& span": {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  shareIcon: {
    textAlign: "right"
  }
}));
