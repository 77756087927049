import { connect } from "react-redux";
import { Route } from "react-router";
import Footer from "../../app/common/Footer";
import HeaderNav from "../../app/common/header/HeaderNav";

const PublicRouteComponent = ({
  component: Component,
  authentication,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <>
        <HeaderNav>
          <Component {...props} />
        </HeaderNav>
        <Footer />
      </>
    )}
  />
);

const mapStateToProps = (state, ownProps) => ({});
const PublicRoute = connect(mapStateToProps)(PublicRouteComponent);

export default PublicRoute;
