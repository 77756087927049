import { CardMedia, Typography } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/system";
import React from "react";
import KEEWISTDialog from "./KEEWIST-dailog";
import theme from "../../app/theme/theme";
import ReactHtmlParser from "react-html-parser";

interface KEEWISTTabProps {
  tab?: any;
  key?: any;
}

const KEEWISTTab = (props: KEEWISTTabProps) => {
  const [open, setOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);

  const handleImageClick = (item: any) => {
    if (item.places.length !== 1) {
      setOpen(!open);
      setImages(item.places);
    }
  };

  const closeImageModel = () => {
    setOpen(false);
  };

  const imageList = (
    <Box
      sx={{
        display: "block",
        margin: "0 auto",
        maxWidth: 600,
        width: "100%",
        overflowY: "auto",
      }}
    >
      {images?.map((image, key) => (
        <img
          style={{ maxWidth: 600, width: "100%" }}
          key={key}
          src={`${image?.filepath}`}
          srcSet={`${image?.filepath}`}
          alt={image.filename}
          loading="lazy"
        />
      ))}
    </Box>
  );
  return (
    <TabPanel
      value={props.tab?.value}
      key={props.tab?.value}
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      {props.tab?.content?.map((item, key) => (
        <Box key={key}>
          <Typography
            style={{
              color: theme.palette.info.main,
              letterSpacing: 0.8,
              textTransform: "uppercase",
              fontWeight: 600,
              marginBottom: theme.spacing(1),
            }}
            component="h5"
            variant="subtitle1"
          >
            {item?.title}
            <span
              style={{
                color: theme.palette.secondary.main,
                paddingLeft: theme.spacing(0.5),
                fontSize: 10,
              }}
            >
              {item?.labelType}
            </span>
          </Typography>
          <Box
            key={key}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              marginBottom: 3,
              marginTop: 1,
              textAlign: "justify",
            }}
          >
            <CardMedia
              style={{
                cursor: "pointer",
                width: "100%",
                paddingRight: 10,
                borderRadius: 4,
                marginBottom: 2,
              }}
              component="img"
              height="100%"
              image={item?.places[0]?.filepath}
              alt={item?.places[0]?.filename}
              onClick={(e) => handleImageClick(item)}
            />
            {ReactHtmlParser(item.description)}
          </Box>
        </Box>
      ))}
      <KEEWISTDialog
        isOpen={open}
        handleClose={closeImageModel}
        title={""}
        content={imageList}
        actionButton={false}
        maxWidth="md"
        fullWidth={true}
      />
    </TabPanel>
  );
};

export default KEEWISTTab;
