import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import KWCopyright from "../../ui-kit/components/KEEWIST-copyright";
import { Paths } from "../Router";
import theme from "../theme/theme";
import styles from "./styles";

export default function Footer() {
  const classes = styles();
  const houses = useSelector((state: any) => state.houses);
  const history = useHistory();

  return (
    <>
      <Box mt={3} className={classes.root}>
        <Container>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography component="h4" variant="subtitle1">
                Company
              </Typography>
              <List dense={true}>
                <ListItem
                  disableGutters
                  onClick={() => history.push(Paths.home)}
                >
                  <ListItemText className={classes.list} primary="Home" />
                </ListItem>
                <ListItem
                  disableGutters
                  onClick={() => history.push(Paths.news)}
                >
                  <ListItemText className={classes.list} primary="News" />
                </ListItem>
                <ListItem
                  disableGutters
                  onClick={() => history.push(Paths.rentals)}
                >
                  <ListItemText className={classes.list} primary="Listing" />
                </ListItem>
                <ListItem
                  disableGutters
                  onClick={() => history.push(Paths.thingstodo)}
                >
                  <ListItemText className={classes.list} primary="Activities" />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <Typography component="h4" variant="subtitle1">
                Listing
              </Typography>
              <List dense={true}>
                {houses.length !== 0 &&
                  houses.map((data: any, i) => (
                    <ListItem disableGutters key={i}>
                      <ListItemText
                        className={classes.list}
                        primary={data.property_name}
                      />
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item>
              <Typography component="h4" variant="subtitle1">
                Policies
              </Typography>
              <List dense={true}>
                <ListItem disableGutters button>
                  <ListItemText
                    className={classes.list}
                    primary="COVID-19 Response"
                  />
                </ListItem>
                <ListItem disableGutters button>
                  <ListItemText
                    className={classes.list}
                    primary="Terms of Services"
                  />
                </ListItem>
                <ListItem disableGutters button>
                  <ListItemText
                    className={classes.list}
                    primary="Cancellation Policy"
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          bgcolor: theme.palette.info.dark,
        }}
      >
        <KWCopyright
          site_name={"Keeble and Chien Enterprises LLC "}
          site_url={"/"}
        />
      </Box>
    </>
  );
}
