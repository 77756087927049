export const validationMessages = {
  houseName: "House name is required.",
  houseType: "House type is required.",
  houseDescription: "House description is required.",
  houseBed: "Total bed is required.",
  houseBath: "Total bath is required.",
  guestLimit: "Guest limit is required.",
  price: "Per night price is required.",
  address: "Address is required.",
  city: "City is required.",
  state: "State is required.",
  zipCode1: "Zip code is required.",
  zipCode2: "Invalid zip code.",
  zipCode3: "Lenght must be 5 digits",
};
