export interface EntireHouseState {
  id: number;
  property_name: string;
  property_description: string;
  thumbnail: string;
  subheader: string;
  property_type: string;
  continue_as_guest: boolean;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number: string;
  isFirstNameValid: boolean;
  isLastNameValid: boolean;
  isEmailAddressValid: boolean;
  isPhoneNumberValid: boolean;
  fnErrorMessage: string;
  lnErrorMessage: string;
  emailErrorMessage: string;
  phoneErrorMessage: string;
  property_images: [
    {
      filepath: string;
      filename: string;
    }
  ];
  amenities: [];
  things_to_know: {
    house_rules: [];
  };
  geo_location: [
    {
      latitude: number;
      longitude: number;
      zoom: number;
    }
  ];

  self_checkin: false;
  enhanced_clean_description: string;
  enhanced_clean: [
    {
      type: string;
      description: string;
    }
  ];
  image_title: string;
  property_guest_limit: number;
  property_beds: number;
  property_baths: number;
  property_price_per_night: number;
  property_from: string;
  property_to: string;
  totalPrice: number;
  totalPriceWiithoutFormat: number;
  totalNightPrice: number;
  booking: [{ startDate: any; endDate: any; key: string }];
  booked: [{ startDate: any; endDate: any; key: string }];
  isBookingSelected: boolean;
  isOpenLoginForm: boolean;
  isUserLoggedIn: boolean;
  openEditDate: boolean;
  openEditGuest: boolean;
  differenceInDays: number;
  cleaningFee: number;
  weeklyDiscount: number;
  commonDiscount: number;
  monthlyDiscount: number;
  serviceFee: number;
  open: boolean;
  openCalendar: boolean;
  expand: boolean;
  adults: number;
  children: number;
  infants: number;
  cancellationPolicy: [];
  loading: boolean;
  offerings: any[];
  offers: {
    bathtub: boolean;
    hairDryer: boolean;
    cleaningProducts: boolean;
    shampoo: boolean;
    conditioner: boolean;
    bodySoap: boolean;
    hotWater: boolean;
    showerGel: boolean;
    // Bedroom and laundry
    freeWasherInBuilding: boolean;
    freeDryerInBuilding: boolean;
    essentials: boolean;
    hangers: boolean;
    bedLinens: boolean;
    extraPillowsAndBlankets: boolean;
    iron: boolean;
    clothingStorage: boolean;
    //Entertaiment
    enthernetConnection: boolean;
    smartTv: boolean;
    // Family
    Crib: boolean;
    PackNPlay: boolean;
    highChair: boolean;
    babyBath: boolean;
    childrensDinnerware: boolean;
    boardGames: boolean;
    babySafetyGates: boolean;
    // Heating and cooling
    centralAirConditioning: boolean;
    ceilingFan: boolean;
    portableFans: boolean;
    heating: boolean;
    //Home safety
    securityCamerasOnProperty: boolean;
    smokeAlarm: boolean;
    carbonMonoxideAlarm: boolean;
    fireExtinguisher: boolean;
    firstAidKit: boolean;
    // Internet and office
    wifi: boolean;
    dedicatedWorkspace: boolean;
    // Kitchen and dining
    kitchen: boolean;
    refrigerator: boolean;
    microwave: boolean;
    cookingBasics: boolean;
    dishesAndSilverware: boolean;
    freezer: boolean;
    dishwasher: boolean;
    stainlessSteelElectricStove: boolean;
    oven: boolean;
    hotWaterKettle: boolean;
    coffeeMaker: boolean;
    wineGlasses: boolean;
    toaster: boolean;
    bakingSheet: boolean;
    blender: boolean;
    barbecueUtensils: boolean;
    diningTable: boolean;
    coffee: boolean;
    // Location features
    privateEntrance: boolean;
    // Outdoor
    sharedPatioOrBalcony: boolean;
    privateBackyardNotFullyFenced: boolean;
    outdoorFurniture: boolean;
    outdoorDiningArea: boolean;
    bbqGrill: boolean;
    // Parking and facilities
    freeParkingOnPremises: boolean;
    freeStreetParking: boolean;
    // Services
    luggageDropoffAllowed: boolean;
    longTermStaysAllowed: boolean;
    smartLock: boolean;
    cleaningAvailableDuringStay: boolean;
  };
  openAmenities: boolean;
  fullRefund: number;
  halfFefund: number;
  noRefund: number;
  openPolicyDetail: boolean;
}

export const entireHouseStateDefault: EntireHouseState = {
  id: 0,
  property_name: "",
  property_description: "",
  thumbnail: "",
  subheader: "",
  property_type: "",
  continue_as_guest: false,
  first_name: "",
  last_name: "",
  email_address: "",
  phone_number: "",
  isFirstNameValid: false,
  isLastNameValid: false,
  isEmailAddressValid: false,
  isPhoneNumberValid: false,
  fnErrorMessage: "",
  lnErrorMessage: "",
  emailErrorMessage: "",
  phoneErrorMessage: "",
  property_images: [
    {
      filepath: "",
      filename: "",
    },
  ],
  amenities: [],
  things_to_know: {
    house_rules: [],
  },
  geo_location: [
    {
      latitude: 0,
      longitude: 0,
      zoom: 0,
    },
  ],

  self_checkin: false,
  enhanced_clean_description: "",
  enhanced_clean: [
    {
      type: "",
      description: "",
    },
  ],
  image_title: "",
  property_guest_limit: 0,
  property_beds: 0,
  property_baths: 0,
  property_price_per_night: 0,
  property_from: "",
  property_to: "",
  totalPrice: 0,
  totalPriceWiithoutFormat: 0,
  totalNightPrice: 0,
  booking: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ],

  booked: [{ startDate: "", endDate: "", key: "selection" }],
  isBookingSelected: false,
  isOpenLoginForm: false,
  isUserLoggedIn: false,
  openEditDate: false,
  openEditGuest: false,
  differenceInDays: 0,
  cancellationPolicy: [],
  cleaningFee: 0,
  weeklyDiscount: 0,
  commonDiscount: 0,
  monthlyDiscount: 0,
  serviceFee: 0,
  open: false,
  openCalendar: false,
  expand: false,
  adults: 0,
  children: 0,
  infants: 0,
  loading: false,
  offerings: [],
  offers: {
    // Offerings
    // Bathroom
    bathtub: false,
    hairDryer: false,
    cleaningProducts: false,
    shampoo: false,
    conditioner: false,
    bodySoap: false,
    hotWater: false,
    showerGel: false,
    // Bedroom and laundry
    freeWasherInBuilding: false,
    freeDryerInBuilding: false,
    essentials: false,
    hangers: false,
    bedLinens: false,
    extraPillowsAndBlankets: false,
    iron: false,
    clothingStorage: false,
    //Entertaiment
    enthernetConnection: false,
    smartTv: false,
    // Family
    Crib: false,
    PackNPlay: false,
    highChair: false,
    babyBath: false,
    childrensDinnerware: false,
    boardGames: false,
    babySafetyGates: false,
    // Heating and cooling
    centralAirConditioning: false,
    ceilingFan: false,
    portableFans: false,
    heating: false,
    //Home safety
    securityCamerasOnProperty: false,
    smokeAlarm: false,
    carbonMonoxideAlarm: false,
    fireExtinguisher: false,
    firstAidKit: false,
    // Internet and office
    wifi: false,
    dedicatedWorkspace: false,
    // Kitchen and dining
    kitchen: false,
    refrigerator: false,
    microwave: false,
    cookingBasics: false,
    dishesAndSilverware: false,
    freezer: false,
    dishwasher: false,
    stainlessSteelElectricStove: false,
    oven: false,
    hotWaterKettle: false,
    coffeeMaker: false,
    wineGlasses: false,
    toaster: false,
    bakingSheet: false,
    blender: false,
    barbecueUtensils: false,
    diningTable: false,
    coffee: false,
    // Location features
    privateEntrance: false,
    // Outdoor
    sharedPatioOrBalcony: false,
    privateBackyardNotFullyFenced: false,
    outdoorFurniture: false,
    outdoorDiningArea: false,
    bbqGrill: false,
    // Parking and facilities
    freeParkingOnPremises: false,
    freeStreetParking: false,
    // Services
    luggageDropoffAllowed: false,
    longTermStaysAllowed: false,
    smartLock: false,
    cleaningAvailableDuringStay: false,
  },
  openAmenities: false,
  fullRefund: 0,
  halfFefund: 0,
  noRefund: 0,
  openPolicyDetail: false,
};
