import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

export default createTheme({
  //   orange: "#FB8060",
  //   navy: "#0D2137",
  //   blue: "#2E77AE",
  //   gray: "#E0EAF5",
  //   white: "#fff",
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#0D2137",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //   light: "#0066ff",
      main: "#FB8060",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    error: {
      //   light: "#0066ff",
      main: red[600],
      dark: red[700],
      contrastText: "#fff",
    },
    info: {
      main: "#2E77AE",
    },
    neutral: {
      main: "#878787",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },

  overrides: {
    MuiPaper: {
      outlined: {
        border: "1px solid #EBEBEB",
        borderRadius: 4,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 5,
        padding: ".5em 4em",
      },
      label: {
        textTransform: "capitalize",
        fontSize: "1.2em",
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: 5,
      },
    },
    MuiInput: {
      root: {
        border: "none",
      },
      underline: {
        "&::before": {
          borderBottom: "2px solid #2E77AE",
        },
        "&::after": {
          borderBottom: "2px solid #0D2137",
        },
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: "#E0EAF5",
        "&:hover": {
          backgroundColor: "#D8E2ED",
        },
        "&$focused": {
          backgroundColor: "#E0EAF5",
          borderWidth: "2px",
        },
      },
    },
    MuiTextField: {
      root: {
        marginTop: 10,
        marginBottom: 4,
      },
    },
  },
});
