import {
  ADD_SNACKBAR,
  REMOVE_SNACKBAR,
} from "../_constants/snackBar.constants";
export const addSnackBar = (message) => {
  return (dispatch) => {
    dispatch({ type: ADD_SNACKBAR, payload: message });
  };
};

export function removeSnackBar() {
  return (dispatch) => {
    dispatch({ type: REMOVE_SNACKBAR });
  };
}
