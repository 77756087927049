export interface EditUserState {
  userId: string;
  email: string;
  username: string;
  disabled: boolean;
  isEmailChanged: boolean;
  isUsernameChanged: boolean;
  isUserRoleChanged: boolean;
  focused: boolean;
  invalidEmail: boolean;
  invalidUsername: boolean;
  invalidEmailMessage: string;
  invalidUsernameMessage: string;
  role: string;
  isRoleInvalid: boolean;
  roleErrorMessage: boolean;
  errors: any[];
  clonedUser: {
    email: string;
    username: string;
    role: string;
  };
}

export const editUserStateDefault: EditUserState = {
  userId: "",
  email: "",
  username: "",
  disabled: true,
  isEmailChanged: false,
  isUsernameChanged: false,
  isUserRoleChanged: false,
  focused: false,
  invalidEmail: false,
  invalidUsername: false,
  invalidEmailMessage: "",
  invalidUsernameMessage: "",
  role: "",
  isRoleInvalid: false,
  roleErrorMessage: false,
  errors: [],
  clonedUser: {
    email: "",
    username: "",
    role: "",
  },
};
