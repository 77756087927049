import { Button, Divider, TextField } from "@material-ui/core";
import { Box, FormHelperText, Typography } from "@mui/material";
import React, { useCallback, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Geocoder from "react-map-gl-geocoder";
import MapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import KEEWISTEditor from "../../../../../../ui-kit/components/KEEWIST-editor";
import { Paths } from "../../../../../Router";
import theme from "../../../../../theme/theme";
import { ThingsToDoStateContext } from "../../ThingsToDoContext";
import styles from "./styles";
import KEEWISTFormDialog from "../KEEWIST-dailog";
import { nullCheck } from "../../../../../../ui-kit/common/validation/KeewistValidations";
import { validationMessages } from "../../helper/constants";
import { TodosApiService } from "../../../../../../api-kit/todos/todos-api.services";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import KEEWISTFileUpload from "../../../../../../ui-kit/components/KEEWIST-fileUpload";
import { UploadApiService } from "../../../../../../api-kit/upload/upload-api.services";

export default function AddLabelContent() {
  const uploadApiService = new UploadApiService();
  const todosApiService = new TodosApiService();
  const { thingsToDoState, updateThingsToDoState } = useContext(
    ThingsToDoStateContext
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const classes = styles();

  const handleViewportChange = useCallback(
    (newViewport) =>
      updateThingsToDoState({
        geo_location: {
          latitude: newViewport.latitude,
          longitude: newViewport.longitude,
          zoom: newViewport.zoom,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleConfirm = () => {
    if (
      thingsToDoState.label !== "" &&
      thingsToDoState.title !== "" &&
      thingsToDoState.description !== "" &&
      thingsToDoState.isGeoLocationAdded
    ) {
      
      const ids = thingsToDoState.toDoImages.map(function (id) {
        return id.ids;
      });
      const newLabelPlace = {
        title: thingsToDoState.title,
        labelType: thingsToDoState.newLabel.toLowerCase(),
        description: thingsToDoState.description,
        geo_location: thingsToDoState.geo_location,
        places: ids,
      };

      var joined = thingsToDoState.content.concat(newLabelPlace);

      updateThingsToDoState({ content: joined, open: !thingsToDoState.open });
    }
  };

  const handleCloseDialog = () => {
    updateThingsToDoState({ open: false });
  };

  const handleSubmitRequest = () => {
    const newlabel = {
      label: thingsToDoState.newLabel,
      disabled: false,
      content: thingsToDoState.content,
    };
    todosApiService
      .createTodo(newlabel)
      .then((res) => {
        dispatch(
          addSnackBar({
            message: "New activity successfully added.",
            type: "success",
          })
        );
        updateThingsToDoState({
          addMoreLabelPlace: false,
          open: false,
          title: "",
          description: "",
          isGeoLocationAdded: false,
          geo_location: {
            latitude: 37.6922361,
            longitude: -97.3375448,
            zoom: 10,
          },
          newLabel: "",
          label: "",
        });
      })
      .catch((err) => {
        dispatch(
          addSnackBar({
            message: "Unable to add new activity.",
            type: "error",
          })
        );
      });
  };

  const handleAddMore = () => {
    updateThingsToDoState({
      addMoreLabelPlace: true,
      open: false,
      title: "",
      description: "",
      isGeoLocationAdded: false,
      geo_location: {
        latitude: 37.6922361,
        longitude: -97.3375448,
        zoom: 10,
      },
    });
  };

  const handleTitle = (title) => {
    const validation = nullCheck(title, validationMessages.addTitle);
    if (validation.isInvalid) {
      updateThingsToDoState({
        title: title,
        isTitleInvalid: validation.isInvalid,
        titleErrorMessage: validation.validationError,
      });
    } else {
      updateThingsToDoState({
        title: title,
        isTitleInvalid: false,
        titleErrorMessage: "",
      });
    }
  };

  const handleImageUpload = (e, files) => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(i.toString(), files[i]);
    }
    formData.append("length", files.length);
    updateThingsToDoState({ disableBtn: true });
    uploadApiService
      .upload(formData)
      .then((res) => {
        const joined = thingsToDoState.toDoImages.concat(res.data);
        updateThingsToDoState({ toDoImages: joined, disableBtn: false });
        dispatch(
          addSnackBar({
            message: "Image uploaded.",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to upload image.",
            type: "error",
          })
        )
      );

    const joined = thingsToDoState.toDoImages.concat(formData);
    updateThingsToDoState({ toDoImages: joined });
  };

  const confirmation = (
    <>
      <Typography
        variant="subtitle1"
        align="left"
        gutterBottom
        color="textSecondary"
        // style={{ textTransform: "uppercase", fontSize: "1.5em" }}
      >
        <strong>Add more</strong> - if you want to add more places to your
        current label you can select "Add more".
      </Typography>
      <Typography
        variant="subtitle1"
        align="left"
        gutterBottom
        color="textSecondary"
        // style={{ textTransform: "uppercase", fontSize: "1.5em" }}
      >
        <strong>Submit</strong> - if you want to submit the current label and
        add new label, click "Submit".
      </Typography>
    </>
  );

  return (
    <>
      <TextField
        variant="outlined"
        fullWidth
        name="todo-title"
        label="Add name"
        type="text"
        id="todo-title"
        onBlur={(e) => handleTitle(e.target?.value)}
        onChange={(e) => handleTitle(e.target?.value)}
        value={thingsToDoState.title !== "" ? thingsToDoState.title : ""}
        error={thingsToDoState.isTitleInvalid}
        helperText={thingsToDoState.titleErrorMessage}
      />
      <FormHelperText className={classes.helperText}>
        Name of your label(ex: if your label is a restaurant, then add
        restaurant name here)
      </FormHelperText>
      <div className={classes.editor}>
        <KEEWISTEditor
          data={thingsToDoState.description}
          onChange={(e: any) => updateThingsToDoState({ description: e })}
          onBlur={(e: any) => updateThingsToDoState({ description: e })}
        />
      </div>
      <FormHelperText>Add description to your label.</FormHelperText>
      <Divider
        style={{
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
      />
      <FormHelperText>
        Need your label address below to get a geographic coordinates. System
        will use these coordinates to put label on the map.
      </FormHelperText>
      <div style={{ height: "20vh" }}>
        <MapGL
          ref={mapRef}
          {...thingsToDoState.geo_location}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          <Geocoder
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={(e) =>
              updateThingsToDoState({
                isGeoLocationAdded: true,
                geo_location: {
                  latitude: e.latitude,
                  longitude: e.longitude,
                  zoom: e.zoom,
                },
              })
            }
            position="top-left"
          />
        </MapGL>
      </div>
      {!thingsToDoState.isGeoLocationAdded && (
        <FormHelperText style={{ color: "#e53934" }}>
          Location is not added! Please make sure to click on the address in
          dropdown. This error message will disappear if you pick the locaion.
        </FormHelperText>
      )}
      <Box sx={{ mt: theme.spacing(0.1) }}>
        <KEEWISTFileUpload handleImageUpload={handleImageUpload} />
      </Box>
      <Button
        onClick={() => history.push(Paths.thingsToDoList)}
        className={classes.btn}
        style={{ marginRight: theme.spacing(1) }}
        variant="contained"
        size={"small"}
      >
        Back
      </Button>
      <Button
        type="button"
        onClick={handleConfirm}
        className={classes.btn}
        variant="contained"
        size={"small"}
        style={{ backgroundColor: theme.palette.secondary.main }}
      >
        Confirm
      </Button>
      <KEEWISTFormDialog
        warningQuestion={"What would you like to do?"}
        content={confirmation}
        isOpen={thingsToDoState.open}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmitRequest}
        handleAddmore={handleAddMore}
        addMore={true}
      />
    </>
  );
}
