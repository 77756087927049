import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  content: {
    maxWidth: 800,
    width: "100%",
    display: "block",
  },
}));
