import EditIcon from "@mui/icons-material/Edit";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import theme from "../../../theme/theme";
import { EntireHouseStateContext } from "../EntireHouseContext";
import styles from "./form/styles";
import styles2 from "../styles";
import store from "../../../../_redux/_helpers/store";
import { PageHelper, Paths } from "../../../Router";
import KEEWISTDialog from "../../../../ui-kit/components/KEEWIST-dailog";
import KEEWISTDatepicker from "../../../../ui-kit/components/KEEWIST-datepicker";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ReactHtmlParser from "react-html-parser";
import { green } from "@mui/material/colors";
import { BookingApiService } from "../../../../api-kit/booking/booking-api.service";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import { useDispatch } from "react-redux";
import { validationMessages } from "../helper/constants";

export const RequestHouse = () => {
  const classes = styles();
  const classes2 = styles2();

  const bookingApiService = new BookingApiService();
  const { entireHouseState, updateEntireHouseState } = React.useContext(
    EntireHouseStateContext
  );

  const dispatch = useDispatch();
  const location: any = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
    const userInfo = store.getState().user;
    if (userInfo.loggedIn) {
      updateEntireHouseState({ isUserLoggedIn: true });
    }
    if (
      location.state !== null &&
      location.state?.houseDetails !== null &&
      location.state?.houseDetails !== undefined
    ) {
      updateEntireHouseState(location.state.houseDetails);
    }
    if (
      location.state !== null &&
      location.state?.cancellationPolicy !== null
    ) {
      updateEntireHouseState({
        cancellationPolicy: location.state?.cancellationPolicy,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  useEffect(() => {
    let discountedPrice = 0;
    if (
      entireHouseState.isBookingSelected &&
      entireHouseState.differenceInDays >= 7 &&
      entireHouseState.differenceInDays < 28
    ) {
      discountedPrice =
        entireHouseState.totalNightPrice - entireHouseState.weeklyDiscount;
    } else if (
      entireHouseState.isBookingSelected &&
      entireHouseState.differenceInDays >= 28
    ) {
      discountedPrice =
        entireHouseState.totalNightPrice - entireHouseState.monthlyDiscount;
    } else {
      discountedPrice = entireHouseState.totalNightPrice;
    }
    updateEntireHouseState({
      totalPrice:
        entireHouseState.totalNightPrice !== 0
          ? formatter.format(
              discountedPrice +
                entireHouseState.cleaningFee +
                entireHouseState.serviceFee
            )
          : formatter.format(0),
      totalPriceWiithoutFormat:
        entireHouseState.totalNightPrice !== 0 &&
        discountedPrice +
          entireHouseState.cleaningFee +
          entireHouseState.serviceFee,
    });
  }, [entireHouseState.totalNightPrice]);

  const handleEditGuest = () => {
    updateEntireHouseState({ openEditGuest: true });
  };

  const handleEditDate = () => {
    updateEntireHouseState({ openEditDate: true });
  };

  const handleCloseGuest = () => {
    updateEntireHouseState({ openEditGuest: false });
  };

  const handleCloseDate = () => {
    updateEntireHouseState({ openEditDate: false });
  };

  const handleAddAdult = () => {
    if (
      entireHouseState.adults + entireHouseState.children <=
      entireHouseState.property_guest_limit
    ) {
      updateEntireHouseState({ adults: entireHouseState.adults + 1 });
    }
  };

  const handleRemoveAdult = () => {
    if (entireHouseState.adults > 1) {
      updateEntireHouseState({ adults: entireHouseState.adults - 1 });
    }
  };

  const handleAddChildren = () => {
    if (
      entireHouseState.adults + entireHouseState.children <=
      entireHouseState.property_guest_limit
    ) {
      updateEntireHouseState({ children: entireHouseState.children + 1 });
    }
  };

  const handleRemoveChildren = () => {
    if (entireHouseState.children > 0) {
      updateEntireHouseState({ children: entireHouseState.children - 1 });
    }
  };

  const handleAddInfant = () => {
    if (entireHouseState.infants < 5) {
      updateEntireHouseState({ infants: entireHouseState.infants + 1 });
    }
  };

  const handleRemoveInfant = () => {
    if (entireHouseState.infants > 0) {
      updateEntireHouseState({ infants: entireHouseState.infants - 1 });
    }
  };

  const toggleExpand = () => {
    updateEntireHouseState({ expand: !entireHouseState.expand });
  };

  const handleOnChangeDate = (value: any) => {
    let dateRange = value[0];

    // To calculate the time difference of two dates
    var Difference_In_Time =
      dateRange.endDate.getTime() - dateRange.startDate.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.floor(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    updateEntireHouseState({
      totalNightPrice:
        entireHouseState.property_price_per_night * Difference_In_Days,
      differenceInDays: Difference_In_Days,
      booking: [dateRange],
    });
  };

  const handleRequestBook = () => {
    const userInfo = store.getState().user;
    // create object for booking
    const bookingObject = {
      userId: userInfo.loggedIn ? userInfo.user?.id : "",
      userEmail: userInfo.loggedIn
        ? userInfo.user?.email
        : entireHouseState.email_address,
      userName: userInfo.loggedIn
        ? userInfo.user?.username
        : entireHouseState.first_name + " " + entireHouseState.last_name,
      userPhoneNumber: userInfo.loggedIn ? "" : entireHouseState.phone_number,
      property_name: entireHouseState.property_name,
      booked_from: entireHouseState.booking[0].startDate,
      booked_to: entireHouseState.booking[0].endDate,
      guest: entireHouseState.adults + entireHouseState.children,
      infant: entireHouseState.infants,
      total_nights: entireHouseState.differenceInDays,
      total_price: entireHouseState.totalPriceWiithoutFormat,
      total_price_night: entireHouseState.totalNightPrice,
      weekly_discount: entireHouseState.weeklyDiscount,
      cleaningFee: entireHouseState.cleaningFee,
      service_fee: entireHouseState.serviceFee,
    };
    if (!entireHouseState.isUserLoggedIn) {
      if (
        entireHouseState.first_name !== "" &&
        entireHouseState.last_name !== "" &&
        entireHouseState.email_address !== "" &&
        entireHouseState.phone_number !== "" &&
        entireHouseState.adults !== 0
      ) {
        bookingApiService
          .addBooking(bookingObject)
          .then((res) => {
            PageHelper.openPage(Paths.rentals);
            dispatch(
              addSnackBar({
                message: validationMessages.bookingSuccess,
                type: "success",
              })
            );
          })
          .catch((err) =>
            dispatch(
              addSnackBar({
                message: validationMessages.bookingFailed,
                type: "error",
              })
            )
          );
      } else {
        dispatch(
          addSnackBar({
            message: validationMessages.adultsField,
            type: "error",
          })
        );
        updateEntireHouseState({
          isFirstNameValid: true,
          isLastNameValid: true,
          isEmailAddressValid: true,
          isPhoneNumberValid: true,
          fnErrorMessage: validationMessages.firstname,
          lnErrorMessage: validationMessages.lastname,
          emailErrorMessage: validationMessages.email,
          phoneErrorMessage: validationMessages.phonenumber,
        });
      }
    } else {
      if (entireHouseState.adults !== 0) {
        bookingApiService
          .addBooking(bookingObject)
          .then((res) => {
            PageHelper.openPage(Paths.rentals);
            dispatch(
              addSnackBar({
                message: validationMessages.bookingSuccess,
                type: "success",
              })
            );
          })
          .catch((err) =>
            dispatch(
              addSnackBar({
                message: validationMessages.bookingFailed,
                type: "error",
              })
            )
          );
      } else {
        dispatch(
          addSnackBar({
            message: validationMessages.adultsField,
            type: "error",
          })
        );
      }
    }
  };

  const datePickerContent = (
    <KEEWISTDatepicker
      onChange={handleOnChangeDate}
      booking={entireHouseState.booking}
      booked={entireHouseState.booked}
      position={"center"}
    />
  );

  const expended = (
    <>
      <div className={classes.guestLimit}>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Adults
          </Typography>
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleAddAdult}>
              <AddIcon fontSize="small" color="primary" />
            </Button>
            <Button>{entireHouseState.adults}</Button>
            <Button onClick={handleRemoveAdult}>
              <RemoveIcon fontSize="small" color="primary" />
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Children
          </Typography>
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleAddChildren}>
              <AddIcon fontSize="small" color="primary" />
            </Button>
            <Button>{entireHouseState.children}</Button>
            <Button onClick={handleRemoveChildren}>
              <RemoveIcon fontSize="small" color="primary" />
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Infants
          </Typography>
          <ButtonGroup
            size="small"
            variant="text"
            aria-label="text button group"
          >
            <Button onClick={handleAddInfant}>
              <AddIcon fontSize="small" color="primary" />
            </Button>
            <Button>{entireHouseState.infants}</Button>
            <Button onClick={handleRemoveInfant}>
              <RemoveIcon fontSize="small" color="primary" />
            </Button>
          </ButtonGroup>
        </div>
        <div className={classes.guestLimitContent}>
          <Typography component="p" variant="body1">
            Pets
          </Typography>
          <Typography
            component="p"
            variant="body2"
            style={{
              color: theme.palette.info.light,
              fontSize: 12,
              paddingTop: theme.spacing(0.8),
            }}
          >
            Pets aren't allowed in this house.
          </Typography>
        </div>
      </div>
    </>
  );

  const editGuestContent = (
    <>
      <div className={classes.datePicker} onClick={toggleExpand}>
        <div className="guests">
          <Typography
            component="h5"
            variant="h5"
            style={{ fontSize: 11, color: theme.palette.primary.main }}
          >
            Guests
          </Typography>
          <Typography
            component="h5"
            variant="subtitle1"
            style={{ fontSize: 10, color: theme.palette.grey[700] }}
          >
            {entireHouseState.adults + entireHouseState.children === 1 ? (
              <span>
                {entireHouseState.adults + entireHouseState.children} guest
              </span>
            ) : (
              <span>
                {entireHouseState.adults + entireHouseState.children} guests
              </span>
            )}
            {entireHouseState.infants > 0 && (
              <span>
                ,{" "}
                {entireHouseState.infants === 1 ? (
                  <>{entireHouseState.infants} infant</>
                ) : (
                  <>{entireHouseState.infants} infants</>
                )}
              </span>
            )}
          </Typography>
        </div>
        <div className="expand">
          <IconButton>
            {entireHouseState.expand ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </div>
      </div>
      {entireHouseState.expand && expended}
    </>
  );

  const bookingFormContent = (
    <form className={classes.form} noValidate autoComplete="off">
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="first-name"
        label="Firstname"
        autoFocus
        onBlur={(e: any) =>
          updateEntireHouseState({
            first_name: e.target?.value,
            isFirstNameValid: e.target?.value !== "" ? false : true,
            fnErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.firstname,
          })
        }
        onChange={(e: any) =>
          updateEntireHouseState({
            first_name: e.target?.value,
            isFirstNameValid: e.target?.value !== "" ? false : true,
            fnErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.firstname,
          })
        }
        error={entireHouseState.isFirstNameValid}
        helperText={entireHouseState.fnErrorMessage}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="last-name"
        label="Lastname"
        onBlur={(e: any) =>
          updateEntireHouseState({
            last_name: e.target?.value,
            isLastNameValid: e.target?.value !== "" ? false : true,
            lnErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.lastname,
          })
        }
        onChange={(e: any) =>
          updateEntireHouseState({
            last_name: e.target?.value,
            isLastNameValid: e.target?.value !== "" ? false : true,
            lnErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.lastname,
          })
        }
        error={entireHouseState.isLastNameValid}
        helperText={entireHouseState.lnErrorMessage}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="email-address"
        label="Email Address"
        onBlur={(e: any) =>
          updateEntireHouseState({
            email_address: e.target?.value,
            isEmailAddressValid: e.target?.value !== "" ? false : true,
            emailErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.email,
          })
        }
        onChange={(e: any) =>
          updateEntireHouseState({
            email_address: e.target?.value,
            isEmailAddressValid: e.target?.value !== "" ? false : true,
            emailErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.email,
          })
        }
        error={entireHouseState.isEmailAddressValid}
        helperText={entireHouseState.emailErrorMessage}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="text"
        id="phone-number"
        label="Phone number"
        onBlur={(e: any) =>
          updateEntireHouseState({
            phone_number: e.target?.value,
            isPhoneNumberValid: e.target?.value !== "" ? false : true,
            phoneErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.phonenumber,
          })
        }
        onChange={(e: any) =>
          updateEntireHouseState({
            phone_number: e.target?.value,
            isPhoneNumberValid: e.target?.value !== "" ? false : true,
            phoneErrorMessage:
              e.target?.value !== "" ? "" : validationMessages.phonenumber,
          })
        }
        error={entireHouseState.isPhoneNumberValid}
        helperText={entireHouseState.phoneErrorMessage}
      />
    </form>
  );

  return (
    <Container>
      <Grid container spacing={4} mt={6} mb={8}>
        <Grid item md={12} sm={12} xs={12} className={classes2.btnGrid}>
          <Button
            variant="contained"
            size="small"
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(5),
              backgroundColor: theme.palette.secondary.main,
            }}
            startIcon={<ChevronLeftIcon />}
            component={Link}
            to={{
              pathname: Paths.rental,
              state: {
                houseId: entireHouseState.id,
              },
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes2.requestPageLeft}>
          {!entireHouseState.isUserLoggedIn && (
            <>
              <Typography
                className={classes2.typograph}
                component="h4"
                variant="subtitle1"
              >
                Your <span>details</span>
              </Typography>
              <div className={classes2.tripDetails}>{bookingFormContent}</div>
              <Divider
                light
                style={{
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
                }}
              />
            </>
          )}
          <Typography
            className={classes2.typograph}
            component="h4"
            variant="subtitle1"
          >
            Your <span>trip</span>
          </Typography>
          <div className={classes2.tripDetails}>
            <div>
              <Typography component="h4" variant="subtitle1">
                Dates
              </Typography>
              <Typography
                component="h5"
                variant="subtitle1"
                style={{ fontSize: 13, color: theme.palette.grey[700] }}
              >
                {new Date(entireHouseState.booking[0].startDate).toDateString()}{" "}
                - {new Date(entireHouseState.booking[0].endDate).toDateString()}
              </Typography>
              <Typography
                component="h5"
                variant="subtitle1"
                style={{ fontSize: 12, color: theme.palette.grey[700] }}
              >
                {entireHouseState.differenceInDays === 1 ? (
                  <span>
                    {entireHouseState.differenceInDays} night in{" "}
                    {entireHouseState.property_name}
                  </span>
                ) : (
                  <span>
                    {entireHouseState.differenceInDays} nights in{" "}
                    {entireHouseState.property_name}
                  </span>
                )}
              </Typography>
            </div>
            <div>
              <Button
                onClick={handleEditDate}
                variant="outlined"
                size="small"
                style={{
                  marginTop: theme.spacing(1),
                }}
              >
                <EditIcon fontSize="small" />
              </Button>
            </div>
          </div>
          <div className={classes2.tripDetails}>
            <div>
              <Typography component="h4" variant="subtitle1">
                Guests
              </Typography>
              <Typography
                component="h5"
                variant="subtitle1"
                style={{ fontSize: 12, color: theme.palette.grey[700] }}
              >
                {entireHouseState.adults + entireHouseState.children === 1 ? (
                  <span>
                    {entireHouseState.adults + entireHouseState.children} guest
                  </span>
                ) : (
                  <span>
                    {entireHouseState.adults + entireHouseState.children} guests
                  </span>
                )}
                {entireHouseState.infants > 0 && (
                  <span>
                    ,{" "}
                    {entireHouseState.infants === 1 ? (
                      <>{entireHouseState.infants} infant</>
                    ) : (
                      <>{entireHouseState.infants} infants</>
                    )}
                  </span>
                )}
              </Typography>
            </div>
            <div>
              <Button
                onClick={handleEditGuest}
                variant="outlined"
                size="small"
                style={{
                  marginTop: theme.spacing(1),
                }}
              >
                <EditIcon fontSize="small" />
              </Button>
            </div>
          </div>
          <Divider
            light
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
          />

          <Typography
            className={classes2.typograph}
            component="h4"
            variant="subtitle1"
          >
            Cancellation <span>policy</span>
          </Typography>
          <Typography
            component="h5"
            variant="body1"
            mt={1}
            style={{ color: theme.palette.grey[700], textAlign: "justify" }}
          >
            Only cleaning fee is refundable because check-in is less than 7 days
            away.
          </Typography>
          <Typography
            component="h5"
            variant="body1"
            style={{ color: theme.palette.grey[700], textAlign: "justify" }}
          >
            Our policy does not cover travel disruptions caused by COVID-19.
          </Typography>
          <Divider
            light
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
          />
          <div className={classes2.warnings}>
            <AnnouncementIcon
              fontSize="large"
              sx={{
                color: theme.palette.secondary.main,
              }}
            />
            <div>
              <Typography
                component="h5"
                variant="subtitle1"
                style={{ fontWeight: 600 }}
              >
                Your reservation won't be confirmed until the host accepts your
                request.
              </Typography>
              <Typography
                component="h5"
                variant="body1"
                style={{ color: theme.palette.grey[700] }}
              >
                You won't be charged until then.
              </Typography>
            </div>
          </div>
          <Divider
            light
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
          />
          <Typography
            component="h5"
            variant="subtitle1"
            style={{
              fontSize: 12,
              color: theme.palette.grey[700],
              textAlign: "justify",
            }}
          >
            By selecting the button below, I agree to the Host's House Rules,
            Airbnb's COVID-19 Safety Requirements and the Guest Refund Policy. I
            agree to pay the total amount shown if the host accepts my booking
            request.
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleRequestBook}
            disabled={
              entireHouseState.totalNightPrice !== 0 &&
              entireHouseState.totalPrice !== 0
                ? false
                : true
            }
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            Request to book
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className={classes2.map}
          sx={{
            top: 66,
            [theme.breakpoints.down("sm")]: {
              display: "block",
              position: "relative",
              top: 10,
              order: 1,
            },
          }}
        >
          <Paper className={classes2.requestPageRightPaper} variant="outlined">
            {entireHouseState.property_images.length > 0 && (
              <img
                key={"property-image"}
                className={classes.image}
                crossOrigin={"anonymous"}
                src={entireHouseState.property_images[0].filepath}
                alt={entireHouseState.property_images[0].filename}
              />
            )}
            <Typography component="h5" variant="subtitle1">
              {entireHouseState.property_name}
            </Typography>
            <Typography
              component="h5"
              variant="body2"
              style={{
                color: theme.palette.grey[700],
                paddingBottom: theme.spacing(1),
              }}
            >
              {entireHouseState.property_guest_limit !== undefined
                ? entireHouseState.property_guest_limit + " guests · "
                : ""}
              {entireHouseState.property_beds !== undefined
                ? entireHouseState.property_beds + " bedrooms · "
                : ""}
              {entireHouseState.property_baths !== undefined
                ? entireHouseState.property_baths + " bathrooms  · "
                : ""}
              {entireHouseState.property_type !== undefined
                ? entireHouseState.property_type
                : ""}
            </Typography>
            <div
              style={{ color: theme.palette.grey[700], textAlign: "justify" }}
            >
              {ReactHtmlParser(entireHouseState.property_description)}
            </div>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
            <Typography
              component="h5"
              variant="body1"
              style={{
                fontWeight: 600,
                fontSize: 18,
                paddingBottom: theme.spacing(1),
              }}
            >
              Price details
            </Typography>
            <div className={classes2.requestPageRight}>
              <div>
                <Typography component="h5" variant="body1">
                  ${entireHouseState.property_price_per_night} x{" "}
                  {entireHouseState.differenceInDays} nights
                </Typography>
              </div>
              <div>
                <Typography component="h5" variant="body1">
                  ${entireHouseState.totalNightPrice}
                </Typography>
              </div>
            </div>
            {/* <div className={classes2.requestPageRight}>
              <div>
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ color: green[800] }}
                >
                  Weekly discount
                </Typography>
              </div>
              <div>
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ color: green[800] }}
                >
                  -${entireHouseState.weeklyDiscount}
                </Typography>
              </div>
            </div> */}
            {entireHouseState.differenceInDays >= 7 &&
              entireHouseState.differenceInDays < 28 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: theme.spacing(0.1),
                  }}
                >
                  <Typography
                    component="h5"
                    variant="body1"
                    style={{ color: green[800] }}
                  >
                    Weekly discount
                  </Typography>
                  <Typography
                    component="h5"
                    variant="body1"
                    style={{ color: green[800] }}
                  >
                    {entireHouseState.cancellationPolicy.map((data: any) => {
                      return data.type === "weekly" && -data.discounted_price;
                    })}
                  </Typography>
                </Box>
              )}
            {entireHouseState.differenceInDays >= 28 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: theme.spacing(0.1),
                }}
              >
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ color: green[800] }}
                >
                  Monthly discount
                </Typography>
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ color: green[800] }}
                >
                  {entireHouseState.cancellationPolicy.map((data: any) => {
                    return data.type === "monthly" && -data.discounted_price;
                  })}
                </Typography>
              </Box>
            )}
            <div className={classes2.requestPageRight}>
              <div>
                <Typography component="h5" variant="body1">
                  Cleaning fee
                </Typography>
              </div>
              <div>
                <Typography component="h5" variant="body1">
                  ${entireHouseState.cleaningFee}
                </Typography>
              </div>
            </div>
            <div className={classes2.requestPageRight}>
              <div>
                <Typography component="h5" variant="body1">
                  Service fee
                </Typography>
              </div>
              <div>
                <Typography component="h5" variant="body1">
                  ${entireHouseState.serviceFee}
                </Typography>
              </div>
            </div>
            <Divider
              light
              style={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            />
            <div className={classes2.requestPageRight}>
              <div>
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ fontWeight: 600 }}
                >
                  Total before taxes
                </Typography>
              </div>
              <div>
                <Typography
                  component="h5"
                  variant="body1"
                  style={{ fontWeight: 600 }}
                >
                  {entireHouseState.totalPrice}
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <KEEWISTDialog
        isOpen={entireHouseState.openEditDate}
        handleClose={handleCloseDate}
        title={"Add dates"}
        content={datePickerContent}
        actionButton={true}
        maxWidth="xs"
        fullWidth={true}
        fullScreen={false}
        btnSize={"small"}
        btnLabelCancel="Close"
        btnLabelHide={true}
      />
      <KEEWISTDialog
        isOpen={entireHouseState.openEditGuest}
        handleClose={handleCloseGuest}
        content={editGuestContent}
        actionButton={false}
        maxWidth="xs"
        fullWidth={true}
        fullScreen={false}
        btnSize={"small"}
      />
    </Container>
  );
};
