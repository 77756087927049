import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UserApiService } from "../../../../api-kit/user/user-api.service";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import KEEWISTTable from "./components/UserTable";

const UserList = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const userApiService = new UserApiService();

  useEffect(() => {
    userApiService
      .getUsers()
      .then((user) => {
        setUsers(user.data);
      })
      .catch((error) => {
        dispatch(
          addSnackBar({
            message: "Unable to get all users.",
            type: "error",
          })
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    numeric: boolean;
  }

  const headCells: HeadCell[] = [
    {
      id: "user-name",
      numeric: false,
      disablePadding: true,
      label: "Username",
    },
    {
      id: "email-address",
      numeric: true,
      disablePadding: false,
      label: "Email address",
    },
    {
      id: "user-role",
      numeric: true,
      disablePadding: false,
      label: "Role/Permission",
    },
    {
      id: "user-since",
      numeric: true,
      disablePadding: false,
      label: "Created at",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ];

  const handleOnDelete = (id: string) => {
    userApiService
      .deleteUser(id)
      .then((res) => {
        let newUsers = users.filter((user) => user._id !== res.data._id);
        setUsers(newUsers);
        dispatch(
          addSnackBar({
            message: "User deleted successfully.",
            type: "success",
          })
        );
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to delete this user!",
            type: "error",
          })
        )
      );
  };

  return (
    <>
      <KEEWISTTable
        rows={users}
        headCells={headCells}
        handleOnDelete={handleOnDelete}
      />
    </>
  );
};

export default UserList;
