import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import theme from "../../app/theme/theme";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface KEEWISTDialogProps {
  isOpen: boolean;
  handleClose: any;
  handleConfirm?: any;
  handleReset?: Function;
  btnLabelResetHide?: boolean;
  title?: string;
  content: any;
  actionButton: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
  fullScreen?: boolean;
  btnLabelHide?: boolean;
  btnSize?: "small" | "large" | "medium";
  variant?: "contained" | "outlined" | "text";
  btnColor?: "inherit" | "primary" | "secondary" | "default";
  btnLabel?:
    | "Save"
    | "Submit"
    | "Add"
    | "Yes"
    | "Apply"
    | "Logout"
    | "Ok"
    | "Print"
    | "Confirm";
  btnLabelCancel?: "No" | "Cancel" | "Remove" | "Continue" | "Close";
}

export default function KEEWISTDialog(props: KEEWISTDialogProps) {
  const handleClose = () => {
    props.handleClose();
  };
  const handleConfirm = () => {
    props.handleConfirm();
  };
  const handleReset = () => {
    props.handleReset();
  };

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      fullScreen={props.fullScreen}
    >
      {props.title !== undefined && (
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
      )}
      <DialogContent
        style={{ textAlign: "justify", paddingBottom: theme.spacing(4) }}
      >
        {props.content}
      </DialogContent>
      {props.actionButton && (
        <DialogActions>
          <Button
            onClick={handleClose}
            variant={props.variant !== undefined ? props.variant : "contained"}
            color={props.btnColor !== undefined ? props.btnColor : "default"}
            size={props.btnSize}
          >
            {props.btnLabelCancel !== undefined
              ? props.btnLabelCancel
              : "Cancel"}
          </Button>
          {!props.btnLabelHide && (
            <Button
              onClick={handleConfirm}
              variant={
                props.variant !== undefined ? props.variant : "contained"
              }
              color={props.btnColor !== undefined ? props.btnColor : "primary"}
              size={props.btnSize}
            >
              {props.btnLabel !== undefined ? props.btnLabel : "Confirm"}
            </Button>
          )}
          {!props.btnLabelResetHide && (
            <Button
              onClick={handleReset}
              variant={
                props.variant !== undefined ? props.variant : "contained"
              }
              color={props.btnColor !== undefined ? props.btnColor : "inherit"}
              size={props.btnSize}
            >
              Reset
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
