import { createStyles, Paper, Theme, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";

interface AdminDashProps {
  user: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...theme.typography.button,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8),
    },
  })
);

const AdminDash = (props: AdminDashProps) => {
  const classes = useStyles();
  const [greetings, setGreetUser] = useState("");
  useEffect(() => {
    greetUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const greetUser = () => {
    let myDate = new Date();
    let hrs = myDate.getHours();

    if (hrs < 12) setGreetUser(`Good Morning ${props.user?.user.username}`);
    else if (hrs >= 12 && hrs <= 17)
      setGreetUser(`Good Afternoon ${props.user?.user.username}`);
    else if (hrs >= 17 && hrs <= 24)
      setGreetUser(`Good Evening ${props.user?.user.username}`);
  };
  return (
    <div>
      <Paper variant="outlined">
        <Typography
          component="h2"
          variant="h2"
          color="inherit"
          className={classes.root}
        >
          {greetings}
        </Typography>
      </Paper>
    </div>
  );
};

const mapDispatchToProps = (props) => {
  return {
    user: props.user,
  };
};
export default connect(mapDispatchToProps, {})(AdminDash);
