import AboutUs from "./about-us/AboutUs";
import Home from "./home/Home";
import Houses from "./entirehouse/Houses";
import PrivateRoom from "./privateroom/PrivateRoom";
import ThingsToDo from "./things-to-do/ThingsToDo";
import News from "./news/News";
import UserLogin from "./auth/login/login";
import UserRegister from "./auth/register/register";
import UserList from "./auth/users/UserList";
import PropertyList from "./auth/properties/PropertyList";

const KeewistPages = {
  AboutUs,
  Home,
  Houses,
  PrivateRoom,
  ThingsToDo,
  News,
  UserLogin,
  UserRegister,
  UserList,
  PropertyList,
};
export default KeewistPages;
