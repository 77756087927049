import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import React from "react";
import KEEWISTTab from "./KEEWIST-tab";

interface KEEWISTTabsProps {
  tabs?: any[];
  handleChange?: any;
}

const KEEWISTTabs = (props: KEEWISTTabsProps) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.handleChange(newValue);
  };
  const data = Array.from(props.tabs);
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="keewist-tabs">
            {data.map((tab, index) => (
              <Tab label={tab.label} value={tab.value} key={index} />
            ))}
          </TabList>
        </Box>
        {data?.map((tab, key) => (
          <KEEWISTTab tab={tab} key={key} />
        ))}
      </TabContext>
    </Box>
  );
};

export default KEEWISTTabs;
