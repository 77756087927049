import { ImageListItem } from "@mui/material";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

interface CardProps {
  src: any;
  title: any;
  id: any;
  index: any;
  moveImage: any;
}

const Card = (props: CardProps): React.ReactElement => {
  const ref = React.useRef(null);
  let id = props.id;
  let index = props.index;
  const [, drop] = useDrop({
    accept: "image",
    hover: (item: any, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      props.moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <ImageListItem ref={ref} style={{ opacity }}>
      <img
        crossOrigin="anonymous"
        src={props.src}
        alt={props.title}
        loading="lazy"
      />
    </ImageListItem>
  );
};

export default Card;
