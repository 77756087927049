import React, { createContext, useState } from "react";
import {
    AddNewsState,
    AddNewsStateDefault,
} from "./components/addNews/AddNewsState";

interface NewsContext {
  addNewsState: AddNewsState;
  updateAddNewsState: React.Dispatch<React.SetStateAction<any>>;
}

export const NewsStateContext = createContext({} as NewsContext);

export const NewsProvider = ({ children }) => {
  const [addNewsState, setAddNewsState] = useState<AddNewsState>(
    AddNewsStateDefault
  );

  function updateAddNewsState(newState: any) {
    setAddNewsState((prevState: any) => {
      return { ...prevState, ...newState };
    });
  }

  const value = {
    addNewsState,
    updateAddNewsState,
  };

  return (
    <NewsStateContext.Provider value={value}>
      {children}
    </NewsStateContext.Provider>
  );
};
