import { TextField } from "@material-ui/core";
import React, { useContext } from "react";
import { zipCodeCheck } from "../../../../../../ui-kit/common/validation/KeewistValidations";
import theme from "../../../../../theme/theme";
import { PropertyStateContext } from "../../PropertyContext";
import { validationMessages } from "../../helpers/constants";
import { updatePropertyStatesFunc } from "../../helpers/HelperFunctions";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import styles from "../styles";

const EditHouseAddress = () => {
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);
    const classes = styles();

  const handleAddressChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.address,
      "isLocationAddressInvalid",
      "locationAddressValidationMessage",
      "locationAddress"
    );
  };

  const handleCityChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.city,
      "isLocationCityInvalid",
      "locationCityValidationMessage",
      "locationCity"
    );
  };

  const handleStateChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.state,
      "isLocationStateInvalid",
      "locationStateValidationMessage",
      "locationState"
    );
  };

  const handleZipCodeChange = (e: any) => {
    let value = e.target.value;
    const validation = zipCodeCheck(
      value,
      validationMessages.zipCode1,
      validationMessages.zipCode2,
      validationMessages.zipCode3
    );
    if (validation.isInvalid) {
      updateAddPropertyState({
        isLocationZipCodeInvalid: validation.isInvalid,
        locationZipCodeValidationMessage: validation.validationError,
      });
    } else {
      updateAddPropertyState({
        locationZipCode: value,
        isLocationZipCodeInvalid: false,
        locationZipCodeValidationMessage: "",
      });
    }
  };

  return (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-address"
        label="Address"
        type="text"
        id="house-address"
        onBlur={(e: any) => handleAddressChange(e)}
        onChange={(e: any) => handleAddressChange(e)}
        value={
          addPropertyState.locationAddress !== undefined
            ? addPropertyState.locationAddress
            : ""
        }
        error={addPropertyState.isLocationAddressInvalid}
        helperText={addPropertyState.locationAddressValidationMessage}
      />
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth
        name="house-city"
        label="City"
        type="text"
        id="house-city"
        onBlur={(e: any) => handleCityChange(e)}
        onChange={(e: any) => handleCityChange(e)}
        value={
          addPropertyState.locationCity ? addPropertyState.locationCity : ""
        }
        error={addPropertyState.isLocationCityInvalid}
        helperText={addPropertyState.locationCityValidationMessage}
      />
      <FormControl
        variant="outlined"
        size="medium"
        className={classes.formControl}
      >
        <InputLabel id="keewist-select-state-label">State</InputLabel>
        <Select
          labelId="keewist-select-state-label"
          id="keewist-select-state"
          value={addPropertyState.locationState}
          onBlur={(e: any) => handleStateChange(e)}
          onChange={(e: any) => handleStateChange(e)}
          error={addPropertyState.isLocationStateInvalid}
          label="State"
        >
          <MenuItem value={"KS"}>KS</MenuItem>
        </Select>
        <FormHelperText style={{ color: " #E73935" }}>
          {addPropertyState.locationStateValidationMessage}
        </FormHelperText>
      </FormControl>
      <TextField
        variant="outlined"
        margin="normal"
        size="medium"
        required
        fullWidth={false}
        name="house-zipCode"
        label="Zip code"
        type="text"
        id="house-zipCode"
        value={
          addPropertyState.locationZipCode
            ? addPropertyState.locationZipCode
            : ""
        }
        onBlur={(e: any) => handleZipCodeChange(e)}
        onChange={(e: any) => handleZipCodeChange(e)}
        error={addPropertyState.isLocationZipCodeInvalid}
        helperText={addPropertyState.locationZipCodeValidationMessage}
      />
      <div style={{ marginBottom: theme.spacing(1) }}></div>
    </>
  );
};

export default EditHouseAddress;
