import {
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Paths } from "../../../../../Router";
import styles from "./styles";
import { nullCheck } from "../../../../../../ui-kit/common/validation/KeewistValidations";
import { connect, useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import { TodosApiService } from "../../../../../../api-kit/todos/todos-api.services";
import theme from "../../../../../theme/theme";
import { validationMessages } from "../../helper/constants";
import KEEWISTEditor from "../../../../../../ui-kit/components/KEEWIST-editor";

interface EditThingsToDoProps {
  user?: any;
}

const EditThingsToDo = (props: EditThingsToDoProps) => {
  const todosApiService = new TodosApiService();
  const history = useHistory();
  const location: any = useLocation();
  const classes = styles();
  const dispatch = useDispatch();
  const [todoId, setTodoId] = React.useState("");
  const [todo, setTodo] = React.useState({
    label: "",
    content: [
      {
        title: "",
        labelType: "",
        description: "",
        geo_location: {
          latitude: 0,
          longitude: 0,
          zoom: 0,
        },
        images: [
          {
            src: "",
            imageText: "",
          },
        ],
      },
    ],
  });
  const [isTitleInvalid, setIsTitleInvalid] = React.useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = React.useState("");

  useEffect(() => {
    if (location.state !== null && location.state.todoId !== null) {
      setTodoId(location.state.todoId);
      todosApiService
        .getTodo(todoId ? todoId : location.state.todoId)
        .then((res) => {
          setTodo(res.data);
        })
        .catch((error) => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!isTitleInvalid) {
      let body = {
        // id: todo._id,
        // title: todo.title,
        // post: todo.post,
        // images: todo.images,
      };
      todosApiService
        .editTodo(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "Successfully updated.",
              type: "success",
            })
          );
          history.push(Paths.thingsToDoList);
        })
        .catch((err) =>
          dispatch(
            addSnackBar({
              message: "Unable to update todo.",
              type: "error",
            })
          )
        );
    }
  };

  const handleTitleChange = (event: any) => {
    let value = event.target?.value;
    const validated = nullCheck(value, validationMessages.editTitle);
    if (validated.isInvalid) {
      setIsTitleInvalid(validated.isInvalid);
      setTitleErrorMessage(validated.validationError);
    } else {
      setIsTitleInvalid(false);
      setTitleErrorMessage("");
    }
    setTodo((prevState) => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.thingsToDoList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Will be added soon...
        </Typography>
        {/* <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Edit todo
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextField
            id="standard-basic"
            label="Title"
            // value={todo.title ? todo.title : ""}
            required
            size="medium"
            variant="outlined"
            name="title"
            // focused={editUserState.focused}
            onChange={handleTitleChange}
            fullWidth
            error={isTitleInvalid}
            helperText={titleErrorMessage}
          />
          <div className={classes.editor}>
            <KEEWISTEditor
              // data={todo.post}
              onChange={(e: any) =>
                setTodo((prevState) => ({
                  ...prevState,
                  post: e,
                }))
              }
              onBlur={(e: any) =>
                setTodo((prevState) => ({
                  ...prevState,
                  post: e,
                }))
              }
            />
          </div>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              style={{ width: "100%", marginTop: theme.spacing(1) }}
            >
              Upload image
            </Button>
          </label>
          <ButtonGroup
            disableElevation
            variant="contained"
            className={classes.btn}
          >
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={() => history.push(Paths.thingsToDoList)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              color="secondary"
              // disabled={editUserState.disabled}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </form> */}
      </Paper>
    </>
  );
};

const mapDispatchToProps = (props) => {
  return {
    user: props.user,
  };
};
export default connect(mapDispatchToProps, {})(EditThingsToDo);
