import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { AttachMoney, Bathtub, LocalHotel } from "@material-ui/icons";
import { Badge, Tooltip, withStyles, Zoom } from "@material-ui/core";
import { HomeIcon } from "../../../svg";
import { Paths } from "../../../Router";
import { Link } from "react-router-dom";
import theme from "../../../theme/theme";
import houseStyle from "./houseStyle";

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
  },
}))(Avatar);

interface HouseProps {
  type?: string;
  title?: string;
  subheader?: any;
  thumbnail?: string;
  images?: any;
  imageTitle?: string;
  beds?: number;
  baths?: number;
  price?: number;
  available?: boolean;
  _id?: any;
}

export default function House(props: HouseProps) {
  const classes = houseStyle();

  return (
    <Card>
      <CardHeader
        avatar={
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <SmallAvatar alt="currency">
                <AttachMoney
                  style={{
                    color: theme.palette.secondary.main,
                  }}
                />
              </SmallAvatar>
            }
          >
            <Avatar className={classes.avatar} alt="Amount">
              {props.price !== undefined ? props.price : <HomeIcon />}
            </Avatar>
          </Badge>
        }
        action={
          <Link
            color="secondary"
            to={{
              pathname: Paths.rental,
              state: {
                houseId: props._id,
              },
            }}
          >
            <MoreVertIcon
              color="action"
              style={{ marginTop: theme.spacing(2) }}
            />
          </Link>
        }
        title={props.title}
        subheader={
          props.subheader !== undefined
            ? `${
                props.subheader.property_city.charAt(0).toUpperCase() +
                props.subheader.property_city.slice(1)
              }, ${props.subheader.property_state}`
            : ""
        }
      />
      <Link
        style={{ position: "relative" }}
        color="secondary"
        to={{
          pathname: Paths.rental,
          state: {
            houseId: props._id,
          },
        }}
      >
        <CardMedia
          className={classes.media}
          image={props.thumbnail}
          title={props.imageTitle}
        />
      </Link>
      <CardActions className={classes.actions}>
        <IconButton aria-label="add to favorites" size="small">
          {props.available !== undefined && props.available ? (
            <Tooltip title="Available" TransitionComponent={Zoom} arrow>
              <CheckCircleOutlineIcon fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title="Booked" TransitionComponent={Zoom} arrow>
              <RemoveCircleOutlineIcon fontSize="small" />
            </Tooltip>
          )}
        </IconButton>
        <IconButton aria-label="total beds" size="small">
          <LocalHotel fontSize="small" />
          <span>{props.beds}</span>
        </IconButton>
        <IconButton aria-label="total baths" size="small">
          <Bathtub fontSize="small" />
          <span>{props.baths}</span>
        </IconButton>
        <IconButton aria-label="share" size="small">
          <span className={classes.type}>{props.type}</span>
        </IconButton>
      </CardActions>
    </Card>
  );
}
