import React, { useEffect, useState } from "react";
import styles from "./styles";
import { Container } from "@mui/material";
import { HomeApiService } from "../../../api-kit/home/home-api.service";
import Header from "./components/Header";
import WhyChooseUs from "./components/WhyChooseUs";
import AvailableHouses from "./components/AvailableHouses";
import HouseLocation from "./components/HouseLocation";
import { AboutApiService } from "../../../api-kit/about/about-api.services";

export default function Home() {
  const homeApiService = new HomeApiService();
  const aboutApiService = new AboutApiService();
  const classes = styles();
  const [data, setData] = useState({});
  const [houseList, setHouseList] = React.useState([]);
  const [houseLocations, setHouseLocations] = React.useState([]);
  const [whyChooseUs, setWhyChooseUs] = React.useState("");

  useEffect(() => {
    window.scroll(0, 0);
    homeApiService
      .getHome()
      .then((res) => {
        if (res.data?.houses !== null && res.data?.houses !== undefined && res.data?.houses.length !== 0) {
          setHouseList(res.data?.houses);
          getLocations(res.data?.houses);
        }
        const headerDetails = {
          header: res.data?.header,
          houses: res.data?.houses,
        };
        setData(headerDetails);
      })
      .catch((error) => console.log(error));

    // calling about api
    aboutApiService
      .getAbout()
      .then((res) => setWhyChooseUs(res.data?.about_us))
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLocations = async (location: any) => {
    let myLocations = [];
    location.forEach((location) => {
      myLocations.push(location.geo_location);
    });
    setHouseLocations(myLocations);
  };

  return (
    <React.Fragment>
      <div className={classes.showcase}>
        <div className={classes.overlay}></div>
        <Header data={data} />
      </div>
      <Container component="main">
        <AvailableHouses houses={houseList} />
        <WhyChooseUs whyChooseUs={whyChooseUs} />
        <HouseLocation locations={houseLocations} />
      </Container>
    </React.Fragment>
  );
}
