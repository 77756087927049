import { Button, Paper, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { isFormSubmitted } from "../../../../service/validation.service";
import styles from "./styles";
import { AboutApiService } from "../../../../api-kit/about/about-api.services";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import KEEWISTEditor from "../../../../ui-kit/components/KEEWIST-editor";

const AuthAbout = () => {
  const aboutApiService = new AboutApiService();
  const dispatch = useDispatch();
  const classes = styles();

  const [facebook, setFacebook] = React.useState("");
  const [aboutUs, setAboutUs] = React.useState("");
  const [aboutId, setAboutId] = React.useState("");

  useEffect(() => {
    let isCurrent = true;
    isFormSubmitted.subscribe((submit) => {
      if (submit && isCurrent) {
        isFormSubmitted.next(false);
      }
    });
    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facebook, aboutUs]);

  useEffect(() => {
    aboutApiService
      .getAbout()
      .then((res) => {
        setAboutUs(
          res.data !== undefined && res.data?.about_us !== null
            ? res.data?.about_us
            : ""
        );
        setFacebook(
          res.data !== undefined && res.data?.facebook !== null
            ? res.data?.facebook
            : ""
        );
        setAboutId(
          res.data !== undefined && res.data?._id !== null ? res.data?._id : ""
        );
      })
      .catch((error) =>
        dispatch(
          addSnackBar({
            message: "Add a brief information about your business.",
            type: "warning",
          })
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: any) => {
    isFormSubmitted.next(true);
    e.preventDefault();
    if (aboutUs !== "" && facebook !== "") {
      let body = {
        about_us: aboutUs,
        facebook: facebook,
        _id: aboutId,
      };
      aboutApiService
        .createAbout(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "About page successfully updated",
              type: "success",
            })
          );
        })
        .catch((error) => {
          dispatch(
            addSnackBar({
              message: "Unable to update About page.",
              type: "error",
            })
          );
        });
    }
  };
  return (
    <Paper component="main" elevation={1} className={classes.root}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        gutterBottom
        color="textSecondary"
        style={{
          textTransform: "uppercase",
          fontSize: "1.5em",
          marginBottom: 1,
        }}
      >
        Why Choose us
      </Typography>
      <form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
        <div className={classes.form}>
          <div className={classes.editor}>
            <KEEWISTEditor
              data={aboutUs}
              onChange={(e: any) => setAboutUs(e)}
              onBlur={(e: any) => setAboutUs(e)}
            />
          </div>
          <Button
            type="submit"
            variant="contained"
            size="small"
            className={classes.btn}
          >
            Submit
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default AuthAbout;
