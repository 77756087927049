import { WrapperService } from "../wrapper.service";
import { authHeader } from "../../_redux/_helpers/auth-header";

export class PropertyApiService {
  public apiService: WrapperService = new WrapperService();
  private requestTimeout = 0; //no timeout;
  private responseEncoding = "utf8";
  private baseURL = process.env.REACT_APP_API_PORT;

  public getProperty(propertyId: string) {
    const apiParam = {
      name: "property",
      method: "GET",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: `/${propertyId}`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public getProperties() {
    const apiParam = {
      name: "property",
      method: "GET",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: "/properties",
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public createProperty(data: any) {
    const apiParam = {
      name: "property",
      method: "POST",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader,
      },
      body: data,
      suffixURL: "/createProperty",
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public async deleteProperty(id) {
    const apiParam = {
      name: "property",
      method: "DELETE",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: {},
      suffixURL: `/${id}`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }

  public editProperty(body: any) {
    const apiParam = {
      name: "property",
      method: "PATCH",
      params: {},
      headers: {
        "Content-Type": "application/json",
        ...authHeader(),
      },
      body: body,
      suffixURL: `/update`,
      baseURL: this.baseURL,
      timeout: this.requestTimeout,
      withCredentials: true,
      responseType: "json",
      responseEncoding: this.responseEncoding,
    };
    return this.apiService.httpCall(apiParam, false);
  }
}
