import React from "react";
import styles from "../styles";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Box, Typography } from "@material-ui/core";

interface ImageSliderProps {
  images: any;
}

const ImageSlider = (props: ImageSliderProps) => {
  const classes = styles();
  const [currentImage, setCurrentImage] = React.useState(0);
  const [viewerIsOpen, setViewerIsOpen] = React.useState(false);

  const openLightbox = () => {
    setCurrentImage(1);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        className={classes.imageHeader}
        onClick={openLightbox}
      >
        {props.images !== undefined &&
          props.images.length !== 0 &&
          props.images
            .slice(0, 5)
            .map((img, key) => (
              <img
                key={key}
                className={classes.image}
                crossOrigin={"anonymous"}
                src={img.filepath}
                alt={img.filename}
              />
            ))}
        <Typography variant="caption" className={classes.viewImages}>
          Show all photos
        </Typography>
      </Box>
      <ModalGateway>
        {viewerIsOpen &&
          props.images !== undefined &&
          props.images.length > 1 && (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={props.images.map((x) => ({
                  // ...x,
                  source: x?.filepath,
                  caption: x?.filename,
                }))}
              />
            </Modal>
          )}
      </ModalGateway>
    </>
  );
};

export default ImageSlider;
