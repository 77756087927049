export function getToken() {
  let token = localStorage.getItem("usertoken");
  if (token) {
    return token;
  } else {
    return "";
  }
}

export function authHeader() {
  let token = getToken();
  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export function getUserInfo() {
  let userInfo = null;
  const jwtToken = getToken();
  if (jwtToken) {
    try {
      userInfo = parseJwt(jwtToken);
    } catch (e) {}
  }
  return userInfo;
}

export function getTokenExpTime() {
  let expTime = null;
  const jwtToken = getToken();
  if (jwtToken) {
    try {
      expTime = JSON.parse(parseJwt(jwtToken).exp);
    } catch (e) {}
  }
  return expTime;
}
