export interface PolicyState {
  policyId: string;
  type: string;
  title: string;
  content: string;
  discountedPrice: number;
  cleaningFee: number;
  serviceFee: number;
}

export const PolicyStateDefault: PolicyState = {
  policyId: "",
  type: "",
  title: "",
  content: "",
  discountedPrice: 0,
  cleaningFee: 0,
  serviceFee: 0,
};
