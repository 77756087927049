import { useContext } from "react";
import { Chip, TextField } from "@material-ui/core";
import theme from "../../../../../theme/theme";
import { PropertyStateContext } from "../../PropertyContext";
import AddIcon from "@mui/icons-material/Add";
import styles from "../styles";

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import KEEWISTDialog from "../../../../../../ui-kit/components/KEEWIST-dailog";

const AddHouseRules = () => {
  const classes = styles();
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);
  const onDeleteRule = (value) => {
    const RuleUpdated = addPropertyState.houseRules.filter(
      (rule) => rule !== value
    );
    updateAddPropertyState({ houseRules: RuleUpdated });
  };

  const saveOfferings = () => {
    console.log("offerings saved.");
    updateAddPropertyState({
      offerings: false,
    });
  };
  const closeOfferingsWindow = () => {
    console.log("offerings closed.");
    updateAddPropertyState({
      offerings: false,
    });
  };

  const resetOfferings = () => {
    updateAddPropertyState({
      offerings: false,
      // Bathroom
      bathtub: false,
      hairDryer: false,
      cleaningProducts: false,
      shampoo: false,
      conditioner: false,
      bodySoap: false,
      hotWater: false,
      showerGel: false,
      // Bedroom and laundry
      freeWasherInBuilding: false,
      freeDryerInBuilding: false,
      essentials: false,
      hangers: false,
      bedLinens: false,
      extraPillowsAndBlankets: false,
      iron: false,
      clothingStorage: false,
      //Entertaiment
      enthernetConnection: false,
      smartTv: false,
      // Family
      Crib: false,
      PackNPlay: false,
      highChair: false,
      babyBath: false,
      childrensDinnerware: false,
      boardGames: false,
      babySafetyGates: false,
      // Heating and cooling
      centralAirConditioning: false,
      ceilingFan: false,
      portableFans: false,
      heating: false,
      //Home safety
      securityCamerasOnProperty: false,
      smokeAlarm: false,
      carbonMonoxideAlarm: false,
      fireExtinguisher: false,
      firstAidKit: false,
      // Internet and office
      wifi: false,
      dedicatedWorkspace: false,
      // Kitchen and dining
      kitchen: false,
      refrigerator: false,
      microwave: false,
      cookingBasics: false,
      dishesAndSilverware: false,
      freezer: false,
      dishwasher: false,
      stainlessSteelElectricStove: false,
      oven: false,
      hotWaterKettle: false,
      coffeeMaker: false,
      wineGlasses: false,
      toaster: false,
      bakingSheet: false,
      blender: false,
      barbecueUtensils: false,
      diningTable: false,
      coffee: false,
      // Location features
      privateEntrance: false,
      // Outdoor
      sharedPatioOrBalcony: false,
      privateBackyardNotFullyFenced: false,
      outdoorFurniture: false,
      outdoorDiningArea: false,
      bbqGrill: false,
      // Parking and facilities
      freeParkingOnPremises: false,
      freeStreetParking: false,
      // Services
      luggageDropoffAllowed: false,
      longTermStaysAllowed: false,
      smartLock: false,
      cleaningAvailableDuringStay: false,
    });
  };

  const whatThisPlaceOffers = (
    <FormGroup>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Bathroom
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.bathtub}
                onChange={() =>
                  updateAddPropertyState({ bathtub: !addPropertyState.bathtub })
                }
                name="Bathtub"
              />
            }
            label="Bathtub"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.hairDryer}
                onChange={() =>
                  updateAddPropertyState({
                    hairDryer: !addPropertyState.hairDryer,
                  })
                }
                name="Hair dryer"
              />
            }
            label="Hair dryer"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.cleaningProducts}
                onChange={() =>
                  updateAddPropertyState({
                    cleaningProducts: !addPropertyState.cleaningProducts,
                  })
                }
                name="Cleaning products"
              />
            }
            label="Cleaning products"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.shampoo}
                onChange={() =>
                  updateAddPropertyState({ shampoo: !addPropertyState.shampoo })
                }
                name="Shampoo"
              />
            }
            label="Shampoo"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.conditioner}
                onChange={() =>
                  updateAddPropertyState({
                    conditioner: !addPropertyState.conditioner,
                  })
                }
                name="Conditioner"
              />
            }
            label="Conditioner"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.bodySoap}
                onChange={() =>
                  updateAddPropertyState({
                    bodySoap: !addPropertyState.bodySoap,
                  })
                }
                name="Body soap"
              />
            }
            label="Body soap"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.hotWater}
                onChange={() =>
                  updateAddPropertyState({
                    hotWater: !addPropertyState.hotWater,
                  })
                }
                name="Hot water"
              />
            }
            label="Hot water"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.showerGel}
                onChange={() =>
                  updateAddPropertyState({
                    showerGel: !addPropertyState.showerGel,
                  })
                }
                name="Shower gel"
              />
            }
            label="Shower gel"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Bedroom and Laundry
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.freeWasherInBuilding}
                onChange={() =>
                  updateAddPropertyState({
                    freeWasherInBuilding:
                      !addPropertyState.freeWasherInBuilding,
                  })
                }
                name="Free washer in building"
              />
            }
            label="Free Washer In Building"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.freeDryerInBuilding}
                onChange={() =>
                  updateAddPropertyState({
                    freeDryerInBuilding: !addPropertyState.freeDryerInBuilding,
                  })
                }
                name="Free dryer - In building"
              />
            }
            label="Free dryer - In building"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.essentials}
                onChange={() =>
                  updateAddPropertyState({
                    essentials: !addPropertyState.essentials,
                  })
                }
                name="Essentials"
              />
            }
            label="Essentials"
          />
          <FormHelperText className={classes.helperText}>
            Towels, bed sheets, soap, and toilet paper.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.hangers}
                onChange={() =>
                  updateAddPropertyState({
                    hangers: !addPropertyState.hangers,
                  })
                }
                name="Hangers"
              />
            }
            label="Hangers"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.bedLinens}
                onChange={() =>
                  updateAddPropertyState({
                    bedLinens: !addPropertyState.bedLinens,
                  })
                }
                name="Bed linens"
              />
            }
            label="Bed linens"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.extraPillowsAndBlankets}
                onChange={() =>
                  updateAddPropertyState({
                    extraPillowsAndBlankets:
                      !addPropertyState.extraPillowsAndBlankets,
                  })
                }
                name="Extra pillows and blankets"
              />
            }
            label="Extra pillows and blankets"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.iron}
                onChange={() =>
                  updateAddPropertyState({
                    iron: !addPropertyState.iron,
                  })
                }
                name="Iron"
              />
            }
            label="Iron"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.clothingStorage}
                onChange={() =>
                  updateAddPropertyState({
                    clothingStorage: !addPropertyState.clothingStorage,
                  })
                }
                name="Clothing storage: closet"
              />
            }
            label="Clothing storage: closet"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Entertainment
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.enthernetConnection}
                onChange={() =>
                  updateAddPropertyState({
                    enthernetConnection: !addPropertyState.enthernetConnection,
                  })
                }
                name="Enthernet Connection"
              />
            }
            label="Enthernet Connection"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.smartTv}
                onChange={() =>
                  updateAddPropertyState({
                    smartTv: !addPropertyState.smartTv,
                  })
                }
                name="HDTV with Amazon Prime Video, Fire TV, Netflix, standard cable"
              />
            }
            label="HDTV with Amazon Prime Video, Fire TV, Netflix, standard cable"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Family
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.Crib}
                onChange={() =>
                  updateAddPropertyState({
                    Crib: !addPropertyState.Crib,
                  })
                }
                name="Crib"
              />
            }
            label="Crib"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.PackNPlay}
                onChange={() =>
                  updateAddPropertyState({
                    PackNPlay: !addPropertyState.PackNPlay,
                  })
                }
                name="Pack 'n play/Travel crib"
              />
            }
            label="Pack 'n play/Travel crib"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.highChair}
                onChange={() =>
                  updateAddPropertyState({
                    highChair: !addPropertyState.highChair,
                  })
                }
                name="High chair"
              />
            }
            label="High chair"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.babyBath}
                onChange={() =>
                  updateAddPropertyState({
                    babyBath: !addPropertyState.babyBath,
                  })
                }
                name="Baby bath"
              />
            }
            label="Baby bath"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.childrensDinnerware}
                onChange={() =>
                  updateAddPropertyState({
                    childrensDinnerware: !addPropertyState.childrensDinnerware,
                  })
                }
                name="Children’s dinnerware"
              />
            }
            label="Children’s dinnerware"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.boardGames}
                onChange={() =>
                  updateAddPropertyState({
                    boardGames: !addPropertyState.boardGames,
                  })
                }
                name="Board games"
              />
            }
            label="Board games"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.babySafetyGates}
                onChange={() =>
                  updateAddPropertyState({
                    babySafetyGates: !addPropertyState.babySafetyGates,
                  })
                }
                name="Baby safety gates"
              />
            }
            label="Baby safety gates"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Heating and cooling
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.centralAirConditioning}
                onChange={() =>
                  updateAddPropertyState({
                    centralAirConditioning:
                      !addPropertyState.centralAirConditioning,
                  })
                }
                name="Central air conditioning"
              />
            }
            label="Central air conditioning"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.ceilingFan}
                onChange={() =>
                  updateAddPropertyState({
                    ceilingFan: !addPropertyState.ceilingFan,
                  })
                }
                name="Ceiling fan"
              />
            }
            label="Ceiling fan"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.portableFans}
                onChange={() =>
                  updateAddPropertyState({
                    portableFans: !addPropertyState.portableFans,
                  })
                }
                name="Portable fans"
              />
            }
            label="Portable fans"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.heating}
                onChange={() =>
                  updateAddPropertyState({
                    heating: !addPropertyState.heating,
                  })
                }
                name="Heating"
              />
            }
            label="Heating"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Home safety
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.securityCamerasOnProperty}
                onChange={() =>
                  updateAddPropertyState({
                    securityCamerasOnProperty:
                      !addPropertyState.securityCamerasOnProperty,
                  })
                }
                name="Security cameras on property"
              />
            }
            label="Security cameras on property"
          />
          <FormHelperText className={classes.helperText}>
            Ring doorbell in the front door only for security purpose and it is
            ongoing recording with or without reservations.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.smokeAlarm}
                onChange={() =>
                  updateAddPropertyState({
                    smokeAlarm: !addPropertyState.smokeAlarm,
                  })
                }
                name="Smoke alarm"
              />
            }
            label="Smoke alarm"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.carbonMonoxideAlarm}
                onChange={() =>
                  updateAddPropertyState({
                    carbonMonoxideAlarm: !addPropertyState.carbonMonoxideAlarm,
                  })
                }
                name="Carbon monoxide alarm"
              />
            }
            label="Carbon monoxide alarm"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.fireExtinguisher}
                onChange={() =>
                  updateAddPropertyState({
                    fireExtinguisher: !addPropertyState.fireExtinguisher,
                  })
                }
                name="Fire extinguisher"
              />
            }
            label="Fire extinguisher"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.firstAidKit}
                onChange={() =>
                  updateAddPropertyState({
                    firstAidKit: !addPropertyState.firstAidKit,
                  })
                }
                name="First aid kit"
              />
            }
            label="First aid kit"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Internet and office
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.wifi}
                onChange={() =>
                  updateAddPropertyState({
                    wifi: !addPropertyState.wifi,
                  })
                }
                name="Wifi"
              />
            }
            label="Wifi"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.dedicatedWorkspace}
                onChange={() =>
                  updateAddPropertyState({
                    dedicatedWorkspace: !addPropertyState.dedicatedWorkspace,
                  })
                }
                name="Dedicated workspace"
              />
            }
            label="Dedicated workspace"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Kitchen and dining
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.kitchen}
                onChange={() =>
                  updateAddPropertyState({
                    kitchen: !addPropertyState.kitchen,
                  })
                }
                name="Kitchen"
              />
            }
            label="Kitchen"
          />
          <FormHelperText className={classes.helperText}>
            Space where guests can cook their own meals.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.refrigerator}
                onChange={() =>
                  updateAddPropertyState({
                    refrigerator: !addPropertyState.refrigerator,
                  })
                }
                name="Refrigerator"
              />
            }
            label="Refrigerator"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.microwave}
                onChange={() =>
                  updateAddPropertyState({
                    microwave: !addPropertyState.microwave,
                  })
                }
                name="Microwave"
              />
            }
            label="Microwave"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.cookingBasics}
                onChange={() =>
                  updateAddPropertyState({
                    cookingBasics: !addPropertyState.cookingBasics,
                  })
                }
                name="Cooking basics"
              />
            }
            label="Cooking basics"
          />
          <FormHelperText className={classes.helperText}>
            Pots and pans, oil, salt and pepper.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.dishesAndSilverware}
                onChange={() =>
                  updateAddPropertyState({
                    dishesAndSilverware: !addPropertyState.dishesAndSilverware,
                  })
                }
                name="Dishes and silverware"
              />
            }
            label="Dishes and silverware"
          />
          <FormHelperText className={classes.helperText}>
            Bowls, chopsticks, plates, cups, etc.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.freezer}
                onChange={() =>
                  updateAddPropertyState({
                    freezer: !addPropertyState.freezer,
                  })
                }
                name="Freezer"
              />
            }
            label="Freezer"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.dishwasher}
                onChange={() =>
                  updateAddPropertyState({
                    dishwasher: !addPropertyState.dishwasher,
                  })
                }
                name="Dishwasher"
              />
            }
            label="Dishwasher"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.stainlessSteelElectricStove}
                onChange={() =>
                  updateAddPropertyState({
                    stainlessSteelElectricStove:
                      !addPropertyState.stainlessSteelElectricStove,
                  })
                }
                name="Stainless steel electric stove"
              />
            }
            label="Stainless steel electric stove"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.oven}
                onChange={() =>
                  updateAddPropertyState({
                    oven: !addPropertyState.oven,
                  })
                }
                name="Oven"
              />
            }
            label="Oven"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.hotWaterKettle}
                onChange={() =>
                  updateAddPropertyState({
                    hotWaterKettle: !addPropertyState.hotWaterKettle,
                  })
                }
                name="Hot water kettle"
              />
            }
            label="Hot water kettle"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.coffeeMaker}
                onChange={() =>
                  updateAddPropertyState({
                    coffeeMaker: !addPropertyState.coffeeMaker,
                  })
                }
                name="Coffee maker: Keurig coffee machine, pour-over coffee"
              />
            }
            label="Coffee maker: Keurig coffee machine, pour-over coffee"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.wineGlasses}
                onChange={() =>
                  updateAddPropertyState({
                    wineGlasses: !addPropertyState.wineGlasses,
                  })
                }
                name="Wine glasses"
              />
            }
            label="Wine glasses"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.toaster}
                onChange={() =>
                  updateAddPropertyState({
                    toaster: !addPropertyState.toaster,
                  })
                }
                name="Toaster"
              />
            }
            label="Toaster"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.bakingSheet}
                onChange={() =>
                  updateAddPropertyState({
                    bakingSheet: !addPropertyState.bakingSheet,
                  })
                }
                name="Baking sheet"
              />
            }
            label="Baking sheet"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.blender}
                onChange={() =>
                  updateAddPropertyState({
                    blender: !addPropertyState.blender,
                  })
                }
                name="Blender"
              />
            }
            label="Blender"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.barbecueUtensils}
                onChange={() =>
                  updateAddPropertyState({
                    barbecueUtensils: !addPropertyState.barbecueUtensils,
                  })
                }
                name="Barbecue utensils"
              />
            }
            label="Barbecue utensils"
          />
          <FormHelperText className={classes.helperText}>
            Grill, charcoal, bamboo skewers/iron skewers, etc.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.diningTable}
                onChange={() =>
                  updateAddPropertyState({
                    diningTable: !addPropertyState.diningTable,
                  })
                }
                name="Dining table"
              />
            }
            label="Dining table"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.coffee}
                onChange={() =>
                  updateAddPropertyState({
                    coffee: !addPropertyState.coffee,
                  })
                }
                name="Coffee"
              />
            }
            label="Coffee"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Location features
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.privateEntrance}
                onChange={() =>
                  updateAddPropertyState({
                    privateEntrance: !addPropertyState.privateEntrance,
                  })
                }
                name="Private entrance"
              />
            }
            label="Private entrance"
          />
          <FormHelperText className={classes.helperText}>
            Separate street or building entrance.
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Outdoor
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.sharedPatioOrBalcony}
                onChange={() =>
                  updateAddPropertyState({
                    sharedPatioOrBalcony:
                      !addPropertyState.sharedPatioOrBalcony,
                  })
                }
                name="Shared patio or balcony"
              />
            }
            label="Shared patio or balcony"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.privateBackyardNotFullyFenced}
                onChange={() =>
                  updateAddPropertyState({
                    privateBackyardNotFullyFenced:
                      !addPropertyState.privateBackyardNotFullyFenced,
                  })
                }
                name="Private backyard – Not fully fenced"
              />
            }
            label="Private backyard – Not fully fenced"
          />
          <FormHelperText className={classes.helperText}>
            An open space on the property usually covered in grass.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.outdoorFurniture}
                onChange={() =>
                  updateAddPropertyState({
                    outdoorFurniture: !addPropertyState.outdoorFurniture,
                  })
                }
                name="Outdoor furniture"
              />
            }
            label="Outdoor furniture"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.outdoorDiningArea}
                onChange={() =>
                  updateAddPropertyState({
                    outdoorDiningArea: !addPropertyState.outdoorDiningArea,
                  })
                }
                name="Outdoor dining area"
              />
            }
            label="Outdoor dining area"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.bbqGrill}
                onChange={() =>
                  updateAddPropertyState({
                    bbqGrill: !addPropertyState.bbqGrill,
                  })
                }
                name="BBQ grill"
              />
            }
            label="BBQ grill"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Parking and facilities
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.freeParkingOnPremises}
                onChange={() =>
                  updateAddPropertyState({
                    freeParkingOnPremises:
                      !addPropertyState.freeParkingOnPremises,
                  })
                }
                name="Free parking on premises"
              />
            }
            label="Free parking on premises"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.freeStreetParking}
                onChange={() =>
                  updateAddPropertyState({
                    freeStreetParking: !addPropertyState.freeStreetParking,
                  })
                }
                name="Free street parking"
              />
            }
            label="Free street parking"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Services
          </Typography>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.luggageDropoffAllowed}
                onChange={() =>
                  updateAddPropertyState({
                    luggageDropoffAllowed:
                      !addPropertyState.luggageDropoffAllowed,
                  })
                }
                name="Luggage dropoff allowed"
              />
            }
            label="Luggage dropoff allowed"
          />
          <FormHelperText className={classes.helperText}>
            For guests' convenience when they have early arrival or late
            departure
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.longTermStaysAllowed}
                onChange={() =>
                  updateAddPropertyState({
                    longTermStaysAllowed:
                      !addPropertyState.longTermStaysAllowed,
                  })
                }
                name="Long term stays allowed"
              />
            }
            label="Long term stays allowed"
          />
          <FormHelperText className={classes.helperText}>
            Allow stay for 28 days or more.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.selfCheckIn}
                onChange={() =>
                  updateAddPropertyState({
                    selfCheckIn: !addPropertyState.selfCheckIn,
                  })
                }
                name="Self check-in"
              />
            }
            label="Self check-in"
          />
          <FormHelperText className={classes.helperText}>
            If house has a keypad, please check the checkbox.
          </FormHelperText>
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.smartLock}
                onChange={() =>
                  updateAddPropertyState({
                    smartLock: !addPropertyState.smartLock,
                  })
                }
                name="Smart lock"
              />
            }
            label="Smart lock"
          />
          <FormControlLabel
            className={classes.checkboxOffers}
            control={
              <Checkbox
                checked={addPropertyState.cleaningAvailableDuringStay}
                onChange={() =>
                  updateAddPropertyState({
                    cleaningAvailableDuringStay:
                      !addPropertyState.cleaningAvailableDuringStay,
                  })
                }
                name="Cleaning available during stay"
              />
            }
            label="Cleaning available during stay"
          />
        </Grid>
      </Grid>
    </FormGroup>
  );

  return (
    <>
      <Typography
        style={{
          marginTop: theme.spacing(3),
        }}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        House Rules
      </Typography>
      <Divider
        light
        style={{
          marginBottom: theme.spacing(3),
        }}
      />
      <TextField
        variant="outlined"
        fullWidth
        name="house-rules"
        label="House rules"
        type="text"
        id="house-rules"
        value={
          addPropertyState.houseRule !== "" ? addPropertyState.houseRule : ""
        }
        onChange={(e) => updateAddPropertyState({ houseRule: e.target?.value })}
      />

      <br />
      <div className={classes.chipsRow}>
        {addPropertyState.houseRules != null &&
          addPropertyState.houseRules !== undefined &&
          addPropertyState.houseRules.map((item, index) => (
            <Chip
              key={index}
              size="small"
              label={item}
              onDelete={(e) => onDeleteRule(item)}
              className={classes.chips}
            />
          ))}
      </div>
      <Typography
        style={{
          marginTop: theme.spacing(3),
        }}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Amenities
      </Typography>
      <Divider
        light
        style={{
          marginBottom: theme.spacing(3),
        }}
      />
      <Button
        variant="contained"
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.light,
        }}
        onClick={() =>
          updateAddPropertyState({ offerings: !addPropertyState.offerings })
        }
        size={"small"}
      >
        <AddIcon color="inherit" fontSize="small" />
      </Button>
      <div style={{ marginBottom: theme.spacing(2) }}></div>
      <KEEWISTDialog
        isOpen={addPropertyState.offerings}
        handleClose={closeOfferingsWindow}
        title={"Amenities"}
        content={whatThisPlaceOffers}
        actionButton={true}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={false}
        btnSize="small"
        btnLabelCancel="Close"
        btnLabel="Save"
        btnLabelResetHide={false}
        handleReset={resetOfferings}
        handleConfirm={saveOfferings}
      />
    </>
  );
};

export default AddHouseRules;
