// Email validation
export const checkEmail = (email): any => {
  let validation = {
    isInvalid: false,
    validationError: "",
  };
  if (email.length === 0) {
    validation.isInvalid = true;
    validation.validationError = "Email address field is required.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    validation.isInvalid = true;
    validation.validationError = "Invalid email address.";
  } else {
    validation.isInvalid = false;
    validation.validationError = "";
  }
  return validation;
};

// User name validation

export const checkUserName = (name) => {
  let validation = {
    isInvalid: false,
    validationError: "",
  };

  if (name.length === 0) {
    validation.isInvalid = true;
    validation.validationError = "Username field is required.";
  } else if (!/^[a-zA-Z]+$/.test(name)) {
    validation.isInvalid = true;
    validation.validationError = "Username must have letters. No spaces!";
  } else {
    validation.isInvalid = false;
    validation.validationError = "";
  }
  return validation;
};

// New house validations
export const nullCheck = (name, message) => {
  let validation = {
    isInvalid: false,
    validationError: "",
  };

  if ((name !== undefined && name.length === 0) || name === 0) {
    validation.isInvalid = true;
    validation.validationError = message;
  }
  return validation;
};

export const zipCodeCheck = (name, message1, message2, message3) => {
  let validation = {
    isInvalid: false,
    validationError: "",
  };

  if (name.length === 0) {
    validation.isInvalid = true;
    validation.validationError = message1;
  } else if (!/^[0-9]*$/.test(name)) {
    validation.isInvalid = true;
    validation.validationError = message2;
  } else if (name.length !== 5) {
    validation.isInvalid = true;
    validation.validationError = message3;
  } else {
    validation.isInvalid = false;
    validation.validationError = "";
  }
  return validation;
};

// confirm password

export const confirmPassword = (
  password1,
  password2,
  message1,
  message2,
  message3
) => {
  let validation = {
    isInvalid: false,
    validationError: "",
  };

  if (password1.length === 0) {
    validation.isInvalid = true;
    validation.validationError = message1;
  } else if (password2.length === 0) {
    validation.isInvalid = true;
    validation.validationError = message2;
  } else if (password1 !== password2) {
    validation.isInvalid = true;
    validation.validationError = message3;
  }
  return validation;
};
