import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styles from "../styles";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import theme from "../../../theme/theme";
import blog from "../../../../assets/images/blog.jpg";

interface NewsHeaderProps {
  header: any;
}

const NewsHeader = (props: NewsHeaderProps) => {
  const classes = styles();
  const { header } = props;

  return (
    <Grid item xs={12}>
      <Paper
        className={classes.mainFeaturedPost}
        style={{ backgroundImage: `url(${blog})` }}
      >
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: "none" }} src={blog} alt={"blog post"} />}
        <div className={classes.overlay} />
        <Grid container>
          <Grid
            item
            md={8}
            style={{
              paddingBottom: theme.spacing(8),
              paddingTop: theme.spacing(8),
            }}
          >
            <div className={classes.mainFeaturedPostContent}>
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                gutterBottom
              >
                {header?.header}
              </Typography>
              <Typography
                variant="body1"
                color="inherit"
                style={{ fontSize: 20, position: "relative" }}
              >
                {header?.inspiring_quote}{" "}
                <FormatQuoteIcon
                  sx={{
                    fontSize: 50,
                    position: "absolute",
                    bottom: -15,
                  }}
                />
              </Typography>
              <Typography
                align="right"
                variant="caption"
                color="inherit"
                style={{
                  fontSize: 16,
                }}
              >
                {"-"}
                {header?.quotee}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default NewsHeader;
