import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AccountCircle, ExitToApp, MenuRounded } from '@mui/icons-material';
import theme from '../../app/theme/theme';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../app/Router';

interface PositionedMenuProps {
    logout?: any;
  }
  
  const PositionedMenu = (props: PositionedMenuProps): React.ReactElement => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="keewist-positioned-button"
        aria-controls={open ? 'keewist-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          color: "inherit",
          justifyContent: "initial",
          minWidth: 0,
          border: "none",
          height: 25,
          paddingTop:15
        }}
      >
        <span><AccountCircle style={{ fontSize: 22 }} /></span>
        <span><MenuRounded color="inherit"/></span>
      </Button>
      <Menu
        id="keewist-positioned-menu"
        aria-labelledby="keewist-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {}
        <MenuItem onClick={() => history.push(Paths.dashboard)}>Dashboard</MenuItem>
        <MenuItem><Button
        onClick={() => props.logout()}
        variant="contained"
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        size={"small"}
      >
        <ExitToApp />
      </Button></MenuItem>
      </Menu>
    </div>
  );
}

export default PositionedMenu;