import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Avatar,
  Button,
  CssBaseline,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { UserApiService } from "../../../../api-kit/user/user-api.service";
import { connect, useDispatch } from "react-redux";
import { Login } from "../../../../_redux/_actions/user.actions";
import {
  checkEmail,
  confirmPassword,
  nullCheck,
} from "../../../../ui-kit/common/validation/KeewistValidations";
import { validationMessages } from "./helper/constants";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import styles from "./styles";
import theme from "../../../theme/theme";

interface UserRegisterProps {
  Login: any;
}

const UserRegister = (props: UserRegisterProps): React.ReactElement => {
  const dispatch = useDispatch();
  const userApiService = new UserApiService();
  const myClasses = styles();
  const [email, setEmail] = useState();
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [password, setPassword] = useState();
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordCheck, setPasswordCheck] = useState();
  const [isPasswordCheckInvalid, setIsPasswordCheckInvalid] = useState(false);
  const [passwordCheckErrorMessage, setPasswordCheckErrorMessage] =
    useState("");
  const [username, setUsername] = useState();
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const history = useHistory();

  const login = () => {
    history.push("/login");
  };
  const handleUsernameChange = (e: any) => {
    let value = e.target?.value;
    const validation = nullCheck(value, validationMessages.username);
    if (validation.isInvalid) {
      setIsUsernameInvalid(validation.isInvalid);
      setUsernameErrorMessage(validation.validationError);
      setUsername(value);
    } else {
      setIsUsernameInvalid(false);
      setUsernameErrorMessage("");
      setUsername(value);
    }
  };

  const handleEmailChange = (e: any) => {
    let value = e.target?.value;
    const validation = checkEmail(value);
    if (validation.isInvalid) {
      setIsEmailInvalid(validation.isInvalid);
      setEmailErrorMessage(validation.validationError);
      setEmail(value);
    } else {
      setIsEmailInvalid(false);
      setEmailErrorMessage("");
      setEmail(value);
    }
  };

  const handlePasswordChange = (e: any) => {
    let value = e.target?.value;
    const validation = nullCheck(value, validationMessages.password);
    if (validation.isInvalid) {
      setIsPasswordInvalid(validation.isInvalid);
      setPasswordErrorMessage(validation.validationError);
      setPassword(value);
    } else {
      setIsPasswordInvalid(false);
      setPasswordErrorMessage("");
      setPassword(value);
    }
  };
  const handlePasswordCheckChange = (e: any) => {
    let value = e.target?.value;
    const validation = confirmPassword(
      password,
      value,
      validationMessages.password,
      validationMessages.confirmPassword,
      validationMessages.passwordCheck
    );
    if (validation.isInvalid) {
      setIsPasswordCheckInvalid(validation.isInvalid);
      setPasswordCheckErrorMessage(validation.validationError);
      setPasswordCheck(value);
    } else {
      setIsPasswordCheckInvalid(false);
      setPasswordCheckErrorMessage("");
      setPasswordCheck(value);
    }
  };

  async function submit(e: any) {
    e.preventDefault();
    const newUser = { email, password, passwordCheck, username };
    userApiService
      .register(newUser)
      .then((user) => {
        const userCredentials = { email, password };
        props.Login(userCredentials);
      })
      .catch((err) => {
        dispatch(
          addSnackBar({
            message: err.data?.error_message,
            type: "error",
          })
        );
      });
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={myClasses.paper}>
        <Avatar className={myClasses.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={myClasses.form}
          onSubmit={submit}
        >
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Username"
            type="text"
            id="username"
            onBlur={(e: any) => handleUsernameChange(e)}
            onChange={(e: any) => handleUsernameChange(e)}
            autoFocus
            error={isUsernameInvalid}
            helperText={usernameErrorMessage}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            type="text"
            label="Email Address"
            name="email"
            onBlur={(e: any) => handleEmailChange(e)}
            onChange={(e: any) => handleEmailChange(e)}
            error={isEmailInvalid}
            helperText={emailErrorMessage}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onBlur={(e: any) => handlePasswordChange(e)}
            onChange={(e: any) => handlePasswordChange(e)}
            error={isPasswordInvalid}
            helperText={passwordErrorMessage}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            onBlur={(e: any) => handlePasswordCheckChange(e)}
            onChange={(e: any) => handlePasswordCheckChange(e)}
            error={isPasswordCheckInvalid}
            helperText={passwordCheckErrorMessage}
          />
          <Button
            fullWidth={true}
            startIcon={<HowToRegIcon />}
            type={"submit"}
            className={myClasses.submit}
            variant="contained"
            style={{
              marginTop: theme.spacing(1),
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
            }}
            size={"large"}
          >
            Register
          </Button>
          <Grid container>
            <Grid item>
              <Link
                style={{ cursor: "pointer" }}
                onClick={login}
                variant="body2"
              >
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
};
const mapStateToProps = (props) => ({
  user: props.user.user,
});

export default connect(mapStateToProps, { Login })(UserRegister);
