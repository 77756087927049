import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  map: {
    position: "sticky",
    top: 50,
    height: "100%",
    marginTop: `${theme.spacing(13.5)}px !important`,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));
