import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import theme from "../../../../theme/theme";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface KEEWISTFormDialogProps {
  isOpen: boolean;
  handleClose: any;
  handleConfirm: any;
  warningQuestion: string;
  content: any;
}

export default function KEEWISTFormDialog(props: KEEWISTFormDialogProps) {
  const handleClose = () => {
    props.handleClose();
  };
  const handleConfirm = () => {
    props.handleConfirm();
  };

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {props.warningQuestion}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <strong>Id:</strong> {props?.content?._id} <br />
          <strong>Title:</strong> {props?.content?.title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="default">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          style={{
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
