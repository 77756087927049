import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useContext, useRef } from "react";
import { zipCodeCheck } from "../../../../../../ui-kit/common/validation/KeewistValidations";
import theme from "../../../../../theme/theme";
import { PropertyStateContext } from "../../PropertyContext";
import { validationMessages } from "../../helpers/constants";
import styles from "../styles";
import { updatePropertyStatesFunc } from "../../helpers/HelperFunctions";
import Geocoder from "react-map-gl-geocoder";
import MapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

const AddHouseAddress = () => {
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const classes = styles();
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);

  const handleAddressChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.address,
      "isLocationAddressInvalid",
      "locationAddressValidationMessage",
      "locationAddress"
    );
  };

  const handleCityChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.city,
      "isLocationCityInvalid",
      "locationCityValidationMessage",
      "locationCity"
    );
  };

  const handleStateChange = (e: any) => {
    let value = e.target.value;
    updatePropertyStatesFunc(
      updateAddPropertyState,
      value,
      validationMessages.state,
      "isLocationStateInvalid",
      "locationStateValidationMessage",
      "locationState"
    );
  };

  const handleZipCodeChange = (e: any) => {
    let value = e.target.value;
    const validation = zipCodeCheck(
      value,
      validationMessages.zipCode1,
      validationMessages.zipCode2,
      validationMessages.zipCode3
    );
    if (validation.isInvalid) {
      updateAddPropertyState({
        isLocationZipCodeInvalid: validation.isInvalid,
        locationZipCodeValidationMessage: validation.validationError,
      });
    } else {
      updateAddPropertyState({
        locationZipCode: value,
        isLocationZipCodeInvalid: false,
        locationZipCodeValidationMessage: "",
      });
    }
  };
  const handleViewportChange = useCallback(
    (newViewport) =>
      updateAddPropertyState({
        viewport: {
          latitude: newViewport.latitude,
          longitude: newViewport.longitude,
          zoom: newViewport.zoom,
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <TextField
        variant="outlined"
        size="medium"
        margin="normal"
        required
        fullWidth
        name="house-address"
        label="Address"
        type="text"
        id="house-address"
        onBlur={(e: any) => handleAddressChange(e)}
        onChange={(e: any) => handleAddressChange(e)}
        value={
          addPropertyState.locationAddress
            ? addPropertyState.locationAddress
            : ""
        }
        error={addPropertyState.isLocationAddressInvalid}
        helperText={addPropertyState.locationAddressValidationMessage}
      />
      <TextField
        variant="outlined"
        size="medium"
        margin="normal"
        required
        fullWidth
        name="house-city"
        label="City"
        type="text"
        id="house-city"
        onBlur={(e: any) => handleCityChange(e)}
        onChange={(e: any) => handleCityChange(e)}
        value={
          addPropertyState.locationCity ? addPropertyState.locationCity : ""
        }
        error={addPropertyState.isLocationCityInvalid}
        helperText={addPropertyState.locationCityValidationMessage}
      />
      <FormControl
        variant="outlined"
        size="medium"
        className={classes.formControl}
      >
        <InputLabel id="keewist-select-state-label">State</InputLabel>
        <Select
          labelId="keewist-select-state-label"
          id="keewist-select-state"
          value={addPropertyState.locationState}
          onBlur={(e: any) => handleStateChange(e)}
          onChange={(e: any) => handleStateChange(e)}
          error={addPropertyState.isLocationStateInvalid}
          label="State"
        >
          <MenuItem value={"KS"}>KS</MenuItem>
        </Select>
        <FormHelperText style={{ color: " #E73935" }}>
          {addPropertyState.locationStateValidationMessage}
        </FormHelperText>
      </FormControl>
      <TextField
        className={classes.zipCode}
        variant="outlined"
        size="medium"
        margin="normal"
        fullWidth={false}
        required
        name="house-zipCode"
        label="Zip code"
        type="text"
        id="house-zipCode"
        onBlur={(e: any) => handleZipCodeChange(e)}
        onChange={(e: any) => handleZipCodeChange(e)}
        error={addPropertyState.isLocationZipCodeInvalid}
        helperText={addPropertyState.locationZipCodeValidationMessage}
      />
      <Divider
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(1) }}
      />
      <Typography
        component={"p"}
        variant="caption"
        style={{ color: "#7c7c7c", textAlign: "justify" }}
      >
        Please add your address below to get a geographic coordinates. System
        will use these coordinates to display house on the map.
      </Typography>
      <div style={{ height: "20vh" }}>
        <MapGL
          ref={mapRef}
          {...addPropertyState.viewport}
          width="100%"
          height="100%"
          onViewportChange={handleViewportChange}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        >
          <Geocoder
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            mapRef={mapRef}
            containerRef={geocoderContainerRef}
            onViewportChange={(e) =>
              updateAddPropertyState({
                viewport: {
                  latitude: e.latitude,
                  longitude: e.longitude,
                  zoom: e.zoom,
                },
              })
            }
            position="top-left"
          />
        </MapGL>
      </div>
      <div style={{ marginBottom: theme.spacing(1) }}></div>
    </>
  );
};

export default AddHouseAddress;
