import { CardMedia, Divider, Grid, Typography } from "@material-ui/core";
import styles from "../styles";
import placeholder from "../../../../assets/images/placeholder-img.jpg";
import ReactHtmlParser from "react-html-parser";
import KEEWISTImageSwipe from "../../../../ui-kit/components/KEEWIST-crousel";
import theme from "../../../theme/theme";
import { Box, Skeleton } from "@mui/material";
import { FacebookShareButton, FacebookShareCount } from "react-share";
import { IoShareSocialOutline } from "react-icons/io5";

interface NewsContentProps {
  news: any;
}
const NewsContent = (props: NewsContentProps) => {
  const classes = styles();
  const { news } = props;
  const url = "http://kcictrental.com/news";

  return (
    <>
      <Grid item xs={12} sm={8}>
        <Typography
          className={classes.typograph}
          // component="h5"
          variant="subtitle1"
          color="inherit"
        >
          Latest <span>news</span>
        </Typography>
        <Divider />
      </Grid>
      {news.length !== 0 ? (
        news.map((res, index) => (
          <div key={index} id={index}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6" color="inherit">
                {res.title}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle2"
                color="textSecondary"
              >
                updated on {new Date(res.createdAt).toDateString()}
              </Typography>
              <KEEWISTImageSwipe images={res.news_images} />
              {res.showImage && (
                <CardMedia
                  className={classes.media}
                  image={res.image !== undefined ? res.image : placeholder}
                  title={res.imageText}
                />
              )}
              <div style={{ textAlign: "justify" }}>
                {ReactHtmlParser(res.content)}
              </div>
              <div className={classes.shareIcon}>
                <FacebookShareButton
                  url={url + `#${index}`}
                  title={res.title}
                  hashtag="#vacationHomes"
                >
                  <IoShareSocialOutline
                    size={20}
                    color={theme.palette.secondary.main}
                  />
                </FacebookShareButton>
                <FacebookShareCount url={url} />
              </div>
              <Divider
                light
                style={{
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(3),
                }}
              />
            </Grid>
          </div>
        ))
      ) : (
        <>
          <Grid item xs={12} sm={8}>
            <Skeleton width="60%" />
            <Skeleton width="20%" />
            <Skeleton width="60%" />
            <Skeleton variant="rectangular" width={500} height={310} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton width="60%" />
            <Skeleton width="20%" />
            <Skeleton width="60%" />
            <Skeleton variant="rectangular" width={500} height={310} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default NewsContent;
