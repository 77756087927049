import { Button, Divider, Grid, Link, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import theme from "../../../theme/theme";
import styles from "../styles";
import KEEWISTDialog from "../../../../ui-kit/components/KEEWIST-dailog";
import KEEWISTDatepicker from "../../../../ui-kit/components/KEEWIST-datepicker";
import ReactHtmlParser from "react-html-parser";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { EntireHouseStateContext } from "../EntireHouseContext";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Box } from "@mui/material";

const LeftSideContent = () => {
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [openDesc, setOpenDesc] = React.useState(false);
  const [openAddDate, setOpenAddDate] = React.useState(false);
  const [countOffer, setCountOffer] = React.useState(Number);
  const { entireHouseState, updateEntireHouseState } = useContext(
    EntireHouseStateContext
  );
  const date = new Date();

  var monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    let count = 0;
    for (let offer in entireHouseState.offers) {
      if (offer) count++;
    }
    setCountOffer(count);
  }, []);

  const openModalWindow = () => {
    setOpen(true);
  };

  const openDescModalWindow = () => {
    setOpenDesc(true);
  };

  const closeModalWindow = () => {
    setOpen(false);
  };

  const closeDescModalWindow = () => {
    setOpenDesc(false);
  };

  const handleOnChangePolicy = () => {
    let dateRange = entireHouseState.booking[0];

    // To calculate the time difference of two dates
    var Difference_In_Time =
      dateRange.endDate.getTime() - dateRange.startDate.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.floor(
      Difference_In_Time / (1000 * 3600 * 24)
    );

    return (
      dateRange.endDate.getDate() +
      " " +
      monthShortNames[dateRange.endDate.getMonth()] +
      " " +
      dateRange.endDate.getFullYear()
    );
  };

  const handleOnChangeDate = (value: any) => {
    let dateRange = value[0];

    // To calculate the time difference of two dates
    var Difference_In_Time =
      dateRange.endDate.getTime() - dateRange.startDate.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Math.floor(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    updateEntireHouseState({
      totalNightPrice:
        entireHouseState.property_price_per_night * Difference_In_Days,
      differenceInDays: Difference_In_Days,
      booking: [dateRange],
    });
  };

  const handelOnClickAddDate = () => {
    setOpenAddDate(true);
  };

  const handelOnClickAMenities = () => {
    updateEntireHouseState({ openAmenities: !entireHouseState.openAmenities });
  };
  const closeAddDate = () => {
    setOpenAddDate(false);
  };

  const handleOnPolicyDetail = () => {
    updateEntireHouseState({
      openPolicyDetail: !entireHouseState.openPolicyDetail,
    });
  };

  const enhancedCleanContent = (
    <Grid container>
      <div style={{ marginBottom: theme.spacing(4), width: "100%" }}>
        {ReactHtmlParser(entireHouseState.enhanced_clean_description)}
      </div>
      {entireHouseState.enhanced_clean?.map((c, key) => (
        <Grid item xs={12} sm={6} key={key}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <AssignmentTurnedInIcon style={{ marginRight: theme.spacing(2) }} />
            <div>
              <Typography
                component="span"
                variant="body1"
                style={{ fontWeight: "bold" }}
              >
                {c.type}
              </Typography>
              <Typography
                variant="body1"
                style={{ marginBottom: theme.spacing(2), paddingRight: 20 }}
              >
                {c.description}
              </Typography>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );

  const houseDescriptionContent = (
    <>{ReactHtmlParser(entireHouseState.property_description)}</>
  );

  const amenityContent = (
    <Grid container>
      <Grid item xs={12} sm={4} md={4}>
        {entireHouseState.offers.enthernetConnection && (
          <Typography
            component="span"
            variant="body1"
            style={{ lineHeight: 1.8 }}
          >
            enthernetConnection
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        {entireHouseState.offers.wifi && (
          <Typography
            component="span"
            variant="body1"
            style={{ lineHeight: 1.8 }}
          >
            wifi
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        {entireHouseState.offers.smartTv && (
          <Typography
            component="span"
            variant="body1"
            style={{ lineHeight: 1.8 }}
          >
            HDTV with Amazon Prime Video, Fire TV, Netflix, standard cable
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        {entireHouseState.offers.kitchen && (
          <Typography
            component="span"
            variant="body1"
            style={{ lineHeight: 1.8 }}
          >
            kitchen
          </Typography>
        )}
      </Grid>
      <Grid item md={12}>
        <Button
          onClick={handelOnClickAMenities}
          variant="contained"
          size="small"
          style={{
            marginTop: theme.spacing(1),
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          Show all {countOffer} amenities
        </Button>
      </Grid>
    </Grid>
  );

  const datePickerRange = (
    <KEEWISTDatepicker
      onChange={handleOnChangeDate}
      booking={entireHouseState.booking}
      booked={entireHouseState.booked}
    />
  );
  const cancellationPolicyDailog = (
    <>
      <Grid item style={{ marginBottom: "1rem" }}>
        <Typography
          component="span"
          variant="body1"
          style={{ lineHeight: 1.8 }}
        >
          Before you book, make sure you're comfortable with this Host's
          cancellation policy.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          component="span"
          variant="body1"
          style={{ lineHeight: 1.8, fontWeight: "bold" }}
        >
          Cancel by
        </Typography>
        {entireHouseState.booking[0] && (
          <div style={{ marginBottom: "1rem" }}>
            <div className={classes.cancelBy}>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8, fontWeight: "bold" }}
              >
                {monthShortNames[
                  entireHouseState.booking[0].endDate.getMonth() - 1
                ] +
                  " " +
                  entireHouseState.booking[0].endDate.getDate()}
              </Typography>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8 }}
              >
                Full refund: Get back 100% of what you paid.
              </Typography>
            </div>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
            {entireHouseState.differenceInDays >= 28 ? (
              <>
                <div className={classes.cancelBy}>
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ lineHeight: 1.8, fontWeight: "bold" }}
                  >
                    {monthShortNames[
                      entireHouseState.booking[0].endDate.getMonth()
                    ] +
                      " " +
                      entireHouseState.booking[0].endDate.getDate()}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ lineHeight: 1.8 }}
                  >
                    Partial refund: Get back 50% of every night. {<br />}No
                    refund of the service fee.
                  </Typography>
                </div>
                <Divider
                  light
                  style={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                  }}
                />
              </>
            ) : (
              <>
                <div className={classes.cancelBy}>
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ lineHeight: 1.8, fontWeight: "bold" }}
                  >
                    {monthShortNames[
                      entireHouseState.booking[0].endDate.getMonth()
                    ] +
                      " " +
                      (entireHouseState.booking[0].endDate.getDate() - 7)}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ lineHeight: 1.8 }}
                  >
                    Partial refund: Get back 50% of every night. {<br />}No
                    refund of the service fee.
                  </Typography>
                </div>
                <Divider
                  light
                  style={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                  }}
                />
                <div className={classes.cancelBy}>
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ lineHeight: 1.8, fontWeight: "bold" }}
                  >
                    {monthShortNames[
                      entireHouseState.booking[0].endDate.getMonth()
                    ] +
                      " " +
                      entireHouseState.booking[0].endDate.getDate()}
                  </Typography>
                  <Typography
                    component="span"
                    variant="body1"
                    style={{ lineHeight: 1.8 }}
                  >
                    No refund
                  </Typography>
                </div>
                <Divider
                  light
                  style={{
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                  }}
                />
              </>
            )}
          </div>
        )}
      </Grid>
    </>
  );

  const whatThisPlaceOffers = (
    <>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Bathroom
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.bathtub && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              bathtub
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.hairDryer && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              hairDryer
            </Typography>

            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.cleaningProducts && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              cleaningProducts
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.shampoo && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              shampoo
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.conditioner && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              conditioner
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.bodySoap && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              bodySoap
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.hotWater && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              hotWater
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.showerGel && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              showerGel
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Bedroom and laundry
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.freeWasherInBuilding && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              freeWasherInBuilding
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.freeDryerInBuilding && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              freeDryerInBuilding
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.essentials && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              essentials
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.hangers && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              hangers
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.bedLinens && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              bedLinens
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.extraPillowsAndBlankets && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              extraPillowsAndBlankets
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.iron && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              iron
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.clothingStorage && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              clothingStorage
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Entertaiment
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.enthernetConnection && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              Enthernet Connection
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.smartTv && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              HDTV with Amazon Prime Video, Fire TV, Netflix, standard cable
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Family
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.Crib && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              Crib
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.PackNPlay && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              PackNPlay
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.highChair && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              highChair
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.babyBath && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              babyBath
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.childrensDinnerware && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              childrensDinnerware
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.boardGames && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              boardGames
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.babySafetyGates && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              babySafetyGates
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Heating and cooling
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.centralAirConditioning && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              centralAirConditioning
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.ceilingFan && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              ceilingFan
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.portableFans && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              portableFans
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.heating && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              heating
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Home safety
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.securityCamerasOnProperty && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              securityCamerasOnProperty
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.smokeAlarm && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              smokeAlarm
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.carbonMonoxideAlarm && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              carbonMonoxideAlarm
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.fireExtinguisher && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              fireExtinguisher
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.firstAidKit && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              firstAidKit
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Internet and office
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.wifi && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              wifi
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.dedicatedWorkspace && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              dedicatedWorkspace
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Kitchen and dining
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.kitchen && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              kitchen
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.refrigerator && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              refrigerator
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.microwave && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              microwave
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.cookingBasics && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              cookingBasics
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.dishesAndSilverware && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              dishesAndSilverware
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.freezer && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              freezer
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.dishwasher && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              dishwasher
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.stainlessSteelElectricStove && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              stainlessSteelElectricStove
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.oven && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              oven
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.hotWaterKettle && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              hotWaterKettle
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.coffeeMaker && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              coffeeMaker
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.wineGlasses && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              wineGlasses
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.toaster && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              toaster
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.bakingSheet && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              bakingSheet
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.blender && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              blender
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.barbecueUtensils && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              barbecueUtensils
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.diningTable && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              diningTable
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.coffee && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              coffee
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Location features
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.privateEntrance && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              privateEntrance
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Outdoor
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.sharedPatioOrBalcony && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              sharedPatioOrBalcony
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.privateBackyardNotFullyFenced && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              privateBackyardNotFullyFenced
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.outdoorFurniture && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              outdoorFurniture
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.outdoorDiningArea && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              outdoorDiningArea
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.bbqGrill && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              bbqGrill
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Parking and facilities
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.freeParkingOnPremises && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              freeParkingOnPremises
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.freeStreetParking && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              freeStreetParking
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          marginBottom: theme.spacing(3),
          marginTop: "60px",
        }}
      >
        Services
      </Typography>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.luggageDropoffAllowed && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              luggageDropoffAllowed
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.longTermStaysAllowed && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              longTermStaysAllowed
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.smartLock && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              smartLock
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {entireHouseState.offers.cleaningAvailableDuringStay && (
          <>
            <Typography
              component="span"
              variant="body1"
              style={{ lineHeight: 1.8 }}
            >
              cleaningAvailableDuringStay
            </Typography>
            <Divider
              light
              style={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            />
          </>
        )}
      </Grid>
    </>
  );

  const thingsToKnowContent = (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          House rules
        </Typography>
        {entireHouseState.things_to_know?.house_rules.map((rule, key) => (
          <div key={key}>
            <Typography key={key} variant="body1" style={{ lineHeight: 1.8 }}>
              {rule}
            </Typography>
          </div>
        ))}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Health & safety
        </Typography>
        <Grid item xs={12} sm={12} md={12}>
          {entireHouseState.offers.securityCamerasOnProperty && (
            <>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8 }}
              >
                securityCamerasOnProperty
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {entireHouseState.offers.smokeAlarm && (
            <>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8 }}
              >
                smokeAlarm
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {entireHouseState.offers.carbonMonoxideAlarm && (
            <>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8 }}
              >
                carbonMonoxideAlarm
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {entireHouseState.offers.fireExtinguisher && (
            <>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8 }}
              >
                fireExtinguisher
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {entireHouseState.offers.firstAidKit && (
            <>
              <Typography
                component="span"
                variant="body1"
                style={{ lineHeight: 1.8 }}
              >
                firstAidKit
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Cancellation Policy
        </Typography>

        {/* {entireHouseState.isBookingSelected &&
        entireHouseState.booking.length > 0 ? (
          entireHouseState.booking.map((date, key) => (
            <div key={key}>
              <>
                <Typography
                  key={key}
                  variant="body1"
                  style={{ lineHeight: 1.8 }}
                >
                  Free cancellation before{" "}
                  {monthShortNames[date.endDate.getMonth() - 1] +
                    " " +
                    date.endDate.getDate()}
                </Typography>
                <Typography
                  onClick={handleOnPolicyDetail}
                  key={key}
                  variant="body1"
                  style={{
                    lineHeight: 1.8,
                    textDecoration: "underline black",
                    cursor: "pointer",
                  }}
                >
                  More detail {">"}
                </Typography>
              </>
            </div>
          ))
        ) : ( */}
        <>
          <Typography variant="body1" style={{ lineHeight: 1.8 }}>
            Add your trip dates to get the cancellation details for this stay.
          </Typography>
          <Button
            onClick={handelOnClickAddDate}
            variant="contained"
            size="small"
            style={{
              marginTop: theme.spacing(1),
              color: theme.palette.common.white,
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            Add dates
          </Button>
        </>
        {/* )} */}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Typography
        className={classes.typograph}
        component="h4"
        variant="subtitle1"
      >
        {entireHouseState.property_name} <span>house</span>
      </Typography>
      <Typography component="h5" variant="body1">
        {entireHouseState.property_guest_limit !== undefined
          ? entireHouseState.property_guest_limit + " guests · "
          : ""}
        {entireHouseState.property_beds !== undefined
          ? entireHouseState.property_beds + " bedrooms · "
          : ""}
        {entireHouseState.property_baths !== undefined
          ? entireHouseState.property_baths + " bathrooms  · "
          : ""}
        {entireHouseState.property_type !== undefined
          ? entireHouseState.property_type
          : ""}
      </Typography>
      <Divider
        light
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      {entireHouseState.property_type?.toLowerCase() === "entirehome" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <HomeOutlinedIcon
            fontSize={"small"}
            sx={{
              color: theme.palette.secondary.light,
              marginTop: theme.spacing(0.1),
            }}
          />
          <div style={{ paddingLeft: theme.spacing(2) }}>
            <Typography component="span" variant="body1">
              Entire home
            </Typography>
            <Typography variant="body2" className={classes.caption}>
              You’ll have the house to yourself.
            </Typography>
          </div>
        </Box>
      )}
      {entireHouseState.enhanced_clean !== null && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <AutoAwesomeIcon
            fontSize={"small"}
            sx={{
              color: theme.palette.secondary.light,
              marginTop: theme.spacing(0.1),
            }}
          />
          <div style={{ paddingLeft: theme.spacing(2) }}>
            <Typography component="span" variant="body1">
              Enhanced Clean
            </Typography>
            <Typography variant="body2" className={classes.caption}>
              <Link className={classes.link} onClick={openModalWindow}>
                Learn more{" "}
              </Link>
              about our enhanced cleaning process.
            </Typography>
          </div>
        </Box>
      )}
      {entireHouseState.self_checkin && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <MeetingRoomOutlinedIcon
            fontSize={"small"}
            sx={{
              color: theme.palette.secondary.light,
              marginTop: theme.spacing(0.1),
            }}
          />
          <div style={{ paddingLeft: theme.spacing(2) }}>
            <Typography component="span" variant="body1">
              Self check-in
            </Typography>
            <Typography variant="body2" className={classes.caption}>
              Check yourself in with the keypad.
            </Typography>
          </div>
        </Box>
      )}

      <Divider
        light
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      <div style={{ textAlign: "justify" }}>
        {entireHouseState.property_description !== null &&
        entireHouseState.property_description.length > 400
          ? ReactHtmlParser(
              entireHouseState.property_description.substr(0, 400)
            )
          : ReactHtmlParser(entireHouseState.property_description)}
        <Link onClick={openDescModalWindow} className={classes.link}>
          read more
        </Link>
      </div>
      <Divider
        light
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      <Typography
        className={classes.typograph}
        component="h4"
        variant="subtitle1"
        style={{ marginBottom: theme.spacing(1) }}
      >
        Place <span>offers</span>
      </Typography>
      {amenityContent}
      <Divider
        light
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      <Typography
        className={classes.typograph}
        component="h4"
        variant="subtitle1"
        style={{ marginBottom: theme.spacing(1) }}
      >
        Select check-in <span>date</span>
      </Typography>
      <Typography
        component="h6"
        variant="body1"
        style={{ marginBottom: theme.spacing(1) }}
      >
        {entireHouseState.differenceInDays !== 0 && (
          <>
            {entireHouseState.differenceInDays === 1 ? (
              <>{entireHouseState.differenceInDays} night in Wichita</>
            ) : (
              <>{entireHouseState.differenceInDays} nights in Wichita</>
            )}{" "}
          </>
        )}
      </Typography>
      {datePickerRange}
      <Divider
        light
        style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
      />
      <Typography
        className={classes.typograph}
        component="h4"
        variant="subtitle1"
        style={{ marginBottom: theme.spacing(1) }}
      >
        Things <span>to know</span>
      </Typography>
      {thingsToKnowContent}

      <KEEWISTDialog
        isOpen={openDesc}
        handleClose={closeDescModalWindow}
        title={"About this house"}
        content={houseDescriptionContent}
        actionButton={false}
        maxWidth="md"
        fullWidth={true}
      />
      <KEEWISTDialog
        isOpen={open}
        handleClose={closeModalWindow}
        title={"Enhanced Clean"}
        content={enhancedCleanContent}
        actionButton={false}
        maxWidth="md"
        fullWidth={true}
      />
      <KEEWISTDialog
        isOpen={openAddDate}
        handleClose={closeAddDate}
        title={"Add dates"}
        content={datePickerRange}
        maxWidth="xs"
        fullWidth={true}
        btnSize={"small"}
        actionButton={true}
        btnLabelCancel="Close"
        btnLabelHide={true}
      />
      <KEEWISTDialog
        isOpen={entireHouseState.openAmenities}
        handleClose={handelOnClickAMenities}
        title={"What this place offers"}
        content={whatThisPlaceOffers}
        maxWidth="md"
        fullWidth={true}
        btnSize={"small"}
        actionButton={true}
        btnLabelCancel="Close"
        btnLabelHide={true}
        btnLabelResetHide={true}
      />
      <KEEWISTDialog
        isOpen={entireHouseState.openPolicyDetail}
        handleClose={handleOnPolicyDetail}
        title={"Cancellation policy"}
        content={cancellationPolicyDailog}
        maxWidth="sm"
        fullWidth={true}
        btnSize={"small"}
        actionButton={true}
        btnLabelCancel="Close"
        btnLabelHide={true}
        btnLabelResetHide={true}
      />
    </>
  );
};

export default LeftSideContent;
