import React, { useEffect } from "react";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import styles from "./styles";
import { nullCheck } from "../../../../ui-kit/common/validation/KeewistValidations";
import { validationMessages } from "./helper/constants";
import { isFormSubmitted } from "../../../../service/validation.service";
import { HomeApiService } from "../../../../api-kit/home/home-api.service";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../_redux/_actions/snackBar.actions";
import KEEWISTEditor from "../../../../ui-kit/components/KEEWIST-editor";

const AuthHome = () => {
  const homeApiService = new HomeApiService();
  const dispatch = useDispatch();
  const classes = styles();
  const [isTitleInvalid, setIsTitleInvalid] = React.useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [paragraph, setParagraph] = React.useState("");
  const [isParagraphInvalid, setIsParagraphInvalid] = React.useState(false);
  const [paragraphErrorMessage, setParagraphErrorMessage] = React.useState("");
  const [isSmallHeaderInvalid, setIsSmallHeaderInvalid] = React.useState(false);
  const [smallHeaderErrorMessage, setSmallHeaderErrorMessage] =
    React.useState("");
  const [smallHeader, setSmallHeader] = React.useState("");
  const [homeId, setHomeId] = React.useState("");

  useEffect(() => {
    homeApiService
      .getHome()
      .then((res) => {
        if (res.data === null) {
          dispatch(
            addSnackBar({
              message:
                "Add title, small header and paragraph to your home page.",
              type: "warning",
            })
          );
        }
        setTitle(
          res.data?.header !== undefined && res.data?.header?.heading !== null
            ? res.data?.header?.heading
            : ""
        );
        setParagraph(
          res.data?.header !== undefined && res.data?.header?.paragraph !== null
            ? res.data?.header?.paragraph
            : "<p></p>"
        );
        setSmallHeader(
          res.data?.header !== undefined &&
            res.data?.header?.small_header !== null
            ? res.data?.header?.small_header
            : ""
        );
        setHomeId(
          res.data?.header !== undefined && res.data?.header?._id !== null
            ? res.data?.header?._id
            : ""
        );
      })
      .catch((error) =>
        dispatch(
          addSnackBar({
            message: "Unable to get home details.",
            type: "error",
          })
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isCurrent = true;
    isFormSubmitted.subscribe((submit) => {
      if (submit && isCurrent) {
        validateFields();
        isFormSubmitted.next(false);
      }
    });
    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, smallHeader, paragraph]);

  const handleEditor = (e: any) => {
    let value = e;
    const validation = nullCheck(value, validationMessages.headerParagraph);
    if (validation.isInvalid) {
      setParagraph(value);
      setIsParagraphInvalid(true);
      setParagraphErrorMessage(validation.validationError);
    } else {
      setParagraph(value);
      setIsParagraphInvalid(false);
      setParagraphErrorMessage("");
    }
  };

  const handleTitleChange = (e: any) => {
    let value = e.target.value;
    const validation = nullCheck(value, validationMessages.headerTitle);
    if (validation.isInvalid) {
      setTitle(value);
      setIsTitleInvalid(true);
      setTitleErrorMessage(validation.validationError);
    } else {
      setTitle(value);
      setIsTitleInvalid(false);
      setTitleErrorMessage("");
    }
  };

  const handleSmallHeaderChange = (e: any) => {
    let value = e.target.value;
    const validation = nullCheck(value, validationMessages.smallHeader);
    if (validation.isInvalid) {
      setSmallHeader(value);
      setIsSmallHeaderInvalid(true);
      setSmallHeaderErrorMessage(validation.validationError);
    } else {
      setSmallHeader(value);
      setIsSmallHeaderInvalid(false);
      setSmallHeaderErrorMessage("");
    }
  };

  const validateFields = () => {
    const validation1 = nullCheck(title, validationMessages.headerTitle);
    if (validation1.isInvalid) {
      setTitle(title);
      setIsTitleInvalid(true);
      setTitleErrorMessage(validation1.validationError);
    } else {
      setTitle(title);
      setIsTitleInvalid(false);
      setTitleErrorMessage("");
    }
    const validation2 = nullCheck(smallHeader, validationMessages.smallHeader);
    if (validation2.isInvalid) {
      setSmallHeader(smallHeader);
      setIsSmallHeaderInvalid(true);
      setSmallHeaderErrorMessage(validation2.validationError);
    } else {
      setSmallHeader(smallHeader);
      setIsSmallHeaderInvalid(false);
      setSmallHeaderErrorMessage("");
    }

    const validation3 = nullCheck(
      paragraph,
      validationMessages.headerParagraph
    );
    if (validation3.isInvalid) {
      setParagraph(paragraph);
      setIsParagraphInvalid(true);
      setParagraphErrorMessage(validation3.validationError);
    } else {
      setParagraph(paragraph);
      setIsParagraphInvalid(false);
      setParagraphErrorMessage("");
    }
  };

  const handleSubmit = (e: any) => {
    isFormSubmitted.next(true);
    e.preventDefault();
    validateFields();
    let body = {
      heading: title,
      paragraph: paragraph,
      small_header: smallHeader,
      id: homeId,
    };
    if (title !== "" && paragraph !== "") {
      homeApiService
        .createHeader(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "Home page successfully updated",
              type: "success",
            })
          );
        })
        .catch((error) => {
          dispatch(
            addSnackBar({
              message: "Unable to update Home page",
              type: "error",
            })
          );
        });
    }
  };

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h4"
        component="h2"
        align="center"
        gutterBottom
        color="textSecondary"
        style={{ textTransform: "uppercase", fontSize: "1.5em" }}
      >
        Home
      </Typography>
      <form
        noValidate
        autoComplete="off"
        className={classes.form}
        onSubmit={(e) => handleSubmit(e)}
      >
        <TextField
          id="title-id"
          label="Title"
          margin="normal"
          value={title ? title : ""}
          required
          size="medium"
          variant="outlined"
          onBlur={(e: any) => handleTitleChange(e)}
          onChange={(e: any) => handleTitleChange(e)}
          fullWidth
          error={isTitleInvalid}
          helperText={titleErrorMessage}
        />
        <TextField
          id="small-title-id"
          label="Small header"
          value={smallHeader ? smallHeader : ""}
          required
          size="medium"
          variant="outlined"
          onBlur={(e: any) => handleSmallHeaderChange(e)}
          onChange={(e: any) => handleSmallHeaderChange(e)}
          fullWidth
          error={isSmallHeaderInvalid}
          helperText={smallHeaderErrorMessage}
        />
        <div className={classes.editor}>
          <KEEWISTEditor
            data={paragraph}
            onChange={(e: any) => handleEditor(e)}
            onBlur={(e: any) => handleEditor(e)}
            isInValid={isParagraphInvalid}
            errorMessage={paragraphErrorMessage}
          />
        </div>
        <Button
          type="submit"
          variant="contained"
          size="small"
          className={classes.btn}
        >
          Submit
        </Button>
      </form>
    </Paper>
  );
};

export default AuthHome;
