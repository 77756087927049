export interface ThingsToDoState {
  label: string;
  isLabelInvalid: boolean;
  labelErrorMessage: string;
  newLabel: string;
  disabled: boolean;
  title: string;
  isTitleInvalid: boolean;
  titleErrorMessage: string;
  disableBtn: boolean;
  labelType: string;
  description: string;
  isDescriptionInvalid: boolean;
  DescriptionErrorMessage: string;
  open: boolean;
  addMoreLabelPlace: boolean;
  isGeoLocationAdded: boolean;
  isLabelExist: boolean;
  geo_location: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  toDoImages: any[];
  content: any[];
}

export const thingsToDoStateDefault: ThingsToDoState = {
  label: "",
  isLabelInvalid: false,
  labelErrorMessage: "",
  newLabel: "",
  disabled: false,
  title: "",
  isTitleInvalid: false,
  titleErrorMessage: "",
  disableBtn: false,
  labelType: "",
  description: "",
  isDescriptionInvalid: false,
  DescriptionErrorMessage: "",
  open: false,
  addMoreLabelPlace: false,
  isGeoLocationAdded: false,
  isLabelExist: false,
  geo_location: {
    latitude: 37.6922361,
    longitude: -97.3375448,
    zoom: 10,
  },
  toDoImages: [],
  content: [],
};
