import React from "react";
import AddLocationsToMap from "../../../common/mapBox/AddLocationsToMap";
interface TodoMapProps {
  locations?: any;
}

const TodoMap = (props: TodoMapProps) => {
  return (
    <AddLocationsToMap
      latitude={37.6922361}
      longitude={-97.3375448}
      zoom={10}
      maxZoom={18}
      width={"100%"}
      height={"100%"}
      data={props.locations}
      fullScreen={true}
      wrapperHeight={"78vh"}
    />
  );
};

export default TodoMap;
