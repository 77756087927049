import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from "./common/error/NotFound";
import history from "../_redux/_helpers/history";
import KeewistPages from "./components";
import PrivateRoute from "../_redux/_components/PrivateRoute";
import PublicRoute from "../_redux/_components/PubicRoute";
import AdminDash from "./components/auth/dashboard/AdminDash";
import EditUser from "./components/auth/users/components/editUser/EditUser";
import { UserProvider } from "./components/auth/users/UserContext";
import AddProperty from "./components/auth/properties/addProperty/AddProperty";
import EditProperty from "./components/auth/properties/editProperty/EditProperty";
import ThingsToDoList from "./components/auth/things-to-do/ThingsToDoList";
import AddThingsToDo from "./components/auth/things-to-do/components/addThingsToDo/AddThingsToDo";
import EditThingsToDo from "./components/auth/things-to-do/components/editThingsToDo/EditThingsToDo";
import AddNews from "./components/auth/news/components/addNews/AddNews";
import NewsList from "./components/auth/news/NewsList";
import EditNews from "./components/auth/news/components/editNews/EditNews";
import AuthHome from "./components/auth/home/AuthHome";
import AuthAbout from "./components/auth/about/AuthAbout";
import { PropertyProvider } from "./components/auth/properties/PropertyContext";
import ViewTodo from "./components/auth/things-to-do/components/viewTodo/VeiwTodo";
import ViewNews from "./components/auth/news/components/viewNews/VeiwNews";
import ViewProperty from "./components/auth/properties/viewProperty/ViewProperty";
import Account from "./components/auth/users/components/account/Account";
import Forgot from "./components/forgotPassword/Forgot";
import Reset from "./components/forgotPassword/Reset";
import EditAccount from "./components/auth/users/components/account/components/EditAccount";
import ViewHouse from "./components/entirehouse/components/ViewHouse";
import Hygiene from "./components/auth/properties/hygiene/Hygiene";
import { RequestHouse } from "./components/entirehouse/components/RequestHouse";
import { EntireHouseProvider } from "./components/entirehouse/EntireHouseContext";
import { ThingsToDoProvider } from "./components/auth/things-to-do/ThingsToDoContext";
import { NewsProvider } from "./components/auth/news/NewsContext";
import Policies from "./components/auth/policies/Policies";
import AddPolicy from "./components/auth/policies/components/AddPolicy";
import EditPolicy from "./components/auth/policies/components/EditPolicy";
import { PolicyProvider } from "./components/auth/policies/PolicyContext";

const {
  AboutUs,
  Home,
  Houses,
  PrivateRoom,
  ThingsToDo,
  News,
  UserLogin,
  UserRegister,
  UserList,
  PropertyList,
} = KeewistPages;
export const Paths = {
  home: "/",
  login: "/login",
  register: "/register",
  about: "/about",
  rentals: "/rental/houses",
  rental: "/rental/house/details",
  requestHouse: "/rental/house/details/request",
  privateroom: "/private-house",
  thingstodo: "/things-to-do",
  news: "/news",
  notFound: "/not-found",
  error: "/error",
  userList: "/dashboard/users",
  dashboard: "/dashboard",
  editUser: "/dashboard/edit/user",
  propertyList: "/dashboard/properties",
  propertyEdit: "/dashboard/property/edit",
  propertyAdd: "/dashboard/property/add",
  propertyView: "/dashboard/property/view",
  authHome: "/dashboard/auth/home",
  authAbout: "/dashboard/auth/about",
  thingsToDoList: "/dashboard/todos",
  thingsToDoEdit: "/dashboard/todo/edit",
  thingsToDoAdd: "/dashboard/todo/add",
  thingsToDoView: "/dashboard/todo/view",
  newsList: "/dashboard/news/list",
  newsEdit: "/dashboard/news/edit",
  newsAdd: "/dashboard/news/add",
  newsView: "/dashboard/news/view",
  account: "/dashboard/user/account",
  accountEdit: "/dashboard/account/edit",
  forgot: "/forgot/password",
  reset: "/reset/password",
  hygiene: "/dashboard/house/hygiene",
  policies: "/dashboard/policies",
  policyAdd: "/dashboard/policy/add",
  policyEdit: "/dashboard/policy/edit",
};
export const pages = [
  Paths.home,
  Paths.about,
  Paths.news,
  Paths.rentals,
  Paths.rental,
  Paths.requestHouse,
  Paths.login,
  Paths.privateroom,
  Paths.register,
  Paths.thingstodo,
  Paths.userList,
  Paths.dashboard,
  Paths.editUser,
  Paths.propertyList,
  Paths.propertyEdit,
  Paths.propertyAdd,
  Paths.propertyView,
  Paths.authHome,
  Paths.authAbout,
  Paths.thingsToDoList,
  Paths.thingsToDoEdit,
  Paths.thingsToDoAdd,
  Paths.thingsToDoView,
  Paths.newsList,
  Paths.newsEdit,
  Paths.newsAdd,
  Paths.newsView,
  Paths.account,
  Paths.accountEdit,
  Paths.forgot,
  Paths.reset,
  Paths.hygiene,
  Paths.policies,
  Paths.policyAdd,
  Paths.policyEdit,
];

export const Links = {
  public: [
    {
      path: Paths.home,
      render: (props) => <Home {...props} />,
    },
    {
      path: Paths.login,
      render: (props) => <UserLogin {...props} />,
    },
    {
      path: Paths.about,
      render: (props) => <AboutUs {...props} />,
    },
    {
      path: Paths.news,
      render: (props) => <News {...props} />,
    },
    {
      path: Paths.rentals,
      render: (props) => <Houses {...props} />,
    },
    {
      path: Paths.rental,
      render: (props) => (
        <EntireHouseProvider>
          <ViewHouse {...props} />
        </EntireHouseProvider>
      ),
    },
    {
      path: Paths.requestHouse,
      render: (props) => (
        <EntireHouseProvider>
          <RequestHouse {...props} />
        </EntireHouseProvider>
      ),
    },
    {
      path: Paths.privateroom,
      render: (props) => <PrivateRoom {...props} />,
    },
    {
      path: Paths.thingstodo,
      render: (props) => <ThingsToDo {...props} />,
    },
    {
      path: Paths.register,
      render: (props) => <UserRegister {...props} />,
    },
    {
      path: Paths.forgot,
      render: (props) => <Forgot {...props} />,
    },
    {
      path: Paths.reset,
      render: (props) => <Reset {...props} />,
    },
    {
      path: Paths.error,
      render: (props) => <div {...props}>Error</div>,
    },
  ],
  private: [
    {
      path: Paths.authHome,
      render: (props) => <AuthHome {...props} />,
    },
    {
      path: Paths.authAbout,
      render: (props) => <AuthAbout {...props} />,
    },
    {
      path: Paths.dashboard,
      render: (props) => <AdminDash {...props} />,
    },
    {
      path: Paths.userList,
      render: (props) => <UserList {...props} />,
    },
    {
      path: Paths.editUser,
      render: (props) => (
        <UserProvider>
          <EditUser {...props} />
        </UserProvider>
      ),
    },
    {
      path: Paths.propertyList,
      render: (props) => <PropertyList {...props} />,
    },
    {
      path: Paths.propertyAdd,
      render: (props) => (
        <PropertyProvider>
          <AddProperty {...props} />
        </PropertyProvider>
      ),
    },
    {
      path: Paths.propertyEdit,
      render: (props) => (
        <PropertyProvider>
          <EditProperty {...props} />
        </PropertyProvider>
      ),
    },
    {
      path: Paths.hygiene,
      render: (props) => (
        <PropertyProvider>
          <Hygiene {...props} />
        </PropertyProvider>
      ),
    },
    {
      path: Paths.propertyView,
      render: (props) => (
        <PropertyProvider>
          <ViewProperty {...props} />
        </PropertyProvider>
      ),
    },
    {
      path: Paths.thingsToDoList,
      render: (props) => <ThingsToDoList {...props} />,
    },
    {
      path: Paths.policies,
      render: (props) => (
        <PolicyProvider>
          <Policies {...props} />
        </PolicyProvider>
      ),
    },
    {
      path: Paths.policyAdd,
      render: (props) => (
        <PolicyProvider>
          <AddPolicy {...props} />
        </PolicyProvider>
      ),
    },
    {
      path: Paths.policyEdit,
      render: (props) => (
        <PolicyProvider>
          <EditPolicy {...props} />
        </PolicyProvider>
      ),
    },
    {
      path: Paths.thingsToDoAdd,
      render: (props) => (
        <ThingsToDoProvider>
          <AddThingsToDo {...props} />
        </ThingsToDoProvider>
      ),
    },
    {
      path: Paths.thingsToDoEdit,
      render: (props) => (
        <ThingsToDoProvider>
          <EditThingsToDo {...props} />
        </ThingsToDoProvider>
      ),
    },
    {
      path: Paths.thingsToDoView,
      render: (props) => (
        <ThingsToDoProvider>
          <ViewTodo {...props} />
        </ThingsToDoProvider>
      ),
    },
    {
      path: Paths.newsList,
      render: (props) => <NewsList {...props} />,
    },
    {
      path: Paths.newsAdd,
      render: (props) => (
        <NewsProvider>
          <AddNews {...props} />
        </NewsProvider>
      ),
    },
    {
      path: Paths.newsEdit,
      render: (props) => <EditNews {...props} />,
    },
    {
      path: Paths.newsView,
      render: (props) => <ViewNews {...props} />,
    },
    {
      path: Paths.account,
      render: (props) => <Account {...props} />,
    },
    {
      path: Paths.accountEdit,
      render: (props) => (
        <UserProvider>
          <EditAccount {...props} />
        </UserProvider>
      ),
    },
  ],
  errors: [
    { path: Paths.notFound, render: (props) => <NotFound {...props} /> },
  ],
};
export const Router = (props) => {
  const publicRoutes = Links.public.map((item, i) => (
    <PublicRoute
      exact
      path={item.path}
      component={item.render}
      key={i + "-public"}
    />
  ));
  const privateRoutes = Links.private.map((item, i) => (
    <PrivateRoute
      exact
      path={item.path}
      component={item.render}
      key={i + "-private"}
      // roles={item?.roles}
    />
  ));
  const notFound = Links.errors.map((item, i) => (
    <Route exact path={item.path} render={item.render} key={i + "-error"} />
  ));

  const routes = ([] as any[]).concat(
    publicRoutes,
    privateRoutes,
    notFound,
    <Redirect from="*" to="/not-found" key={"redirect"} />
  );

  return <Switch>{routes}</Switch>;
};

export class PageHelper {
  static openPage(page: string): void {
    history.push(page);
  }
}
