import { render } from "react-dom";
import React from "react";
import App from "./app/App";
import "./styles/keewist.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Provider } from "react-redux";
import store from "./_redux/_helpers/store";
import { ConnectedRouter } from "connected-react-router/immutable";
import history from "./_redux/_helpers/history";
import { fetchHouses } from "./_redux/_actions/house.actions";
import { StylesProvider } from "@mui/styles";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

store.dispatch(fetchHouses() as any);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <StylesProvider injectFirst>
        <DndProvider backend={HTML5Backend}>
        <App />
        </DndProvider>
      </StylesProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
