import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: `${theme.zIndex.drawer + 1} !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: `${theme.transitions.easing.sharp} !important`,
      duration: `${theme.transitions.duration.leavingScreen} !important`,
    }),
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  appBarShift: {
    marginLeft: `${240}px !important`,
    width: `calc(100% - 240px) !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: `${theme.transitions.easing.sharp} !important`,
      duration: `${theme.transitions.duration.enteringScreen} !important`,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  icons: {
    color: theme.palette.common.white,
  },
  menuButtonHidden: {
    display: "none !important",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: `240px !important`,
    transition: theme.transitions.create("width", {
      easing: `${theme.transitions.easing.sharp} !important`,
      duration: `${theme.transitions.duration.enteringScreen} !important`,
    }),
    backgroundColor: `${theme.palette.primary.light} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  drawerPaperClose: {
    overflowX: `hidden`,
    transition: theme.transitions.create("width", {
      easing: `${theme.transitions.easing.sharp} !important`,
      duration: `${theme.transitions.duration.leavingScreen} !important`,
    }),
    width: `${theme.spacing(7)} !important`,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: {
    marginTop: `${theme.spacing(20)} !important`,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    marginLeft: `${240}px !important`,
    width: `calc(100% - 240px) !important`,
  },
  colapsedMenu: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  logo: {
    width: 45,
    marginRight: 20,
  },
  breadcrumbs: {
    paddingBottom: theme.spacing(3),
  },
  item: {
    cursor: "pointer"
  }
}));
