import { userConstants } from "../_constants/user.constants";
import { getUserInfo as user } from "../_helpers/auth-header";

const initialState = user()
  ? {
      loggedIn: true,
      user: user(),
    }
  : {
      loggedIn: false,
      user: {},
    };
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: action.payload,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        user: {},
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        user: {},
      };
    default:
      return state;
  }
}
