import {
  Button,
  ButtonGroup,
  Divider,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { PropertyApiService } from "../../../../../api-kit/property/property-api.service";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../_redux/_actions/snackBar.actions";
import { Paths } from "../../../../Router";
import styles from "./styles";
import theme from "../../../../theme/theme";
import EditHouseDetails from "./components/EditHouseDetails";
import EditHouseAddress from "./components/EditHouseAddress";
import EditHouseRules from "./components/EditHouseRules";
import { PropertyStateContext } from "../PropertyContext";

const EditProperty = () => {
  const { addPropertyState, updateAddPropertyState } =
    useContext(PropertyStateContext);
  const classes = styles();
  const location: any = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const propertyApiService = new PropertyApiService();
  const [houseId, setHouseId] = React.useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.houseRules.concat(
        addPropertyState.houseRule.trim()
      );
      if (addPropertyState.houseRule !== "") {
        updateAddPropertyState({ houseRules: joined, houseRule: "" });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.houseRule]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.healthSafeties.concat(
        addPropertyState.healthSafety.trim()
      );
      if (addPropertyState.healthSafety !== "") {
        updateAddPropertyState({ healthSafeties: joined, healthSafety: "" });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.healthSafety]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.cancellationPolicies.concat(
        addPropertyState.cancellationPolicy.trim()
      );
      if (addPropertyState.cancellationPolicy !== "") {
        updateAddPropertyState({
          cancellationPolicies: joined,
          cancellationPolicy: "",
        });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.cancellationPolicy]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      var joined = addPropertyState.amenities.concat(
        addPropertyState.amenity.trim()
      );
      if (addPropertyState.amenity !== "") {
        updateAddPropertyState({ amenities: joined, amenity: "" });
      }
      return () => clearTimeout(delayDebounceFn);
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPropertyState.amenity]);

  useEffect(() => {
    if (location.state !== null && location.state.houseId !== null) {
      setHouseId(houseId ? houseId : location.state.houseId);
      propertyApiService
        .getProperty(location.state.houseId)
        .then((res) => {
          console.log(res.data, res.data?.things_to_know, res.data?.things_to_know?.house_rules)
          updateAddPropertyState({
            guestLimit: res.data?.property_guest_limit,
            houseBath: res.data?.property_baths,
            houseBed: res.data?.property_beds,
            houseDescription: res.data?.property_description,
            houseName: res.data?.property_name,
            houseType: res.data?.property_type,
            locationAddress: res.data?.property_location?.property_address1,
            locationCity: res.data?.property_location?.property_city,
            locationState: res.data?.property_location?.property_state,
            locationZipCode: res.data?.property_location?.property_zipCode1,
            pricePerNight: res.data?.property_price_per_night,
            houseRules: res.data?.things_to_know?.house_rules,
            healthSafeties: res.data?.things_to_know?.health_safety,
            cancellationPolicies: res.data?.things_to_know?.cancellation_policy,
            propertyImages: res.data?.property_images
          });
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let body = {
      id: houseId,
      property_name: addPropertyState.houseName,
      property_description: addPropertyState.houseDescription,
      property_type: addPropertyState.houseType,
      property_beds: addPropertyState.houseBed,
      property_baths: addPropertyState.houseBath,
      property_guest_limit: addPropertyState.guestLimit,
      property_price_per_night: addPropertyState.pricePerNight,
      geo_location: addPropertyState.viewport,
      property_location: {
        property_address1: addPropertyState.locationAddress,
        property_city: addPropertyState.locationCity,
        property_state: addPropertyState.locationState,
        property_zipCode1: addPropertyState.locationZipCode,
      },
      things_to_know: {
        house_rules: addPropertyState.houseRules,
        health_safety: addPropertyState.healthSafeties,
        cancellation_policy: addPropertyState.cancellationPolicies,
      },
      amenities: addPropertyState.amenities,
      self_checkin: addPropertyState.selfCheckIn,
      enhanced_clean_description: addPropertyState.enhancedCleaningDescription,
      enhanced_clean: addPropertyState.enhancedCleanings,
      propertyImages: addPropertyState.propertyImages
    };
    propertyApiService
      .editProperty(body)
      .then((data) => {
        dispatch(
          addSnackBar({
            message: "Successfully updated.",
            type: "success",
          })
        );
        history.push(Paths.propertyList);
      })
      .catch((err) =>
        dispatch(
          addSnackBar({
            message: "Unable to update news.",
            type: "error",
          })
        )
      );
  };

  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.propertyList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Edit house
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
        >
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            House details
          </Typography>
          <Divider
            variant="fullWidth"
            style={{ marginBottom: theme.spacing(2) }}
          />
          <EditHouseDetails />
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            House address
          </Typography>
          <Divider
            variant="fullWidth"
            style={{ marginBottom: theme.spacing(2) }}
          />
          <EditHouseAddress />
          <Typography
            className={classes.dividerFullWidth}
            color="textSecondary"
            display="block"
            variant="caption"
          >
            House rules
          </Typography>
          <Divider
            variant="fullWidth"
            style={{ marginBottom: theme.spacing(2) }}
          />
          <EditHouseRules />
          <ButtonGroup
            disableElevation
            variant="contained"
            className={classes.btn}
          >
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={() => history.push(Paths.propertyList)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </form>
      </Paper>
    </>
  );
};

export default EditProperty;
