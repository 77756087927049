import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    height: "300px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    color: theme.palette.common.white,
    fontSize: "1em",
  },
  list: {
    color: theme.palette.info.light,
    cursor: "pointer",
  },
}));
