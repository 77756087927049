import { Box, Skeleton } from "@mui/material";
import React from "react";

export default function SKELETONText() {
  return (
    <Box>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
}
