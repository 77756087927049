import {
  Button,
  ButtonGroup,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserApiService } from "../../../../../../api-kit/user/user-api.service";
import { Paths } from "../../../../../Router";
import styles from "./styles";
import {
  checkEmail,
  checkUserName,
} from "../../../../../../ui-kit/common/validation/KeewistValidations";
import { UserStateContext } from "../../UserContext";
import { useDispatch } from "react-redux";
import { addSnackBar } from "../../../../../../_redux/_actions/snackBar.actions";
import theme from "../../../../../theme/theme";

const EditUser = () => {
  const userApiService = new UserApiService();
  const history = useHistory();
  const location: any = useLocation();
  const classes = styles();
  const dispatch = useDispatch();
  const { editUserState, updateEditUserState } = useContext(UserStateContext);

  useEffect(() => {
    if (location.state !== null && location.state.userId !== null) {
      updateEditUserState({ userId: location.state.userId });
      userApiService
        .getUser(location.state.userId)
        .then((user) => {
          updateEditUserState({
            email: user.data?.email,
            username: user.data?.username,
            role: user.data?.role,
            clonedUser: user.data,
          });
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!editUserState.invalidEmail && !editUserState.invalidUsername) {
      let body = {
        id: editUserState.userId,
        email: editUserState.email,
        username: editUserState.username,
        role: editUserState.role,
      };
      userApiService
        .editUser(body)
        .then((data) => {
          dispatch(
            addSnackBar({
              message: "Successfully updated.",
              type: "success",
            })
          );
          history.push(Paths.userList);
        })
        .catch((err) =>
          dispatch(
            addSnackBar({
              message: "Unable to update user.",
              type: "error",
            })
          )
        );
    }
  };

  const handleEmailChange = (event: any) => {
    let value = event.target?.value;
    const validated = checkEmail(value);
    if (validated.isInvalid) {
      updateEditUserState({
        focused: false,
        invalidEmail: validated.isInvalid,
        invalidEmailMessage: validated.validationError,
      });
    } else {
      updateEditUserState({
        invalidEmail: false,
        invalidEmailMessage: "",
      });
    }
    checkValueChanged(value, "email");
    updateEditUserState({ email: value });
  };
  //checking if form field values are changed.
  const checkValueChanged = (value: string, field: string) => {
    if (value.length !== 0) {
      if (field === "email" && value !== editUserState.clonedUser.email) {
        updateEditUserState({ isEmailChanged: true, disabled: false });
      } else if (
        field === "username" &&
        value !== editUserState.clonedUser.username
      ) {
        updateEditUserState({ isUsernameChanged: true, disabled: false });
      } else if (field === "role" && value !== editUserState.clonedUser.role) {
        updateEditUserState({ isUserRoleChanged: true, disabled: false });
      } else {
        updateEditUserState({
          isUsernameChanged: false,
          isEmailChanged: false,
          disabled: true,
        });
      }
    }
  };

  const handleUsernameChange = (event: any) => {
    let value = event.target?.value;
    const validated = checkUserName(value);
    if (validated.isInvalid) {
      updateEditUserState({
        focused: false,
        invalidUsername: validated.isInvalid,
        invalidUsernameMessage: validated.validationError,
      });
    } else {
      updateEditUserState({
        invalidUsername: false,
        invalidUsernameMessage: "",
      });
    }
    updateEditUserState({
      username: value,
    });
    checkValueChanged(value, "username");
  };

  const handleRoleChange = (event) => {
    let value = event.target?.value;
    checkValueChanged(value, "role");
    updateEditUserState({ role: value });
  };

  return (
    <>
      <Button
        style={{
          color: theme.palette.common.white,
          backgroundColor: theme.palette.secondary.main,
        }}
        variant="contained"
        startIcon={<ChevronLeftIcon />}
        onClick={() => history.push(Paths.userList)}
        size="small"
      >
        Back
      </Button>
      <Paper variant="outlined" square className={classes.paper}>
        <Typography
          variant="h4"
          component="h2"
          align="center"
          gutterBottom
          color="textSecondary"
          style={{ textTransform: "uppercase", fontSize: "1.5em" }}
        >
          Edit user
        </Typography>
        <form
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
        >
          <TextField
            id="standard-basic"
            label="Email"
            value={editUserState.email}
            required
            size="medium"
            variant="outlined"
            // focused={editUserState.focused}
            onChange={handleEmailChange}
            fullWidth
            error={editUserState.invalidEmail}
            helperText={editUserState.invalidEmailMessage}
          />
          <TextField
            margin="normal"
            id="standard-basic"
            label="Username"
            value={editUserState.username}
            required
            size="medium"
            variant="outlined"
            focused={editUserState.focused}
            onChange={handleUsernameChange}
            fullWidth
            error={editUserState.invalidUsername}
            helperText={editUserState.invalidUsernameMessage}
          />
          <Box sx={{ minWidth: 120 }} mt={1.5}>
            <FormControl fullWidth>
              <InputLabel id="user-edit-label">User Roles</InputLabel>
              <Select
                labelId="user-edit-label"
                id="user-edit"
                value={editUserState.role}
                label="User roles"
                onChange={handleRoleChange}
                displayEmpty
              >
                <MenuItem value={"KWA"}>KWA</MenuItem>
                <MenuItem value={"KWU"}>KWU</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <ButtonGroup
            disableElevation
            variant="contained"
            className={classes.btn}
          >
            <Button
              variant="contained"
              size="small"
              color="default"
              onClick={() => history.push(Paths.userList)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="small"
              style={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.secondary.main,
              }}
              disabled={editUserState.disabled}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </form>
      </Paper>
    </>
  );
};

export default EditUser;
